@charset "UTF-8";

@import "/node_modules/aos/dist/aos.css";

#page_preloader {
  position: fixed;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 105%;
  height: 105vh;
  left: 50%;
  top: 50%;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background: #fff;
  transform: translate(-50%, -50%);
  border: 2px solid red;
}

#page_preloader > div {
  display: flex;
  width: 120px;
  height: 120px;
  margin: auto;
  animation: preload 1.2s forwards linear;
}

#page_preloader > div svg {
  width: 120px;
  height: 120px;
}

#page_preloader > div svg > [class*='circle_'] {
  opacity: 0;
}

#page_preloader > div svg .line_y_1 {
  stroke-dasharray: 600;
  stroke-dashoffset: 600;
  animation: lines 0.8s 0.2s linear forwards;
}

#page_preloader > div svg .line_y_2 {
  stroke-dasharray: 620;
  stroke-dashoffset: -620;
  animation: lines2 1.2s 0s linear forwards;
}

#page_preloader > div svg .line_g_1 {
  stroke-dasharray: 600;
  stroke-dashoffset: 600;
  animation: lines 1s 0.6s linear forwards;
}

#page_preloader > div svg .line_r_1 {
  stroke-dasharray: 600;
  stroke-dashoffset: 600;
  animation: lines 0.7s 0.6s linear forwards;
}

#page_preloader > div svg .circle_1 {
  animation: zoomer 0.3s linear 1s forwards;
}

#page_preloader > div svg .circle_2 {
  animation: zoomer 0.4s linear 0.9s forwards;
}

#page_preloader > div svg .circle_3 {
  animation: zoomer 0.37s linear 1.1s forwards;
}

#page_preloader > div svg .circle_4 {
  animation: zoomer 0.38s linear 1.3s forwards;
}

#page_preloader > div svg .circle_5 {
  animation: zoomer 0.2s linear 1.2s forwards;
}

#page_preloader > div svg .circle_6 {
  animation: zoomer 0.23s linear 1.4s forwards;
}

#page_preloader > div svg .circle_7 {
  animation: zoomer 0.25s linear 1.5s forwards;
}

#page_preloader > div svg .circle_8 {
  animation: zoomer 0.32s linear 1.2s forwards;
}

#page_preloader > div svg .circle_9 {
  animation: zoomer 0.27s linear 0.9s forwards;
}

#page_preloader > div svg .circle_10 {
  animation: zoomer 0.2s linear 1.6s forwards;
}

#page_preloader > div svg .circle_11 {
  animation: zoomer 0.28s linear 1.3s forwards;
}

@keyframes zoomer {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes lines {
  0% {
    stroke-dashoffset: 600;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes lines2 {
  0% {
    stroke-dashoffset: -620;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes preload {
  0% {
    transform: scale(0.3);
  }

  35% {
    transform: scale(1.3);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }
}

@keyframes loader {
  0% {
    transform: scale(1);
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }

  99.9% {
    transform: scale(1);
    opacity: 0;
    visibility: hidden;
    pointer-events: none !important;
  }

  100% {
    transform: scale(1);
    opacity: 0;
    visibility: hidden;
    pointer-events: none !important;
  }
}

@keyframes preload-spin {
  0% {
    transform: rotate(0) scale(1);
  }

  50% {
    transform: rotate(0) scale(1.3);
  }

  100% {
    transform: rotate(0) scale(1);
  }
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  font-weight: 400;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

audio:not([controls]) {
  display: none;
}

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  overflow-x: hidden;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  vertical-align: middle;
  border: 0;
  -ms-interpolation-mode: bicubic;
}

button,
input,
select,
textarea {
  margin: 0;
  font-size: 100%;
  vertical-align: middle;
}

button,
input {
  *overflow: visible;
  line-height: normal;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
}

input[type="search"] {
  box-sizing: content-box;
  -webkit-appearance: textfield;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
q:before,
blockquote:after,
q:after {
  content: '';
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  padding: 0;
  margin: 0;
}

b,
strong,
b *,
strong * {
  font-weight: 600 !important;
}

i:not(.fas):not(.fa):not(.far):not(.fab):not(.fal):not(.fad):not(.font-icon),
em:not(.fas):not(.fa):not(.far):not(.fab):not(.fal):not(.fad):not(.font-icon) {
  font-style: italic !important;
}

hr {
  display: block;
  width: 100%;
  height: 10px;
  visibility: hidden;
  opacity: 0;
  clear: both;
  float: none;
  margin: 0 !important;
  padding: 0 !important;
}

*,
*:after,
*:before {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
}

@font-face {
  font-family: "Exo2";
  font-display: swap;
  src: url("/fonts/Exo2-Regular.woff") format("woff"), url("/fonts/Exo2-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Exo2";
  font-display: swap;
  src: url("/fonts/Exo2-SemiBold.woff") format("woff"), url("/fonts/Exo2-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Exo2";
  font-display: swap;
  src: url("/fonts/Exo2-ExtraBold.woff") format("woff"), url("/fonts/Exo2-ExtraBold.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Icons";
  font-display: swap;
  src: url("/fonts/icomoon.woff") format("woff"), url("/fonts/icomoon.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "dripicons-v2";
  font-display: swap;
  src: url("/fonts/dripicons-v2.woff") format("woff"), url("/fonts/dripicons-v2.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

:root {
  --app-height: 100%;
}

.main-container {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
  max-width: 2200px;
  width: 100%;
  min-height: var(--app-height);
  margin: auto;
  overflow: hidden;
  box-shadow: 0 0 50px 0 rgba(55, 65, 74, 0.2);
}

.main-container > .page-content {
  flex: 1 1 auto;
}

.main-container > .footer {
  order: 99;
}

.resolution-wrap {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1366px;
  min-width: 320px;
  height: auto;
  min-height: 100%;
  padding: 0 16px;
}

.resolution-text-wrap {
  display: block;
  left: 0;
  width: 100%;
  max-width: 860px;
  min-width: 320px;
  height: auto;
  min-height: 100%;
  margin: auto;
  padding: 0 20px;
}

.logos-table {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.logos-table > div {
  display: flex;
  padding: 0 16px;
  margin-bottom: 16px;
  width: 33.33%;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.logos-table > div a {
  pointer-events: none !important;
}

.logos-table > div img {
  width: 100%;
}

::selection {
  background: rgba(55, 65, 74, 0.8);
  color: #fff !important;
}

::-moz-selection {
  background: rgba(55, 65, 74, 0.8);
  color: #fff !important;
}

html,
body {
  font: 400 17px/140% 'Exo2', Helvetica, Arial, sans-serif;
  line-height: 130%;
  color: #37414A;
}

.ta_l {
  text-align: left !important;
}

.ta_r {
  text-align: right !important;
}

.ta_c {
  text-align: center !important;
}

.no_select,
.no_select * {
  cursor: default;
  user-select: none !important;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background: #F4F5F5;
}

::-webkit-scrollbar-thumb {
  background: #AFB8BF;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background: #9ba4ab;
}

::-webkit-scrollbar-thumb:active {
  background: #2692E0;
}

select,
a,
button,
*:before,
*:after,
[type="button"],
[type="submit"],
[type="reset"] {
  transition: all 0.25s ease-in-out;
}

a {
  color: #127ecc;
}

body:not(.mobile) a:hover {
  color: #309cea;
}

.imgfit {
  overflow: hidden;
}

.imgfit img {
  -o-object-fit: cover !important;
  object-fit: cover !important;
  width: 100%;
  height: 100%;
  font-family: 'object-fit: cover;';
}

.centerxy {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.centerx {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.centery {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
}

[data-icon]:before {
  font-family: "dripicons-v2" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="dripicons-"]:before,
[class*=" dripicons-"]:before,
.font-icon {
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dripicons-alarm:before {
  content: "\61";
}

.dripicons-align-center:before {
  content: "\62";
}

.dripicons-align-justify:before {
  content: "\63";
}

.dripicons-align-left:before {
  content: "\64";
}

.dripicons-align-right:before {
  content: "\65";
}

.dripicons-anchor:before {
  content: "\66";
}

.dripicons-archive:before {
  content: "\67";
}

.dripicons-arrow-down:before {
  content: "\68";
}

.dripicons-arrow-left:before {
  content: "\69";
}

.dripicons-arrow-right:before {
  content: "\6a";
}

.dripicons-arrow-thin-down:before {
  content: "\6b";
}

.dripicons-arrow-thin-left:before {
  content: "\6c";
}

.dripicons-arrow-thin-right:before {
  content: "\6d";
}

.dripicons-arrow-thin-up:before {
  content: "\6e";
}

.dripicons-arrow-up:before {
  content: "\6f";
}

.dripicons-article:before {
  content: "\70";
}

.dripicons-backspace:before {
  content: "\71";
}

.dripicons-basket:before {
  content: "\72";
}

.dripicons-basketball:before {
  content: "\73";
}

.dripicons-battery-empty:before {
  content: "\74";
}

.dripicons-battery-full:before {
  content: "\75";
}

.dripicons-battery-low:before {
  content: "\76";
}

.dripicons-battery-medium:before {
  content: "\77";
}

.dripicons-bell:before {
  content: "\78";
}

.dripicons-blog:before {
  content: "\79";
}

.dripicons-bluetooth:before {
  content: "\7a";
}

.dripicons-bold:before {
  content: "\41";
}

.dripicons-bookmark:before {
  content: "\42";
}

.dripicons-bookmarks:before {
  content: "\43";
}

.dripicons-box:before {
  content: "\44";
}

.dripicons-briefcase:before {
  content: "\45";
}

.dripicons-brightness-low:before {
  content: "\46";
}

.dripicons-brightness-max:before {
  content: "\47";
}

.dripicons-brightness-medium:before {
  content: "\48";
}

.dripicons-broadcast:before {
  content: "\49";
}

.dripicons-browser:before {
  content: "\4a";
}

.dripicons-browser-upload:before {
  content: "\4b";
}

.dripicons-brush:before {
  content: "\4c";
}

.dripicons-calendar:before {
  content: "\4d";
}

.dripicons-camcorder:before {
  content: "\4e";
}

.dripicons-camera:before {
  content: "\4f";
}

.dripicons-card:before {
  content: "\50";
}

.dripicons-cart:before {
  content: "\51";
}

.dripicons-checklist:before {
  content: "\52";
}

.dripicons-checkmark:before {
  content: "\53";
}

.dripicons-chevron-down:before {
  content: "\54";
}

.dripicons-chevron-left:before {
  content: "\55";
}

.dripicons-chevron-right:before {
  content: "\56";
}

.dripicons-chevron-up:before {
  content: "\57";
}

.dripicons-clipboard:before {
  content: "\58";
}

.dripicons-clock:before {
  content: "\59";
}

.dripicons-clockwise:before {
  content: "\5a";
}

.dripicons-cloud:before {
  content: "\30";
}

.dripicons-cloud-download:before {
  content: "\31";
}

.dripicons-cloud-upload:before {
  content: "\32";
}

.dripicons-code:before {
  content: "\33";
}

.dripicons-contract:before {
  content: "\34";
}

.dripicons-contract-2:before {
  content: "\35";
}

.dripicons-conversation:before {
  content: "\36";
}

.dripicons-copy:before {
  content: "\37";
}

.dripicons-crop:before {
  content: "\38";
}

.dripicons-cross:before {
  content: "\39";
}

.dripicons-crosshair:before {
  content: "\21";
}

.dripicons-cutlery:before {
  content: "\22";
}

.dripicons-device-desktop:before {
  content: "\23";
}

.dripicons-device-mobile:before {
  content: "\24";
}

.dripicons-device-tablet:before {
  content: "\25";
}

.dripicons-direction:before {
  content: "\26";
}

.dripicons-disc:before {
  content: "\27";
}

.dripicons-document:before {
  content: "\28";
}

.dripicons-document-delete:before {
  content: "\29";
}

.dripicons-document-edit:before {
  content: "\2a";
}

.dripicons-document-new:before {
  content: "\2b";
}

.dripicons-document-remove:before {
  content: "\2c";
}

.dripicons-dot:before {
  content: "\2d";
}

.dripicons-dots-2:before {
  content: "\2e";
}

.dripicons-dots-3:before {
  content: "\2f";
}

.dripicons-download:before {
  content: "\3a";
}

.dripicons-duplicate:before {
  content: "\3b";
}

.dripicons-enter:before {
  content: "\3c";
}

.dripicons-exit:before {
  content: "\3d";
}

.dripicons-expand:before {
  content: "\3e";
}

.dripicons-expand-2:before {
  content: "\3f";
}

.dripicons-experiment:before {
  content: "\40";
}

.dripicons-export:before {
  content: "\5b";
}

.dripicons-feed:before {
  content: "\5d";
}

.dripicons-flag:before {
  content: "\5e";
}

.dripicons-flashlight:before {
  content: "\5f";
}

.dripicons-folder:before {
  content: "\60";
}

.dripicons-folder-open:before {
  content: "\7b";
}

.dripicons-forward:before {
  content: "\7c";
}

.dripicons-gaming:before {
  content: "\7d";
}

.dripicons-gear:before {
  content: "\7e";
}

.dripicons-graduation:before {
  content: "\5c";
}

.dripicons-graph-bar:before {
  content: "\e000";
}

.dripicons-graph-line:before {
  content: "\e001";
}

.dripicons-graph-pie:before {
  content: "\e002";
}

.dripicons-headset:before {
  content: "\e003";
}

.dripicons-heart:before {
  content: "\e004";
}

.dripicons-help:before {
  content: "\e005";
}

.dripicons-home:before {
  content: "\e006";
}

.dripicons-hourglass:before {
  content: "\e007";
}

.dripicons-inbox:before {
  content: "\e008";
}

.dripicons-information:before {
  content: "\e009";
}

.dripicons-italic:before {
  content: "\e00a";
}

.dripicons-jewel:before {
  content: "\e00b";
}

.dripicons-lifting:before {
  content: "\e00c";
}

.dripicons-lightbulb:before {
  content: "\e00d";
}

.dripicons-link:before {
  content: "\e00e";
}

.dripicons-link-broken:before {
  content: "\e00f";
}

.dripicons-list:before {
  content: "\e010";
}

.dripicons-loading:before {
  content: "\e011";
}

.dripicons-location:before {
  content: "\e012";
}

.dripicons-lock:before {
  content: "\e013";
}

.dripicons-lock-open:before {
  content: "\e014";
}

.dripicons-mail:before {
  content: "\e015";
}

.dripicons-map:before {
  content: "\e016";
}

.dripicons-media-loop:before {
  content: "\e017";
}

.dripicons-media-next:before {
  content: "\e018";
}

.dripicons-media-pause:before {
  content: "\e019";
}

.dripicons-media-play:before {
  content: "\e01a";
}

.dripicons-media-previous:before {
  content: "\e01b";
}

.dripicons-media-record:before {
  content: "\e01c";
}

.dripicons-media-shuffle:before {
  content: "\e01d";
}

.dripicons-media-stop:before {
  content: "\e01e";
}

.dripicons-medical:before {
  content: "\e01f";
}

.dripicons-menu:before {
  content: "\e020";
}

.dripicons-message:before {
  content: "\e021";
}

.dripicons-meter:before {
  content: "\e022";
}

.dripicons-microphone:before {
  content: "\e023";
}

.dripicons-minus:before {
  content: "\e024";
}

.dripicons-monitor:before {
  content: "\e025";
}

.dripicons-move:before {
  content: "\e026";
}

.dripicons-music:before {
  content: "\e027";
}

.dripicons-network-1:before {
  content: "\e028";
}

.dripicons-network-2:before {
  content: "\e029";
}

.dripicons-network-3:before {
  content: "\e02a";
}

.dripicons-network-4:before {
  content: "\e02b";
}

.dripicons-network-5:before {
  content: "\e02c";
}

.dripicons-pamphlet:before {
  content: "\e02d";
}

.dripicons-paperclip:before {
  content: "\e02e";
}

.dripicons-pencil:before {
  content: "\e02f";
}

.dripicons-phone:before {
  content: "\e030";
}

.dripicons-photo:before {
  content: "\e031";
}

.dripicons-photo-group:before {
  content: "\e032";
}

.dripicons-pill:before {
  content: "\e033";
}

.dripicons-pin:before {
  content: "\e034";
}

.dripicons-plus:before {
  content: "\e035";
}

.dripicons-power:before {
  content: "\e036";
}

.dripicons-preview:before {
  content: "\e037";
}

.dripicons-print:before {
  content: "\e038";
}

.dripicons-pulse:before {
  content: "\e039";
}

.dripicons-question:before {
  content: "\e03a";
}

.dripicons-reply:before {
  content: "\e03b";
}

.dripicons-reply-all:before {
  content: "\e03c";
}

.dripicons-return:before {
  content: "\e03d";
}

.dripicons-retweet:before {
  content: "\e03e";
}

.dripicons-rocket:before {
  content: "\e03f";
}

.dripicons-scale:before {
  content: "\e040";
}

.dripicons-search:before {
  content: "\e041";
}

.dripicons-shopping-bag:before {
  content: "\e042";
}

.dripicons-skip:before {
  content: "\e043";
}

.dripicons-stack:before {
  content: "\e044";
}

.dripicons-star:before {
  content: "\e045";
}

.dripicons-stopwatch:before {
  content: "\e046";
}

.dripicons-store:before {
  content: "\e047";
}

.dripicons-suitcase:before {
  content: "\e048";
}

.dripicons-swap:before {
  content: "\e049";
}

.dripicons-tag:before {
  content: "\e04a";
}

.dripicons-tag-delete:before {
  content: "\e04b";
}

.dripicons-tags:before {
  content: "\e04c";
}

.dripicons-thumbs-down:before {
  content: "\e04d";
}

.dripicons-thumbs-up:before {
  content: "\e04e";
}

.dripicons-ticket:before {
  content: "\e04f";
}

.dripicons-time-reverse:before {
  content: "\e050";
}

.dripicons-to-do:before {
  content: "\e051";
}

.dripicons-toggles:before {
  content: "\e052";
}

.dripicons-trash:before {
  content: "\e053";
}

.dripicons-trophy:before {
  content: "\e054";
}

.dripicons-upload:before {
  content: "\e055";
}

.dripicons-user:before {
  content: "\e056";
}

.dripicons-user-group:before {
  content: "\e057";
}

.dripicons-user-id:before {
  content: "\e058";
}

.dripicons-vibrate:before {
  content: "\e059";
}

.dripicons-view-apps:before {
  content: "\e05a";
}

.dripicons-view-list:before {
  content: "\e05b";
}

.dripicons-view-list-large:before {
  content: "\e05c";
}

.dripicons-view-thumb:before {
  content: "\e05d";
}

.dripicons-volume-full:before {
  content: "\e05e";
}

.dripicons-volume-low:before {
  content: "\e05f";
}

.dripicons-volume-medium:before {
  content: "\e060";
}

.dripicons-volume-off:before {
  content: "\e061";
}

.dripicons-wallet:before {
  content: "\e062";
}

.dripicons-warning:before {
  content: "\e063";
}

.dripicons-web:before {
  content: "\e064";
}

.dripicons-weight:before {
  content: "\e065";
}

.dripicons-wifi:before {
  content: "\e066";
}

.dripicons-wrong:before {
  content: "\e067";
}

.dripicons-zoom-in:before {
  content: "\e068";
}

.dripicons-zoom-out:before {
  content: "\e069";
}

.font_8,
.font_8 * {
  font: 400 13px/140% 'Exo2', Helvetica, Arial, sans-serif;
}

.font_7,
.font_7 * {
  font: 400 15px/140% 'Exo2', Helvetica, Arial, sans-serif;
}

.font_6,
.font_6 * {
  font: 400 17px/140% 'Exo2', Helvetica, Arial, sans-serif;
}

.font_5,
.font_5 * {
  font: 400 21px/140% 'Exo2', Helvetica, Arial, sans-serif;
}

.font_4,
.font_4 * {
  font: 400 25px/140% 'Exo2', Helvetica, Arial, sans-serif;
}

.font_3,
.font_3 * {
  font: 600 23px/120% 'Exo2', Helvetica, Arial, sans-serif;
}

.font_2,
.font_2 * {
  font: 600 25px/120% 'Exo2', Helvetica, Arial, sans-serif;
}

.font_1,
.font_1 * {
  font: 600 35px/120% 'Exo2', Helvetica, Arial, sans-serif;
}

.caps {
  text-transform: uppercase;
}

.headernames_box > h1 {
  font: 600 25px/120% 'Exo2', Helvetica, Arial, sans-serif;
  margin-bottom: 10px;
}

.headernames_box > h2 {
  font: 600 25px/120% 'Exo2', Helvetica, Arial, sans-serif;
  margin-bottom: 10px;
}

.headernames_box > h3 {
  font: 600 23px/120% 'Exo2', Helvetica, Arial, sans-serif;
  margin-bottom: 10px;
}

.headernames_box > h4 {
  font: 400 25px/140% 'Exo2', Helvetica, Arial, sans-serif;
  margin-bottom: 10px;
}

.text-box .row,
.text-wrap .row,
.text-box .logos,
.text-wrap .logos {
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px !important;
  margin-right: -8px !important;
}

.text-box .row[data-columns='1'] > .column,
.text-wrap .row[data-columns='1'] > .column,
.text-box .logos[data-columns='1'] > .column,
.text-wrap .logos[data-columns='1'] > .column {
  width: 100% !important;
}

.text-box .row[data-columns='2'] > .column,
.text-wrap .row[data-columns='2'] > .column,
.text-box .logos[data-columns='2'] > .column,
.text-wrap .logos[data-columns='2'] > .column {
  width: 50% !important;
}

.text-box .row[data-columns='3'] > .column,
.text-wrap .row[data-columns='3'] > .column,
.text-box .logos[data-columns='3'] > .column,
.text-wrap .logos[data-columns='3'] > .column {
  width: 33.33% !important;
}

.text-box .row[data-columns='4'] > .column,
.text-wrap .row[data-columns='4'] > .column,
.text-box .logos[data-columns='4'] > .column,
.text-wrap .logos[data-columns='4'] > .column {
  width: 25% !important;
}

.text-box .row,
.text-wrap .row {
  margin-bottom: 16px;
}

.text-box .row > .column,
.text-wrap .row > .column {
  padding-left: 8px !important;
  padding-right: 8px !important;
  display: flex;
  flex-wrap: wrap;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-bottom: 16px;
}

.text-box .row > .column .img-wrap,
.text-wrap .row > .column .img-wrap {
  position: relative;
  display: flex;
  overflow: hidden;
  padding-bottom: 60%;
  width: 100%;
  height: auto;
}

.text-box .row > .column .img-wrap img,
.text-wrap .row > .column .img-wrap img {
  position: absolute;
  width: 100% !important;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
  left: 0;
  top: 0;
}

.text-box .logos,
.text-wrap .logos {
  margin-bottom: 32px;
}

.text-box .logos .img-wrap,
.text-wrap .logos .img-wrap {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  align-content: center;
  align-items: center;
  justify-content: center;
  background: none !important;
}

.text-box .logos .img-wrap img,
.text-wrap .logos .img-wrap img {
  position: relative;
  width: auto !important;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.text-box .my-img,
.text-wrap .my-img {
  padding: 0;
}

.text-box .video-wrap,
.text-wrap .video-wrap {
  display: flex;
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.text-box .video-wrap iframe,
.text-wrap .video-wrap iframe {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.text-box .title > *,
.text-wrap .title > * {
  font: 600 35px/120% 'Exo2', Helvetica, Arial, sans-serif;
  padding-left: 20px;
  position: relative;
  margin-bottom: 8px;
}

.text-box .title > *::before,
.text-wrap .title > *::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  background: #3aa6f4;
  display: block;
  width: 2px;
  height: calc(100% - 16px);
  transform: translateY(-50%);
}

.text-box h1,
.text-wrap h1,
.text-box h2,
.text-wrap h2,
.text-box h3,
.text-wrap h3,
.text-box h4,
.text-wrap h4 {
  display: inline-block;
  width: 100%;
}

.text-box h1,
.text-wrap h1 {
  font: 600 50px/120% 'Exo2', Helvetica, Arial, sans-serif;
  letter-spacing: 0.14px;
  position: relative;
  margin-bottom: 8px;
}

.text-box h2,
.text-wrap h2 {
  font: 600 32px/120% 'Exo2', Helvetica, Arial, sans-serif;
  position: relative;
  margin-bottom: 8px;
}

.text-box h3,
.text-wrap h3 {
  font: 600 22px/120% 'Exo2', Helvetica, Arial, sans-serif !important;
  position: relative;
  margin-bottom: 8px;
}

.text-box h3 a,
.text-wrap h3 a {
  font: 600 22px/120% 'Exo2', Helvetica, Arial, sans-serif !important;
  color: #37414A;
}

.text-box h3 a:hover,
.text-wrap h3 a:hover {
  color: #3aa6f4;
}

.text-box h4,
.text-wrap h4 {
  font: 600 19px/116% 'Exo2', Helvetica, Arial, sans-serif !important;
  position: relative;
  margin-bottom: 8px;
}

.text-box h4 a,
.text-wrap h4 a {
  font: 600 19px/116% 'Exo2', Helvetica, Arial, sans-serif !important;
  color: #3aa6f4;
}

.text-box h4 a:hover,
.text-wrap h4 a:hover {
  color: #4ebaff;
}

.text-box .date,
.text-wrap .date {
  color: #616B74;
  margin-bottom: 16px;
}

.text-box .date::before,
.text-wrap .date::before {
  content: '\4d';
  color: #AFB8BF;
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-box .sub-title > *,
.text-wrap .sub-title > * {
  font: 600 23px/120% 'Exo2', Helvetica, Arial, sans-serif;
  margin-bottom: 10px;
}

.text-box .sub-sub-title,
.text-wrap .sub-sub-title {
  font: 400 25px/140% 'Exo2', Helvetica, Arial, sans-serif;
}

.text-box p,
.text-wrap p {
  font: 400 17px/140% 'Exo2', Helvetica, Arial, sans-serif;
  margin-bottom: 16px;
}

.text-box * p,
.text-wrap * p {
  margin-bottom: 0 !important;
}

.text-box .big-img,
.text-wrap .big-img {
  max-width: 100%;
  display: flex;
  border-radius: 8px;
  box-shadow: 0 6px 25px -3px rgba(0, 0, 0, 0.09);
  position: relative;
  margin-bottom: 16px;
}

.text-box .big-img img,
.text-wrap .big-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover !important;
  object-fit: cover !important;
  font-family: 'object-fit: cover;';
}

.text-box [class*='prev-img'],
.text-wrap [class*='prev-img'] {
  width: 33.33%;
  display: flex;
}

.text-box [class*='prev-img'] > div,
.text-wrap [class*='prev-img'] > div {
  overflow: hidden;
  display: flex;
  flex: 1 0 120px;
  width: 100%;
  padding-bottom: 66.25%;
  position: relative;
  margin-bottom: 16px;
  border-radius: 8px;
  box-shadow: 0 6px 25px -3px rgba(0, 0, 0, 0.09);
}

.text-box [class*='prev-img'] > div img,
.text-wrap [class*='prev-img'] > div img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover !important;
  object-fit: cover !important;
  font-family: 'object-fit: cover;';
  position: absolute;
  top: 0;
  left: 0;
}

.text-box [class*='prev-img'].prev-img__left,
.text-wrap [class*='prev-img'].prev-img__left {
  float: left;
  margin-right: 16px;
}

.text-box [class*='prev-img'].prev-img__right,
.text-wrap [class*='prev-img'].prev-img__right {
  float: right;
  margin-left: 16px;
}

.text-box .quote,
.text-wrap .quote,
.text-box .quote_c,
.text-wrap .quote_c,
.text-box blockquote,
.text-wrap blockquote {
  position: relative;
  margin-bottom: 16px;
  font-size: 16px;
  display: inline-block;
  width: 100%;
  background: #f9fafa;
  border-radius: 4px;
  padding: 20px 20px 20px 60px;
  box-shadow: 0 6px 25px -3px rgba(0, 0, 0, 0.09);
}

.text-box .quote_c,
.text-wrap .quote_c {
  text-align: center;
  padding-left: 20px !important;
}

.text-box .quote::before,
.text-wrap .quote::before,
.text-box blockquote::before,
.text-wrap blockquote::before {
  content: "";
  width: 40px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: block;
  background: #AFB8BF;
  border-radius: 4px 0 0 4px;
}

.text-box .quote::after,
.text-wrap .quote::after,
.text-box blockquote::after,
.text-wrap blockquote::after {
  content: "\49";
  width: 40px;
  height: 40px;
  position: absolute;
  color: #616b74;
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-size: 23px;
  top: 20px;
  left: 8px;
  z-index: 2;
  display: block;
}

.text-box ul:not(.todo-list):not(.numeric-list),
.text-wrap ul:not(.todo-list):not(.numeric-list) {
  list-style-type: none;
  counter-reset: num;
  margin: 0 auto 16px auto;
}

.text-box ul:not(.todo-list):not(.numeric-list) > li,
.text-wrap ul:not(.todo-list):not(.numeric-list) > li {
  position: relative;
  list-style: none;
  min-height: 25px;
  padding-left: 18px;
}

.text-box ul:not(.todo-list):not(.numeric-list) > li:not(:last-of-type),
.text-wrap ul:not(.todo-list):not(.numeric-list) > li:not(:last-of-type) {
  margin-bottom: 16px;
}

.text-box ul:not(.todo-list):not(.numeric-list) > li:before,
.text-wrap ul:not(.todo-list):not(.numeric-list) > li:before {
  content: '';
  width: 8px;
  height: 8px;
  position: absolute;
  left: 2px;
  top: 7px;
  z-index: 3;
  background: rgba(58, 166, 244, 0.7);
  border-radius: 100%;
}

.text-box ul:not(.todo-list):not(.numeric-list) > li > *,
.text-wrap ul:not(.todo-list):not(.numeric-list) > li > * {
  margin: 10px auto 5px;
}

.text-box ul:not(.todo-list):not(.numeric-list) > li > * > li:before,
.text-wrap ul:not(.todo-list):not(.numeric-list) > li > * > li:before {
  content: '';
  width: 8px;
  height: 8px;
  position: absolute;
  left: 2px;
  top: 7px;
  z-index: 3;
  background: #AFB8BF;
  border-radius: 100%;
}

.text-box ul.todo-list,
.text-wrap ul.todo-list {
  list-style-type: none;
  counter-reset: num;
  margin: 0 auto 32px auto;
}

.text-box ul.todo-list > li,
.text-wrap ul.todo-list > li {
  position: relative;
  list-style: none;
  margin-bottom: 15px;
  min-height: 25px;
  padding-left: 18px;
}

.text-box ul.todo-list > li label,
.text-wrap ul.todo-list > li label {
  position: absolute;
  left: 0;
  top: -2px;
  margin-right: 10px;
}

.text-box ol,
.text-wrap ol {
  list-style-type: none;
  counter-reset: num;
  margin: 0 auto 16px auto;
}

.text-box ol > li,
.text-wrap ol > li {
  position: relative;
  list-style: none;
  margin-bottom: 15px;
  min-height: 25px;
  padding-left: 30px;
}

.text-box ol > li::before,
.text-wrap ol > li::before {
  content: counter(num) ' ' !important;
  counter-increment: num !important;
  color: #fff;
  background: #3aa6f4;
  width: 23px;
  height: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: absolute;
  line-height: 17px;
  left: 0;
  top: 0px;
  font-size: 12px;
  font-weight: 800;
  border-radius: 8px;
}

.text-box .action-color,
.text-wrap .action-color {
  color: #2692E0;
}

.text-box .anchors a,
.text-wrap .anchors a {
  text-decoration: none;
  color: #DBDFE2;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.2);
}

.text-box .anchors a:hover,
.text-wrap .anchors a:hover {
  border-bottom: 1px dashed rgba(255, 255, 255, 0.8);
}

.text-box .numeric-hn,
.text-wrap .numeric-hn {
  position: relative;
  display: flex;
  align-content: flex-start;
  align-items: flex-start;
  margin-bottom: 16px;
}

.text-box .numeric-hn > span,
.text-wrap .numeric-hn > span {
  display: flex;
  align-self: stretch;
  min-height: 100%;
  align-content: center;
  align-items: center;
  font: 600 32px/120% 'Exo2', Helvetica, Arial, sans-serif;
}

.text-box .numeric-hn::before,
.text-wrap .numeric-hn::before {
  content: attr(data-numeric);
  margin-right: 16px;
  color: #3aa6f4;
  display: flex;
  flex: 0 0 46px;
  width: 46px;
  min-width: 46px;
  height: 46px;
  align-content: center;
  align-items: center;
  justify-content: center;
  position: relative;
  line-height: 17px;
  left: 0;
  top: 0;
  font-size: 22px;
  font-weight: 800;
  border-radius: 8px;
}

.text-box .numeric-list > li,
.text-wrap .numeric-list > li {
  padding-left: 65px;
  margin-bottom: 32px;
  position: relative;
}

.text-box .numeric-list > li > ul.numeric-list > li,
.text-wrap .numeric-list > li > ul.numeric-list > li {
  font: 400 15px/125% 'Exo2', Helvetica, Arial, sans-serif !important;
  color: #AFB8BF;
}

.text-box .numeric-list > li > ul.numeric-list > li .numeric-list__number,
.text-wrap .numeric-list > li > ul.numeric-list > li .numeric-list__number {
  font-size: 14px !important;
}

.text-box .numeric-list > li > ul.numeric-list > li > ul.numeric-list > li,
.text-wrap .numeric-list > li > ul.numeric-list > li > ul.numeric-list > li {
  padding-left: 90px !important;
}

.text-box .numeric-list > li > ul.numeric-list > li > ul.numeric-list > li .numeric-list__number,
.text-wrap .numeric-list > li > ul.numeric-list > li > ul.numeric-list > li .numeric-list__number {
  font-size: 13px !important;
}

.text-box .numeric-list__number,
.text-wrap .numeric-list__number {
  margin-right: 16px;
  background: #4b555e;
  display: inline-flex;
  width: auto;
  height: auto;
  align-content: center;
  align-items: center;
  justify-content: center;
  position: absolute;
  padding: 8px 11px 6px 13px;
  left: 0;
  top: -3px;
  color: #AFB8BF;
  font-size: 16px;
  line-height: 16px !important;
  font-weight: 600;
  border-radius: 8px;
}

.text-box .table-scroll,
.text-wrap .table-scroll {
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
}

.text-box table.adaptive-table,
.text-wrap table.adaptive-table {
  border-radius: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.text-box table.adaptive-table thead,
.text-wrap table.adaptive-table thead {
  display: none;
}

.text-box table.adaptive-table tr,
.text-wrap table.adaptive-table tr {
  display: grid;
  border: 1px solid #eaebeb;
  grid-template-rows: auto;
  grid-template-columns: 0.5fr 0.5fr 1fr;
  background: #fff;
  margin-bottom: 16px;
  border-radius: 16px;
  box-shadow: 0 6px 25px -3px rgba(0, 0, 0, 0.09);
  padding: 6px 8px 8px;
}

.text-box table.adaptive-table tr > td,
.text-wrap table.adaptive-table tr > td {
  font-size: 14px;
  display: inline-flex;
  flex-direction: column;
  padding: 6px 16px 16px;
  text-align: left;
  position: relative;
}

.text-box table.adaptive-table tr > td:nth-child(1),
.text-wrap table.adaptive-table tr > td:nth-child(1) {
  grid-row-start: 1;
  grid-column-start: 1;
  grid-row-end: 2;
  grid-column-end: 2;
}

.text-box table.adaptive-table tr > td:nth-child(2),
.text-wrap table.adaptive-table tr > td:nth-child(2) {
  grid-row-start: 2;
  grid-column-start: 1;
  grid-row-end: 3;
  grid-column-end: 2;
}

.text-box table.adaptive-table tr > td:nth-child(3),
.text-wrap table.adaptive-table tr > td:nth-child(3) {
  grid-row-start: 3;
  grid-column-start: 1;
  grid-row-end: 4;
  grid-column-end: 2;
}

.text-box table.adaptive-table tr > td:nth-child(4),
.text-wrap table.adaptive-table tr > td:nth-child(4) {
  grid-row-start: 1;
  grid-column-start: 2;
  grid-row-end: 2;
  grid-column-end: 3;
}

.text-box table.adaptive-table tr > td:nth-child(5),
.text-wrap table.adaptive-table tr > td:nth-child(5) {
  grid-row-start: 2;
  grid-column-start: 2;
  grid-row-end: 3;
  grid-column-end: 3;
}

.text-box table.adaptive-table tr > td:nth-child(6),
.text-wrap table.adaptive-table tr > td:nth-child(6) {
  grid-row-start: 3;
  grid-column-start: 2;
  grid-row-end: 4;
  grid-column-end: 3;
}

.text-box table.adaptive-table tr > td:nth-child(7),
.text-wrap table.adaptive-table tr > td:nth-child(7) {
  grid-row-start: 1;
  grid-column-start: 3;
  grid-row-end: 4;
  grid-column-end: 4;
}

.text-box table.adaptive-table tr > td::before,
.text-wrap table.adaptive-table tr > td::before {
  content: attr(data-thn);
  display: flex;
  width: calc(100% + 26px);
  font: 600 16px/110% 'Exo2', Helvetica, Arial, sans-serif;
  padding: 10px 13px;
  margin-bottom: 4px;
  background: #F4F5F5;
  border-radius: 8px;
  left: -13px;
  position: relative;
}

.text-box table.adaptive-table tr > td::after,
.text-wrap table.adaptive-table tr > td::after {
  content: '';
  width: 1px;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
}

.text-gradient {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
}

[class*='list_v'] {
  margin: 0 auto 20px;
}

[class*='list_v'] li {
  position: relative;
  margin-bottom: 15px;
  padding-left: 25px;
}

.list_v1 li:before {
  content: '\53';
  width: 20px;
  height: 20px;
  position: absolute;
  left: -3px;
  top: 0;
  font-family: "dripicons-v2" !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-size: 17px;
  text-rendering: auto;
  font-style: normal !important;
  color: #3aa6f4;
}

.list_v2 li:before {
  content: "\39";
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  top: 4px;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-size: 16px;
  text-rendering: auto;
  color: #2692E0 !important;
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.list_v3 {
  list-style-type: none;
  counter-reset: num;
  margin-bottom: 40px;
}

.list_v3 > li {
  list-style: none;
  margin-bottom: 15px;
  min-height: 25px;
}

.list_v3 > li::before {
  content: counter(num) ' ' !important;
  counter-increment: num !important;
  color: #fff;
  background: #2692E0;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: absolute;
  line-height: 17px;
  left: -8px;
  top: 0;
  font-size: 13px;
  font-weight: 600;
  border-radius: 8px;
}

.list_v4 li {
  padding: 0 0 14px 0;
  border-bottom: 1px solid #F4F5F5;
}

.list_v5 > li {
  margin-bottom: 10px;
}

.list_v5 > li::before {
  content: "";
  width: 6px;
  height: 6px;
  position: absolute;
  left: 6px;
  top: 8px;
  border-radius: 100%;
  z-index: 3;
  background: #3aa6f4;
}

.list_v5 > li > ul {
  margin: 13px auto 0;
}

.list_v5 > li > ul > li {
  width: 100%;
}

.list_v5 > li > ul > li::before {
  content: "";
  width: 6px;
  height: 6px;
  position: absolute;
  left: 6px;
  top: 8px;
  border-radius: 100%;
  z-index: 3;
  background: #F4F5F5;
}

.list_v6 > li {
  margin-bottom: 8px;
  padding-left: 13px;
}

.list_v6 > li::before {
  content: '\e01c';
  width: 10px;
  height: 10px;
  position: absolute;
  left: -3px;
  top: 3px;
  font-size: 6px;
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  color: #2692E0;
}

.list_v6 > li > ul {
  margin: 13px auto 0;
}

.list_v6 > li > ul > li {
  width: 100%;
}

.list_v6 > li > ul > li::before {
  content: '\e01c';
  background-position: 50% -360px;
  width: 10px;
  height: 10px;
  position: absolute;
  left: -3px;
  top: 1px;
  font-size: 9px;
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  color: #F4F5F5;
}

.btn__theme-1 {
  display: flex;
  border: none;
  background: none;
  outline: none;
  border-radius: 8px !important;
  font: 600 20px/120% 'Exo2', Helvetica, Arial, sans-serif;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: auto;
  min-width: 120px;
  height: 54px;
  padding: 0 32px;
  background: #3aa6f4;
  text-align: center;
  text-decoration: none;
  color: #fff;
  white-space: nowrap;
  transition: all 0.2s ease-out;
  box-shadow: 0 4px 18px 0 rgba(58, 166, 244, 0.1);
}

.btn__theme-1 > *:not(:last-child) {
  margin-right: 7px;
}

.btn__theme-1 > i {
  margin-left: 8px;
}

.btn__theme-2 {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: auto;
  min-width: 120px;
  height: 54px;
  padding: 0 24px;
  text-align: center;
  text-decoration: none;
  box-shadow: inset 0 0 0 1px #3aa6f4;
  background: none;
  color: #37414A !important;
  white-space: nowrap;
  transition: all 0.2s ease-out;
  border-radius: 8px;
  background: #3aa6f4;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
  position: relative;
}

.btn__theme-2::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 8px;
  box-shadow: inset 1px 1px 0 0 #2692e0;
}

.btn__theme-2 > *:not(:last-child) {
  margin-right: 7px;
}

.btn__theme-2:active {
  transform: translateY(1px);
}

.btn__theme-3 {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: auto;
  height: 54px;
  padding: 0 24px;
  text-align: center;
  text-decoration: none;
  border: 1px solid #fff;
  color: #37414A;
  background: #fff;
  white-space: nowrap;
  transition: all 0.2s ease-out;
  border-radius: 8px;
  box-shadow: 0 6px 25px -3px rgba(0, 0, 0, 0.09);
}

.btn__theme-3:active {
  transform: translateY(1px);
}

.btn__theme-4 {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: auto;
  height: 54px;
  padding: 0 24px;
  text-align: center;
  text-decoration: none;
  border: 1px solid #fff;
  color: #fff;
  background: none;
  white-space: nowrap;
  transition: all 0.2s linear !important;
  border-radius: 8px;
}

.btn__theme-4:active {
  transform: translateY(1px);
}

[class*="style-btn__"] {
  display: flex;
  display: -ms-flexbox;
  cursor: default;
  user-select: none !important;
}

[class*="style-btn__"] > * i {
  margin-right: 10px;
}

.style-btn__1 > * {
  display: flex;
  border: none;
  background: none;
  outline: none;
  border-radius: 8px !important;
  font: 600 20px/120% 'Exo2', Helvetica, Arial, sans-serif;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: auto;
  min-width: 120px;
  height: 54px;
  padding: 0 32px;
  background: #3aa6f4;
  text-align: center;
  text-decoration: none;
  color: #fff;
  white-space: nowrap;
  transition: all 0.2s ease-out;
  box-shadow: 0 4px 18px 0 rgba(58, 166, 244, 0.1);
}

.style-btn__1 > * > *:not(:last-child) {
  margin-right: 7px;
}

.style-btn__1 > * > i {
  margin-left: 8px;
}

.style-btn__2 > * {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: auto;
  min-width: 120px;
  height: 54px;
  padding: 0 24px;
  text-align: center;
  text-decoration: none;
  box-shadow: inset 0 0 0 1px #3aa6f4;
  background: none;
  color: #37414A !important;
  white-space: nowrap;
  transition: all 0.2s ease-out;
  border-radius: 8px;
  background: #3aa6f4;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
  position: relative;
}

.style-btn__2 > *::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 8px;
  box-shadow: inset 1px 1px 0 0 #2692e0;
}

.style-btn__2 > * > *:not(:last-child) {
  margin-right: 7px;
}

.style-btn__2 > *:active {
  transform: translateY(1px);
}

.style-btn__3 > * {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: auto;
  height: 54px;
  padding: 0 24px;
  text-align: center;
  text-decoration: none;
  border: 1px solid #fff;
  color: #37414A;
  background: #fff;
  white-space: nowrap;
  transition: all 0.2s ease-out;
  border-radius: 8px;
  box-shadow: 0 6px 25px -3px rgba(0, 0, 0, 0.09);
}

.style-btn__3 > *:active {
  transform: translateY(1px);
}

body:not(.mobile) .style-btn__1 > *:not([disabled]):hover,
body:not(.mobile) .btn__theme-1:not([disabled]):hover {
  cursor: pointer !important;
  background: #3aa6f4;
  color: #fff;
  box-shadow: 0 2px 20px 0 rgba(38, 146, 224, 0.45), inset 0 0 30px 0 rgba(38, 146, 224, 0.55);
}

body:not(.mobile) .style-btn__2 > *:not([disabled]):hover,
body:not(.mobile) .btn__theme-2:not([disabled]):hover {
  cursor: pointer !important;
  box-shadow: inset 0 0 0 1px #2692e0;
  color: #fff !important;
  background: #3aa6f4;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
}

body:not(.mobile) .style-btn__3 > *:not([disabled]):hover,
body:not(.mobile) .btn__theme-3:not([disabled]):hover {
  cursor: pointer !important;
  background: #fff;
  color: #37414A !important;
}

body:not(.mobile) .style-btn__4 > *:not([disabled]):hover,
body:not(.mobile) .btn__theme-4:not([disabled]):hover {
  cursor: pointer !important;
  background: none;
  box-shadow: inset 0 0 0 5px #ffffff;
  color: #fff !important;
}

.btns_group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-content: flex-start;
  align-items: stretch;
}

.btn_l {
  display: flex;
  justify-content: flex-start !important;
}

.btn_c {
  display: flex;
  justify-content: center !important;
}

.btn_r {
  display: flex;
  justify-content: flex-end !important;
}

#go-top {
  position: fixed;
  bottom: 65px;
  right: 10px;
  display: none;
  width: 40px;
  height: 40px;
  z-index: 99;
}

#go-top > * {
  display: block;
  text-align: center;
  cursor: pointer;
  width: inherit;
  height: inherit;
  background: #2692E0;
  border: 1px solid #2692E0;
  collapse: #3aa6f4;
  border-radius: 8px;
  transform: scale(0.95);
  box-shadow: 0 5px 17px 0 rgba(38, 146, 224, 0.8);
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

#go-top > *::after {
  content: 'n';
  font-size: 15px;
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  color: #fff;
}

#go-top > *:active {
  transform: scale(0.92);
}

.btn-more {
  display: inline-flex;
  min-height: 45px;
  color: #fff;
  font: 400 20px/110% 'Exo2', Helvetica, Arial, sans-serif;
  user-select: none;
  padding: 10px 0;
  text-decoration: none;
  align-content: center;
  align-items: center;
  justify-content: center;
  text-transform: lowercase;
  transition: all 0.2s linear;
  background: #3aa6f4;
  position: relative;
}

.btn-more:focus,
.btn-more:active {
  color: #2692E0 !important;
  background: #3aa6f4;
}

.btn-more:focus::after,
.btn-more:active::after {
  color: #2692E0 !important;
}

.btn-more:active {
  transform: translateY(2px);
}

.btn-more::after {
  content: 'V';
  padding-top: 2px;
  position: relative;
  top: 2px;
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-left: 10px;
}

body:not(.mobile) .btn-more:hover {
  color: white;
  cursor: pointer;
  background: #3aa6f4;
  padding-left: 3px;
}

body:not(.mobile) .btn-more:hover::after {
  color: white;
  margin-left: 13px;
}

input:not[type=checkbox] {
  -webkitw: none;
  -webkit-box-appearance: none;
  box-shado-shadow: none;
  border-radius: 0 !important;
  -webkit-appearance: none;
}

input,
textarea {
  outline: none;
}

input:active,
textarea:active {
  outline: none;
}

*:focus {
  outline: none;
}

textarea {
  resize: none;
}

textarea {
  resize: vertical;
}

textarea {
  resize: horizontal;
}

button::-moz-focus-inner {
  border: 0;
}

a.active.focus,
a.active:focus,
a.focus,
a:active.focus,
a:active:focus,
a:focus,
button:active,
button.active.focus,
button.active:focus,
button.focus,
button:active.focus,
button:active:focus,
button:focus,
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
  outline: 0 !important;
  outline-color: transparent !important;
  outline-width: 0 !important;
  outline-style: none !important;
  box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
}

a.active.focus button:active,
a.active:focus button:active,
a.focus button:active,
a:active.focus button:active,
a:active:focus button:active,
a:focus button:active,
button:active button:active,
button.active.focus button:active,
button.active:focus button:active,
button.focus button:active,
button:active.focus button:active,
button:active:focus button:active,
button:focus button:active,
.btn.active.focus button:active,
.btn.active:focus button:active,
.btn.focus button:active,
.btn:active.focus button:active,
.btn:active:focus button:active,
.btn:focus button:active,
a.active.focus button:focus,
a.active:focus button:focus,
a.focus button:focus,
a:active.focus button:focus,
a:active:focus button:focus,
a:focus button:focus,
button:active button:focus,
button.active.focus button:focus,
button.active:focus button:focus,
button.focus button:focus,
button:active.focus button:focus,
button:active:focus button:focus,
button:focus button:focus,
.btn.active.focus button:focus,
.btn.active:focus button:focus,
.btn.focus button:focus,
.btn:active.focus button:focus,
.btn:active:focus button:focus,
.btn:focus button:focus {
  outline: none !important;
}

*:focus,
*:active,
*::-moz-focus-inner {
  outline: none !important;
  border: 0;
  outline: 0 !important;
  outline-offset: 0 !important;
}

form.form_style_1 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
}

form.form_style_1 > * {
  position: relative;
}

form.form_style_1 > * > * {
  position: relative;
}

.form_style_1 button:not(.btn_search):not(.icn_btn):not(.btn_search2),
.form_style_1 input[type="button"]:not(.btn_search):not(.btn_search2),
.form_style_1 input[type="submit"]:not(.btn_search):not(.btn_search2),
.form_style_1 input[type="reset"]:not(.btn_search):not(.btn_search2) {
  display: flex;
  border: none;
  background: none;
  outline: none;
  border-radius: 8px !important;
  font: 600 20px/120% 'Exo2', Helvetica, Arial, sans-serif;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: auto;
  min-width: 120px;
  height: 54px;
  padding: 0 32px;
  background: #3aa6f4;
  text-align: center;
  text-decoration: none;
  color: #fff;
  white-space: nowrap;
  transition: all 0.2s ease-out;
  box-shadow: 0 4px 18px 0 rgba(58, 166, 244, 0.1);
  transition: all 0.2s ease-in-out;
}

.form_style_1 button:not(.btn_search):not(.icn_btn):not(.btn_search2) > *:not(:last-child),
.form_style_1 input[type="button"]:not(.btn_search):not(.btn_search2) > *:not(:last-child),
.form_style_1 input[type="submit"]:not(.btn_search):not(.btn_search2) > *:not(:last-child),
.form_style_1 input[type="reset"]:not(.btn_search):not(.btn_search2) > *:not(:last-child) {
  margin-right: 7px;
}

.form_style_1 button:not(.btn_search):not(.icn_btn):not(.btn_search2) > i,
.form_style_1 input[type="button"]:not(.btn_search):not(.btn_search2) > i,
.form_style_1 input[type="submit"]:not(.btn_search):not(.btn_search2) > i,
.form_style_1 input[type="reset"]:not(.btn_search):not(.btn_search2) > i {
  margin-left: 8px;
}

.form_main_parameters {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: center;
  text-decoration: none;
  width: 100%;
  height: 54px;
  padding: 0;
  color: #AFB8BF;
  border-radius: 8px;
  border-width: 0 0 1px 0 !important;
  border-bottom: 1px solid #AFB8BF;
  background: #fff;
  font: 400 15px/140% 'Exo2', Helvetica, Arial, sans-serif !important;
  line-height: normal !important;
  padding: 0 20px 0 19.2px;
}

.form_style_1 input:not([type="radio"]):not([type="checkbox"]):not([type="button"]):not([type="submit"]):not([type="reset"]):not([type="color"]):not([type="range"]):not(.input_avatar) {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: center;
  text-decoration: none;
  width: 100%;
  height: 54px;
  padding: 0;
  color: #AFB8BF;
  border-radius: 8px;
  border-width: 0 0 1px 0 !important;
  border-bottom: 1px solid #AFB8BF;
  background: #fff;
  font: 400 15px/140% 'Exo2', Helvetica, Arial, sans-serif !important;
  line-height: normal !important;
  padding: 0 20px 0 19.2px;
}

.form_style_1 input:not([type="radio"]):not([type="checkbox"]):not([type="button"]):not([type="submit"]):not([type="reset"]):not([type="color"]):not([type="range"]):not(.input_avatar):focus {
  border: 2px solid #3aa6f4 !important;
  font: 400 15px/140% 'Exo2', Helvetica, Arial, sans-serif;
  color: #37414A;
}

.form_style_1 textarea,
.form_style_1 select,
.form_style_1 .file_upload > div,
.form_style_1 .type_color_box,
.form_style_1 .inputfile_6 + label {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: center;
  text-decoration: none;
  width: 100%;
  height: 54px;
  padding: 0;
  color: #AFB8BF;
  border-radius: 8px;
  border-width: 0 0 1px 0 !important;
  border-bottom: 1px solid #AFB8BF;
  background: #fff;
  font: 400 15px/140% 'Exo2', Helvetica, Arial, sans-serif !important;
  line-height: normal !important;
  padding: 0 20px 0 19.2px;
}

.form_style_1 textarea:focus,
.form_style_1 select:focus,
.form_style_1 .file_upload > div:focus,
.form_style_1 .type_color_box:focus,
.form_style_1 .inputfile_6 + label:focus {
  border: 2px solid #3aa6f4 !important;
}

.form_style_1 .custom_select_container {
  background: #fff;
  border-width: 0 0 1px 0 !important;
  border-bottom: 1px solid #AFB8BF;
  height: 54px;
  display: block;
  width: 100%;
  top: 0;
  border-radius: 8px;
  z-index: 1;
}

.form_style_1 .custom_select_container select {
  border: none !important;
  background: none;
  display: block;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  padding: 0 40px 2px 19.2px;
  color: #AFB8BF;
  font: 400 15px/140% 'Exo2', Helvetica, Arial, sans-serif;
}

.form_style_1 .custom_select_container select::-ms-expand {
  display: none !important;
}

.form_style_1 .custom_select_container select:focus {
  color: #37414A !important;
}

.form_style_1 .custom_select_container select:not(:focus) {
  color: #AFB8BF !important;
}

.form_style_1 .custom_select_container::before {
  display: flex !important;
  justify-content: center !important;
  align-content: center !important;
  align-items: center !important;
  content: '\54';
  width: 54px;
  height: 54px;
  position: absolute;
  z-index: -1;
  font-size: 20px;
  right: 3px;
  top: 50%;
  transform: translateY(-50%) scale(1);
  color: #AFB8BF;
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.form_style_1 .custom_select_container.active::before {
  color: #3aa6f4;
  transform: translateY(-50%) scale(-1);
}

.custom_select_container option {
  height: 54px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #37414A;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

body:not(.mobile) .form_style_1 .custom-select_container:hover {
  cursor: pointer;
}

body:not(.mobile) .form_style_1 .custom-select_container:hover::before {
  color: #3aa6f4;
}

.select_box {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: stretch;
  width: 100%;
  height: 54px;
  max-height: 54px;
  cursor: pointer;
}

.select_box.active > div {
  align-self: start;
  top: 0;
  background: #fff;
  box-shadow: inset 0 0 1px 0 #F4F5F5, 0 5px 40px -6px rgba(0, 0, 0, 0.2);
}

.select_box.active > div > *:not(:last-child) {
  box-shadow: inset 0 -1px 0 0 #F4F5F5;
}

.select_box.active > div > *:first-child:after {
  transform: translateY(-50%) scaleY(-1);
}

.select_box:not(.active) > div > *:not(:first-child) {
  display: none;
  cursor: pointer;
}

.select_box * {
  transition: all 0.2s ease-out;
}

.select_box > div {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
  width: 100%;
  position: relative;
  height: auto;
  z-index: 10;
}

.select_box > div > * {
  display: block;
  text-align: left;
  padding: 0 20px 0 8px;
  text-decoration: underline;
  height: 30px;
  font: 400 21px/140% 'Exo2', Helvetica, Arial, sans-serif;
  color: #37414A;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  white-space: nowrap;
}

.select_box > div > *:hover {
  cursor: pointer !important;
}

.select_box > div > *:first-child:after {
  content: "\54";
  display: block;
  position: absolute;
  font-size: 11px;
  top: 50%;
  transform: translateY(-50%);
  right: 3px;
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #DBDFE2;
  z-index: 0;
}

.select_box.select_box_theme1 {
  border-radius: 8px;
  background: #37414A;
}

.select_box.select_box_theme1:not(.active) > div > *:not(:first-child) {
  display: none;
}

.select_box.select_box_theme1.active > div {
  align-self: start;
  top: 0;
  background: #37414A;
  -webkit-box-shadow: 0 5px 40px -6px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 40px -6px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 40px -6px rgba(0, 0, 0, 0.2);
}

.select_box.select_box_theme1.active > div > * {
  color: #fff;
}

.select_box.select_box_theme1.active > div > *:not(:last-child) {
  box-shadow: inset 0 -1px 0 0 #616B74;
}

.select_box.select_box_theme1.active > div > *:not(:first-child):hover {
  background: none;
  color: #2692E0;
}

.select_box.select_box_theme1.active > div > *:first-child:after {
  transform: translateY(-50%) scaleY(-1);
}

.select_box.select_box_theme1 * {
  cursor: default;
  user-select: none !important;
  transition: all 0.2s ease-out;
}

.select_box.select_box_theme1 > div {
  z-index: 10;
  border-radius: 8px;
}

.select_box.select_box_theme1 > div > * {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  text-align: left;
  padding: 0 50px 0 20px;
  min-height: 54px;
  font: 400 15px/140% 'Exo2', Helvetica, Arial, sans-serif;
  text-decoration: none;
  color: #F4F5F5;
}

.select_box.select_box_theme1 > div > *:first-child:after {
  content: '\54';
  display: block;
  position: absolute;
  font-size: 20px;
  top: 27px;
  right: 25px;
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #DBDFE2;
}

body:not(.mobile) .select_box.select_box_theme1:hover {
  cursor: pointer !important;
}

body:not(.mobile) .select_box.select_box_theme1.active > div > *:not(:first-child):hover {
  background: none;
  color: #2692E0;
  cursor: pointer !important;
}

.select_box.select_box_theme2 {
  border-radius: 8px;
  background: none;
  border: none;
}

.select_box.select_box_theme2:not(.active) > div > *:not(:first-child) {
  display: none;
}

.select_box.select_box_theme2.active > div {
  align-self: start;
  top: 0;
  background: #fff;
  box-shadow: 0 6px 25px -3px rgba(0, 0, 0, 0.09);
}

.select_box.select_box_theme2.active > div > * {
  color: #37414A;
}

.select_box.select_box_theme2.active > div > *:first-child:after {
  color: #3aa6f4;
}

.select_box.select_box_theme2.active > div > *:not(:last-child) {
  box-shadow: inset 0 -1px 0 0 #F4F5F5;
}

.select_box.select_box_theme2.active > div > *:not(:first-child):hover {
  background: none;
  color: #2692E0;
}

.select_box.select_box_theme2.active > div > *:first-child:after {
  transform: translateY(-50%) scaleY(-1);
}

.select_box.select_box_theme2 * {
  cursor: default;
  user-select: none !important;
  transition: all 0.2s ease-out;
}

.select_box.select_box_theme2 > div {
  z-index: 10;
  border-radius: 8px;
  background: #fff;
  border-bottom: 1px solid #AFB8BF;
}

.select_box.select_box_theme2 > div > * {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  text-align: left;
  padding: 0 50px 0 20px;
  min-height: 54px;
  font: 400 15px/140% 'Exo2', Helvetica, Arial, sans-serif;
  text-decoration: none;
  color: #AFB8BF;
}

.select_box.select_box_theme2 > div > *:first-child:after {
  content: '\54';
  display: block;
  position: absolute;
  font-size: 20px;
  top: 27px;
  right: 25px;
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #AFB8BF;
}

body:not(.mobile) .select_box.select_box_theme1:hover {
  cursor: pointer !important;
}

body:not(.mobile) .select_box.select_box_theme1.active > div > *:not(:first-child):hover {
  background: none;
  color: #2692E0;
  cursor: pointer !important;
}

.form_style_1 .type-week,
.form_style_1 .type-date {
  background: none;
}

.form_style_1 .type-week:before,
.form_style_1 .type-date:before {
  content: '\4d';
  width: 40px;
  height: 40px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  color: #DBDFE2;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.form_style_1 .type-week:after,
.form_style_1 .type-date:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  width: 100%;
  height: 40px;
  background: #fff;
}

.form_style_1 .type-week input,
.form_style_1 .type-date input {
  z-index: 3;
  background: none !important;
}

.placeholder_style_inputs {
  color: #AFB8BF !important;
  opacity: 0;
  transition: opacity 0.3s ease;
  line-height: normal;
}

.placeholder_style_textarea {
  color: #AFB8BF;
  opacity: 0;
  transition: opacity 0.3s ease;
  line-height: 51px !important;
}

.placeholder_focus {
  opacity: 0;
  transition: opacity 0.3s ease;
  font: 400 15px/140% 'Exo2', Helvetica, Arial, sans-serif;
}

input::-webkit-input-placeholder {
  color: #AFB8BF !important;
  opacity: 0;
  transition: opacity 0.3s ease;
  line-height: normal;
}

textarea::-webkit-input-placeholder {
  color: #AFB8BF;
  opacity: 0;
  transition: opacity 0.3s ease;
  line-height: 51px !important;
}

input::-moz-placeholder {
  color: #AFB8BF !important;
  opacity: 0;
  transition: opacity 0.3s ease;
  line-height: normal;
  line-height: 52px;
}

textarea::-moz-placeholder {
  color: #AFB8BF;
  opacity: 0;
  transition: opacity 0.3s ease;
  line-height: 51px !important;
  line-height: 52px;
}

input:-moz-placeholder {
  color: #AFB8BF !important;
  opacity: 0;
  transition: opacity 0.3s ease;
  line-height: normal;
}

textarea:-moz-placeholder {
  color: #AFB8BF;
  opacity: 0;
  transition: opacity 0.3s ease;
  line-height: 51px !important;
}

input:-ms-input-placeholder {
  color: #AFB8BF !important;
  opacity: 0;
  transition: opacity 0.3s ease;
  line-height: normal;
}

textarea:-ms-input-placeholder {
  color: #AFB8BF;
  opacity: 0;
  transition: opacity 0.3s ease;
  line-height: 51px !important;
}

input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
  font: 400 15px/140% 'Exo2', Helvetica, Arial, sans-serif;
}

input:focus::-moz-placeholder,
textarea:focus::-moz-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
  font: 400 15px/140% 'Exo2', Helvetica, Arial, sans-serif;
}

input:focus:-moz-placeholder,
textarea:focus:-moz-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
  font: 400 15px/140% 'Exo2', Helvetica, Arial, sans-serif;
}

input:focus:-ms-input-placeholder,
textarea:focus:-ms-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
  font: 400 15px/140% 'Exo2', Helvetica, Arial, sans-serif;
}

.checkbox,
.radio {
  position: relative;
  cursor: pointer;
  margin-right: 10px;
}

.checkbox *,
.radio * {
  cursor: default;
  user-select: none !important;
  cursor: pointer !important;
  width: 28px;
  height: 28px;
}

.checkbox::selection,
.radio::selection {
  background: transparent;
}

.checkbox input + span,
.radio input + span {
  background: #fff;
  border: 1px solid #bdc1c4 !important;
  content: "";
  display: inline-block;
  margin: 0 10px 0 0;
  padding: 0;
  width: 18px;
  height: 18px;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
}

.checkbox input:not(:checked) + span::after,
.radio input:not(:checked) + span::after {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.2s;
}

.checkbox input:not[disabled]:active + span,
.radio:not[disabled] input:active + span {
  box-shadow: inset 0 0 0 2px rgba(15, 185, 177, 0.2);
}

.checkbox input:focus + span,
.radio input:focus + span {
  box-shadow: inset 0 0 0 2px rgba(15, 185, 177, 0.2);
}

.checkbox input:checked + span::after,
.radio input:checked + span::after {
  transform: translate(-50%, -50%) scale(1);
}

.checkbox input,
.radio input {
  position: absolute;
  opacity: 0;
}

.checkbox input + span {
  border-radius: 3px;
}

.checkbox input + span::after {
  content: '\53';
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  left: 50%;
  color: #2692E0;
  font-size: 16px;
  width: 14px;
  height: 14px;
}

.radio input + span {
  border-radius: 100%;
}

.radio input + span::after {
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 9px;
  height: 9px;
  transform: translate(-50%, -50%) scale(0);
}

.radio input:checked + span::after {
  background: #3aa6f4;
}

.form_style_1 input[type="checkbox"] + .form_err_def {
  display: block;
  background: #37414A;
  border: 1px solid #616B74;
  color: #F4F5F5;
  padding: 10px 13px;
  border-radius: 8px !important;
  position: absolute;
  height: auto !important;
  bottom: -35px !important;
  top: auto !important;
  left: -4px !important;
}

.form_style_1 input[type="checkbox"] + .form_err_def:before {
  content: "";
  display: block;
  transform: rotate(-45deg);
  width: 10px;
  height: 10px;
  position: absolute !important;
  top: -6px;
  left: 7px;
  background: #fff;
  border-top: 1px solid #2692E0;
  border-right: 1px solid #2692E0;
  z-index: -1;
}

.form_style_1 label {
  z-index: 1;
}

.form_style_1 .textarea_box {
  width: 100%;
  height: auto;
  position: relative;
}

.form_style_1 textarea {
  padding-top: 5px;
  padding-bottom: 14px;
  font: 400 15px/140% 'Exo2', Helvetica, Arial, sans-serif;
  min-width: 100%;
  max-width: 100%;
  max-height: 100%;
  min-height: 100px;
  resize: none;
  border-radius: 4px;
}

.form_style_1 .inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.form_style_1 .inputfile + label {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: center;
  text-decoration: none;
  width: 100%;
  height: 54px;
  padding: 0;
  color: #AFB8BF;
  border-radius: 8px;
  background: #F4F5F5;
  border: 1px solid #F4F5F5 !important;
  padding: 0 64px 0 20px;
  cursor: pointer !important;
  outline: none !important;
}

.form_style_1 .inputfile + label > span {
  overflow: hidden;
  z-index: 2;
}

.form_style_1 .inputfile + label > span::after {
  content: 'load file';
}

.form_style_1 .inputfile + label > strong {
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  z-index: 3;
  width: 54px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.form_style_1 .inputfile + label > strong::before {
  content: "\58";
  font-size: 18px;
  color: #F4F5F5;
  z-index: 5;
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.form_style_1 .inputfile + label:hover > strong::before {
  color: #3aa6f4;
}

.form_style_1 .no-js .inputfile + label {
  display: none;
}

.form_style_1 .inputfile:focus + label,
.form_style_1 .inputfile.has_focus + label {
  outline: none;
}

.form_style_1 .inputfile:focus + label,
.form_style_1 .inputfile.has-focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.form_style_1 .input_avatar {
  width: 108px !important;
  height: 108px !important;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  box-shadow: 0 6px 25px -3px rgba(0, 0, 0, 0.09);
  cursor: pointer;
}

.form_style_1 .input_avatar + label {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  width: 108px;
  height: 108px;
  outline: none !important;
  border-radius: 100%;
  background: url("/img/avatar_bg.png") 50% 50% no-repeat;
  background-size: cover;
}

.form_style_1 .input_avatar + label > span {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
  height: 100% !important;
  border-radius: 100%;
  z-index: 2;
  overflow: hidden;
  background: url("/img/avatar_bg.png");
}

.form_style_1 .input_avatar + label > span img {
  -o-object-fit: cover !important;
  object-fit: cover !important;
  width: 100%;
  height: 100%;
  font-family: 'object-fit: cover;';
}

.form_style_1 .input_avatar + label > strong {
  position: absolute;
  bottom: -6px;
  left: 80px;
  z-index: 3;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  background: #2692E0;
  border-radius: 8px;
  box-shadow: 0 6px 25px -3px rgba(0, 0, 0, 0.09);
}

.form_style_1 .input_avatar + label > strong::before {
  content: "\58";
  color: #fff;
  z-index: 5;
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

.form_style_1 .input_avatar + label:hover > strong::before {
  color: #fff;
}

.form_style_1 .no-js .input_avatar + label {
  display: none;
}

.form_style_1 .input_avatar:focus + label,
.form_style_1 .input_avatar.has_focus + label {
  outline: none;
}

.form_style_1 .input_avatar:focus + label,
.form_style_1 .input_avatar.has-focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.user_profile_avatar,
.user_profile_avatar *,
.user_profile_avatar input:focus {
  outline: 0 !important;
  outline-color: transparent !important;
  outline-width: 0 !important;
  outline-style: none !important;
  box-shadow: 0 0 0 0 rgba(0, 123, 255, 0) !important;
}

.form_style_1 .search_box {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  position: relative;
  height: 56px;
}

.form_style_1 .search_box > input[type="search"],
.form_style_1 .search_box > input[type="text"] {
  flex: 1 1 100%;
  position: relative;
  padding-right: 54px !important;
}

.form_style_1 .search_box > button,
.form_style_1 .search_box > input[type='button'],
.form_style_1 .search_box > input[type='submit'] {
  width: 54px !important;
  height: 56px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  z-index: 2;
  background: none !important;
  padding: 0 !important;
  border: none !important;
}

.form_style_1 .search_box > button::before,
.form_style_1 .search_box > input[type='button']::before,
.form_style_1 .search_box > input[type='submit']::before {
  content: '\e041';
  font-size: 18px;
  color: #2692E0;
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.form_style_1 .search_box > button:hover::before,
.form_style_1 .search_box > input[type='button']:hover::before,
.form_style_1 .search_box > input[type='submit']:hover::before {
  color: #3aa6f4;
}

.form_style_1 input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-right: 0;
  cursor: pointer;
  background: url('/img/icn_close.svg') 50% 50% no-repeat;
  background-size: 10px auto !important;
  opacity: 0.6;
}

.form_style_1 input[type="search"]::-webkit-search-cancel-button:hover {
  opacity: 1;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.quantity {
  position: relative;
  height: 54px;
}

.quantity input {
  width: 45px;
  height: 42px;
  line-height: 1.65;
  float: left;
  display: block;
  padding: 0;
  margin: 0;
  padding-left: 20px;
  border: 1px solid #eee;
}

.quantity input:focus {
  outline: 0;
}

.quantity .quantity-nav {
  position: absolute;
  right: 2px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-content: flex-start;
  align-items: stretch;
  height: 50px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
}

.quantity .quantity-nav .quantity-button {
  position: relative;
  cursor: pointer;
  background: #AFB8BF;
  width: 50px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  color: #fff;
  margin-left: 5px;
  border-radius: 8px;
  transition: all 0.2s ease-out;
  transform: scale(0.8);
  cursor: default;
  user-select: none !important;
}

.quantity .quantity-nav .quantity-button:active {
  transform: scale(0.7);
}

.quantity .quantity-nav .quantity-button.quantity-up::before {
  content: '\e035';
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.quantity .quantity-nav .quantity-button.quantity-down::before {
  content: '\e024';
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body:not(.mobile) .quantity-nav .quantity-button:hover {
  cursor: pointer;
  background-color: #2692E0;
  color: #fff !important;
}

.form_style_1 .type_color_box {
  position: relative;
  overflow: hidden;
  text-align: center;
}

.form_style_1 .type_color_box:before {
  content: 'Choice color:';
  height: 100%;
  text-align: left;
  width: auto;
  position: absolute;
  opacity: 0.9;
  border-right: 1px solid #616B74;
  top: 0;
  left: 0;
  padding: 10px;
  background-color: #fff;
  display: block;
  z-index: 1;
}

.form_style_1 input[type=color] {
  left: 0;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  display: block;
  position: absolute;
  border: none;
  padding: 0;
  overflow: hidden;
  -webkit-transform: scale(1.5);
  -moz-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -o-transform: scale(1.5);
  transform: scale(1.5);
  cursor: pointer;
}

.form_style_1 .form_err input:not([type="radio"]):not([type="checkbox"]):not([type="button"]):not([type="submit"]):not([type="reset"]):not([type="color"]),
.form_style_1 .form_err textarea,
.form_style_1 .form_err select,
.form_style_1 .form_err .file_upload > div,
.form_style_1 .form_err .type_color_box,
.form_style_1 .form_err .inputfile_6 + label {
  border-color: #E33D2B;
  background: rgba(231, 76, 60, 0.05) !important;
}

.custom_select_container > div.err_info {
  position: relative;
  left: 0;
  z-index: 8;
  display: block;
  float: none;
  clear: both;
  width: 100% !important;
}

.vgap > * {
  margin-bottom: 48px;
  position: relative;
  width: 100%;
}

.form_footer1 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: flex-start;
  align-items: center;
}

.form_footer1 > * {
  flex-basis: auto;
  margin-bottom: 10px;
}

.form_footer1 > *:first-child {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.form_footer1 > *:first-child > * {
  flex-basis: auto;
  margin-right: 18px;
  margin-bottom: 10px;
  white-space: nowrap;
}

.form_footer2 {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: flex-start;
  align-items: center;
  margin-left: -16px;
  margin-right: -16px;
  margin-bottom: 20px;
}

.form_footer2 > * {
  flex-basis: 50%;
  padding-left: 16px;
  padding-right: 16px;
}

.form_footer2 > * > * {
  width: 100%;
}

.eye {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 54px;
  height: 54px;
  position: absolute;
  z-index: 4;
  right: 0;
  top: 0;
  transition: all 0.2s ease-out;
}

.eye::after {
  content: '\e037';
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #616B74;
}

.eye:hover {
  cursor: pointer;
}

.eye:active {
  transform: translateY(1px) scale(0.95);
}

.eye.visible::after {
  color: #2692E0;
}

.login-footer {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: space-between;
}

.login-footer > * {
  display: flex;
  font-size: 15px !important;
}

.login-footer > * > *:not(:first-child) {
  margin-left: 16px;
}

.drop_links_box {
  margin: 0 5px !important;
  cursor: default;
  user-select: none !important;
  font: 400 17px/140% 'Exo2', Helvetica, Arial, sans-serif;
  z-index: 10;
  min-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: stretch;
  align-self: flex-start !important;
}

.drop_links_box,
.drop_links_box * {
  transition: 0.3s;
}

.drop_links_box > div:nth-child(1) {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: relative;
  padding-right: 15px;
  height: 26px;
}

.drop_links_box > div:nth-child(1) > span {
  border-bottom: 1px dashed #616B74;
}

.drop_links_box > div:nth-child(1)::after {
  content: '\e04d';
  position: absolute;
  top: 40%;
  right: 2px;
  transform: translateY(-50%) scaleY(1);
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  color: #DBDFE2;
}

.drop_links_box > div:nth-child(1):hover {
  cursor: pointer !important;
}

.drop_links_box > div:nth-child(2) {
  opacity: 0;
  visibility: hidden;
  transform: scaleY(0) translateY(0);
  position: absolute;
  top: 26px;
  transform-origin: 50% 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: center;
  align-items: stretch;
  background: #fff;
  box-shadow: 0 6px 25px -3px rgba(0, 0, 0, 0.09);
  border-radius: 8px;
  padding: 10px;
}

.drop_links_box > div:nth-child(2)::after {
  content: '';
  display: block;
  position: absolute;
  z-index: 0;
  background: #fff;
  transform: rotate(-45deg) translateX(-50%);
  top: -5px;
  left: 50%;
  width: 10px;
  height: 10px;
}

.drop_links_box > div:nth-child(2) > * {
  padding: 5px;
  text-decoration: none;
  color: #37414A;
}

.drop_links_box > div:nth-child(2) > *:not(:last-child) {
  border-bottom: 1px solid #F4F5F5;
}

.drop_links_box > div:nth-child(2) > *:hover {
  cursor: pointer;
}

.drop_links_box.active > div:nth-child(1)::after {
  top: 52%;
  transform: translateY(-50%) scaleY(-1);
  color: #2692E0;
}

.drop_links_box.active > div:nth-child(2) {
  opacity: 1;
  visibility: visible;
  transform: scaleY(1) translateY(0);
}

.field-wrap {
  width: 100%;
  position: relative;
}

label.label_bottom {
  position: absolute;
  display: flex;
  align-content: center;
  align-items: center;
  z-index: 4;
  top: 0;
  left: 0;
  height: 54px;
  color: #AFB8BF;
  padding-left: 19.2px !important;
  opacity: 1;
  font: 400 17px/140% 'Exo2', Helvetica, Arial, sans-serif;
  transition: 0.1s;
  pointer-events: none;
}

input:focus:placeholder-shown ~ label.label_bottom,
textarea:focus:placeholder-shown ~ label.label_bottom,
select:focus:placeholder-shown ~ label.label_bottom,
input:not(:placeholder-shown) ~ label.label_bottom,
textarea:not(:placeholder-shown) ~ label.label_bottom,
select:not(:placeholder-shown) ~ label.label_bottom {
  height: 48px;
  top: -34.92307692px;
  left: -10px;
  font: 400 13px/140% 'Exo2', Helvetica, Arial, sans-serif;
  color: #37414A;
}

span.error {
  color: #E33D2B;
  font: 400 13px/110% 'Exo2', Helvetica, Arial, sans-serif;
  position: absolute;
  bottom: -20px;
  width: 100%;
  padding: 0 13px 5px;
  border-top: none;
  z-index: 7;
  background: none;
}

input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #F4F5F5;
}

input[type=range]:focus::-ms-fill-lower {
  background: #2692E0;
}

input[type=range]:focus::-ms-fill-upper {
  background: #3aa6f4;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  animate: 0.2s;
  background: #F4F5F5;
  border-radius: 8px;
  box-shadow: none;
  border: 0;
}

input[type=range]::-webkit-slider-thumb {
  transition: all 0.2s ease-out;
  z-index: 2;
  position: relative;
  -webkit-box-shadow: 0 3px 10px -2px rgba(40, 80, 120, 0.2);
  -moz-box-shadow: 0 3px 10px -2px rgba(40, 80, 120, 0.2);
  box-shadow: 0 3px 10px -2px rgba(40, 80, 120, 0.2);
  border: none;
  height: 20px;
  width: 20px;
  border-radius: 2px;
  background: #2692E0;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px;
}

input[type=range]::-webkit-slider-thumb:hover,
input[type=range]::-webkit-slider-thumb:active {
  background: #3aa6f4;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  animate: 0.2s;
  background: #F4F5F5;
  border-radius: 8px;
  box-shadow: none;
  border: 0;
}

input[type=range]::-moz-range-thumb {
  transition: all 0.2s ease-out;
  z-index: 2;
  position: relative;
  -webkit-box-shadow: 0 3px 10px -2px rgba(40, 80, 120, 0.2);
  -moz-box-shadow: 0 3px 10px -2px rgba(40, 80, 120, 0.2);
  box-shadow: 0 3px 10px -2px rgba(40, 80, 120, 0.2);
  border: none;
  height: 20px;
  width: 20px;
  border-radius: 2px;
  background: #2692E0;
  cursor: pointer;
}

input[type=range]::-moz-range-thumb:hover,
input[type=range]::-moz-range-thumb:active {
  background: #3aa6f4;
}

input[type=range]::-ms-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type=range]::-ms-fill-lower,
input[type=range]::-ms-fill-upper {
  background: #F4F5F5;
  border-radius: 8px;
  box-shadow: none;
  border: 0;
}

input[type=range]::-ms-thumb {
  transition: all 0.2s ease-out;
  z-index: 2;
  position: relative;
  -webkit-box-shadow: 0 3px 10px -2px rgba(40, 80, 120, 0.2);
  -moz-box-shadow: 0 3px 10px -2px rgba(40, 80, 120, 0.2);
  box-shadow: 0 3px 10px -2px rgba(40, 80, 120, 0.2);
  border: none;
  height: 20px;
  width: 20px;
  border-radius: 2px;
  background: #2692E0;
  cursor: pointer;
}

input[type=range]::-ms-thumb:hover,
input[type=range]::-ms-thumb:active {
  background: #3aa6f4;
}

[disabled] {
  opacity: 0.5;
}

.hide {
  display: none !important;
}

body::after {
  content: '';
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 90;
  background: rgba(35, 45, 54, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  transition: 0.2s;
  opacity: 0;
  visibility: hidden;
}

body.popup__opened::after {
  opacity: 1;
  visibility: visible;
}

body.popup__opened .main-container {
  filter: blur(1px);
}

.header {
  transition: none;
}

.popup {
  position: fixed;
  transition: 0.2s;
  z-index: 999;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  overflow-y: auto;
  overflow-x: hidden;
  opacity: 0;
  visibility: hidden;
}

.popup__container {
  width: 100%;
  min-height: 100%;
  padding: 30px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup__container .popup__window {
  position: relative;
  width: 100%;
  max-width: 680px;
  padding: 32px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 13px 60px -5px rgba(25, 35, 44, 0.4);
  transition: all 0.2s ease-out;
  transform: perspective(600) translateY(40%) rotateX(10deg);
}

.popup__container .popup__window .popup__title {
  margin-bottom: 15px;
}

.popup__container .popup__window .popup__sub-title {
  margin-bottom: 15px;
}

.popup__container .popup__close {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  transition: 0.2s;
  width: 32px;
  height: 32px;
  position: absolute;
  top: -8px;
  right: -8px;
  z-index: 15;
  background: #2692E0;
  border-radius: 8px;
  box-shadow: 0 3px 20px -1px rgba(8, 116, 194, 0.4);
}

.popup__container .popup__close:active {
  transform: translateY(1px);
}

.popup__container .popup__close::before {
  content: '9';
  font-size: 17px;
  color: #fff;
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.popup.popup__open {
  opacity: 1;
  visibility: visible;
}

.popup.popup__open .popup__window {
  transform: perspective(600) translateY(0) rotateX(0);
}

body:not(.mobile) .popup__close:hover {
  cursor: pointer;
  background: #3aa6f4;
}

.popup__big-icon img {
  width: 270px;
}

.popup__title {
  font: 600 30px/120% 'Exo2', Helvetica, Arial, sans-serif;
  margin-bottom: 15px;
}

.popup__big-icon {
  font-size: 60px;
  color: #2692E0;
  margin-bottom: 16px;
}

.section__error {
  display: flex;
  height: 33.33vh;
  min-height: 400px;
  max-height: 640px;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  padding: 32px 16px !important;
  background: url("/img/video-bg.jpg") 50% 50% no-repeat;
  background-size: cover !important;
}

.section__error > * {
  position: relative;
  z-index: 3;
}

.section__error .section__error__number {
  font-size: 120px;
  color: #2692E0;
  font-weight: 800 !important;
  line-height: 120%;
  height: 150px;
  display: flex;
  text-align: center;
}

.section__error .error__details {
  display: inline-block;
  text-align: center;
  color: #37414A;
}

.header.desktop-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  z-index: 90;
  width: 100%;
  max-width: 2200px;
  height: auto;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  box-shadow: 0 0 13px -1px rgba(55, 65, 74, 0.13);
  /*
  .languages{
    display: flex;

    a{
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      width: 40px;
      height: 40px;
       font: @f_sbold 14px/120% @font;
      border-radius: 100%;
      text-align: center;
      color: @color_b3 - 60%;
      margin-right: @gap/2;

      &:not(.active):hover{
        background: @color_1;
        color: @color_w !important;

      }

      &.active{
        pointer-events: none;
        border: 1px solid @color_2;
        //background: @color_1;
        //background: @color_2;
        color: @color_w;
        position: relative;
        box-shadow: inset 0 0 0 1px rgba(@color_1, 1);

        &::before{
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          pointer-events: none;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          border-radius: @br;
          box-shadow: inset 1px 1px 0 0 rgba(@color_3, 1)

        }
      }
    }
  }
  */
}

.header.desktop-header .menu__desktop-hide {
  display: none;
}

.header.desktop-header .logo {
  display: flex;
  width: 210px;
  height: 53px;
  transition: all 0.3s linear;
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
}

.header.desktop-header .logo a {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
}

.header.desktop-header .logo a img {
  height: 53px;
}

.header.desktop-header .languages {
  display: flex;
  position: relative;
}

.header.desktop-header .languages .lang_selected {
  display: flex;
  padding-right: 10px;
  width: 62px;
  height: 32px;
  color: #fff;
  align-content: center;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  transition: all 0.07s linear;
  cursor: pointer;
}

.header.desktop-header .languages .lang_selected::after {
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
  content: 'T';
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #AFB8BF !important;
}

.header.desktop-header .languages .lang_drop {
  display: flex;
  width: 100%;
  padding: 0 0 4px 0;
  flex-direction: column;
  background: #37414A;
  position: absolute;
  transition: all 0.1s linear;
  top: 0;
  opacity: 0;
  visibility: hidden;
}

.header.desktop-header .languages .lang_drop a {
  width: 62px;
  padding-right: 10px;
  height: 32px;
  align-content: center;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  display: flex;
  color: #fff;
  text-decoration: none;
}

.header.desktop-header .languages .lang_drop a.active {
  display: none;
}

.header.desktop-header .languages.active .lang_selected {
  background: #37414A;
}

.header.desktop-header .languages.active .lang_selected::after {
  transform: translateY(-50%) rotate(-180deg);
}

.header.desktop-header .languages.active .lang_drop {
  top: 32px;
  opacity: 1;
  visibility: visible;
}

.header.desktop-header .menu {
  display: none !important;
  margin-left: auto;
  margin-right: 0;
}

.header.desktop-header .menu__wrap {
  display: flex;
}

.header.desktop-header .menu__item {
  position: relative;
}

.header.desktop-header .menu__item a {
  display: -webkit-box;
  min-height: 46px;
  padding: 0 16px;
  font: 600 18px/120% 'Exo2', Helvetica, Arial, sans-serif;
  text-decoration: none;
  color: #37414A;
  align-content: center;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.header.desktop-header .menu__item a.active::after {
  content: '';
  display: block;
  position: absolute;
  bottom: -17px;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 4px;
  background: #2692E0;
}

.header.desktop-header .resolution-wrap {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  position: relative;
  height: 60px;
  transition: all 0.2s linear;
}

body:not(.mobile) .header.desktop-header .menu__item a:hover {
  color: #3aa6f4;
}

body:not(.mobile) .header.desktop-header .menu__item a:hover::after {
  content: '';
}

.header.desktop-header.header__small {
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(14px);
}

.header.desktop-header.header__small .menu__item a {
  font: 400 14px/120% 'Exo2', Helvetica, Arial, sans-serif;
  padding: 0 8px;
}

.header.desktop-header.header__small .menu__item a.active::after {
  bottom: -7px;
}

.header.desktop-header.header__small .languages .lang_selected {
  font-size: 13px;
}

.header.desktop-header.header__small .languages .lang_drop a {
  font-size: 13px;
}

.header.desktop-header.header__small .languages.active .lang_selected {
  background: #37414A;
}

.header.desktop-header.header__small .languages.active .lang_selected::after {
  transform: translateY(-50%) rotate(-180deg);
}

.header.desktop-header.header__small .languages.active .lang_drop {
  top: 32px;
  opacity: 1;
  visibility: visible;
}

.header.desktop-header.header__small .logo {
  display: flex;
  width: 160px;
  height: 45px;
}

.header.desktop-header.header__small .logo a img {
  height: 45px;
}

.header.desktop-header.header__small .resolution-wrap {
  height: 60px;
}

.header.mobile-header {
  display: flex;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
}

.header.mobile-header .menu__desktop-hide {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header.mobile-header .menu__desktop-hide [class*='style-btn'] {
  margin-bottom: 16px;
}

.header.mobile-header .menu__desktop-hide .menu__contacts > * {
  margin-bottom: 16px;
}

.header.mobile-header .menu__desktop-hide .menu__contacts .contacts__tel,
.header.mobile-header .menu__desktop-hide .menu__contacts .contacts__mail {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.header.mobile-header .menu__desktop-hide .menu__contacts .contacts__tel::before,
.header.mobile-header .menu__desktop-hide .menu__contacts .contacts__mail::before {
  width: 36px;
  height: 36px;
  background-size: 100% auto !important;
}

.header.mobile-header .menu__desktop-hide .menu__contacts .contacts__tel a,
.header.mobile-header .menu__desktop-hide .menu__contacts .contacts__mail a {
  font-size: 16px;
  font-weight: 400;
}

.header.mobile-header .menu__desktop-hide .menu__contacts .contacts__tel a:hover,
.header.mobile-header .menu__desktop-hide .menu__contacts .contacts__mail a:hover {
  text-decoration: none !important;
}

.header.mobile-header .menu__desktop-hide .menu__contacts .contacts__social-media a {
  width: 36px;
  height: 36px;
}

.header.mobile-header .resolution-wrap {
  display: flex;
  flex-direction: row;
  margin: auto;
  height: 60px;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
}

.header.mobile-header .logo {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  width: 174px;
  height: 40px;
}

.header.mobile-header .logo img {
  height: 40px;
}

.header.mobile-header .menu {
  display: flex;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(55, 65, 74, 0.98);
  backdrop-filter: blur(10px);
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  width: 100%;
  max-width: 460px;
  height: 100vh;
  box-shadow: 0 0 5000px 5000px rgba(0, 0, 0, 0.6);
  overflow: hidden;
  overflow-y: auto;
  transform: translateX(-100%);
  opacity: 0;
  visibility: hidden;
}

.header.mobile-header .menu__item {
  display: flex;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
}

.header.mobile-header .menu__item a {
  display: -webkit-box;
  width: 100%;
  height: 60px;
  text-decoration: none;
  align-content: center;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 18px;
  transition: all 0.3s linear;
  position: relative;
  border-bottom: 2px solid #37414A;
  box-shadow: 0 1px 0 0 rgba(97, 107, 116, 0.03);
}

.header.mobile-header .menu__item a.active {
  pointer-events: none;
  background: #3aa6f4;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.header.mobile-header .menu__wrap {
  padding-top: 48px;
  margin-bottom: 32px;
  width: 100%;
}

.header.mobile-header .languages {
  margin-right: 16px;
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

.header.mobile-header .languages .lang_selected {
  display: flex;
  padding-right: 10px;
  width: 62px;
  height: 32px;
  color: #fff;
  align-content: center;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  transition: all 0.07s linear;
  cursor: pointer;
  border-radius: 8px 8px 0 0;
}

.header.mobile-header .languages .lang_selected::after {
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
  content: 'T';
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #AFB8BF !important;
}

.header.mobile-header .languages .lang_drop {
  display: flex;
  width: 100%;
  padding: 0 0 4px 0;
  flex-direction: column;
  background: #37414A;
  position: absolute;
  transition: all 0.1s linear;
  top: 0;
  opacity: 0;
  visibility: hidden;
  border-radius: 0 0 8px 8px;
}

.header.mobile-header .languages .lang_drop a {
  width: 62px;
  padding-right: 10px;
  height: 32px;
  align-content: center;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  display: flex;
  color: #fff;
  text-decoration: none;
}

.header.mobile-header .languages .lang_drop a.active {
  display: none;
}

.header.mobile-header .languages.active .lang_selected {
  background: #37414A;
}

.header.mobile-header .languages.active .lang_selected::after {
  transform: translateY(-50%) rotate(-180deg);
}

.header.mobile-header .languages.active .lang_drop {
  top: 32px;
  opacity: 1;
  visibility: visible;
}

.header.mobile-header .menu-burger {
  display: none !important;
  position: absolute;
  width: 36px;
  height: 36px;
  z-index: 5;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
}

.header.mobile-header .menu-burger:hover {
  cursor: pointer;
}

.header.mobile-header .menu-burger:hover > * {
  background: #fff;
}

.header.mobile-header .menu-burger::before,
.header.mobile-header .menu-burger i,
.header.mobile-header .menu-burger::after {
  display: block;
  width: 75%;
  height: 2px;
  background: #AFB8BF !important;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  transform-origin: 0% 50%;
  transition: all 0.2s linear;
}

.header.mobile-header .menu-burger::before {
  content: '';
  top: 25%;
}

.header.mobile-header .menu-burger i {
  top: 50%;
}

.header.mobile-header .menu-burger::after {
  content: '';
  top: 75%;
}

.header.mobile-header.menu-active .menu-burger::before,
.header.mobile-header.menu-active .menu-burger::after {
  top: 50%;
  width: 50%;
}

.header.mobile-header.menu-active .menu-burger::before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.header.mobile-header.menu-active .menu-burger i {
  width: 30%;
  transform: translate(120%, -50%);
  opacity: 0;
  visibility: hidden;
}

.header.mobile-header.menu-active .menu-burger::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.header.mobile-header.menu-active .menu {
  transition: all 0.3s linear;
  transform: translateX(0);
  opacity: 1;
  visibility: visible;
}

.main_page .logo a {
  pointer-events: none !important;
}

.footer {
  width: 100%;
  position: relative;
  z-index: 3;
  background: #37414A;
}

.footer .logo-copy {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}

.footer__logo {
  margin-right: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}

.footer__logo a {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  height: 34px;
}

.footer__logo a img {
  height: 30px;
}

.footer__copyright {
  color: #757f88;
  font-size: 12px;
  position: absolute;
  top: 13px;
  left: 50%;
  transform: translateX(-50%);
}

.footer__nav {
  display: inline-flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
}

.footer__nav a {
  text-decoration: none;
  color: #757f88;
  font: 400 11px/110% 'Exo2', Helvetica, Arial, sans-serif;
  margin-left: 16px;
  padding: 10px 0;
}

.footer__nav a:hover {
  text-decoration: underline;
  color: #AFB8BF;
}

.footer .resolution-wrap {
  align-content: center;
  align-items: center;
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer .burger-btn {
  position: absolute;
  right: 16px;
  top: 10px;
  color: #AFB8BF;
  display: none;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-transform: uppercase;
  font-size: 11px;
}

.footer .burger-btn::before {
  content: "\e020";
  color: #AFB8BF;
  margin-right: 6px;
  font-size: 13px;
  font-family: 'dripicons-v2';
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
}

.map-wrap .footer {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 14;
}

body:not(.mobile) .burger-btn:hover {
  color: #fff;
  cursor: pointer;
}

body:not(.mobile) .burger-btn:hover::before {
  color: #fff;
}

.main-container {
  position: relative;
}

.map-wrap-scroll {
  display: flex;
  height: calc(var(--app-height) - 240px);
  overflow: hidden !important;
  position: relative;
  background: #AFB8BF;
}

.map-wrap-scroll .map-container-scroll {
  width: 100%;
}

.map-wrap {
  display: flex;
  max-height: 100vh;
  max-height: var(--app-height) !important;
  min-height: var(--app-height);
  overflow: hidden !important;
  position: relative;
}

.map-wrap .map-container {
  flex: 1 1 auto;
  border: none;
  position: relative;
  width: 100%;
  height: 100vh;
  max-height: var(--app-height) !important;
  min-height: var(--app-height);
  z-index: 1;
}

.map-wrap .sidebar-container {
  position: relative;
  width: 100%;
  max-width: 420px;
  height: auto;
  min-height: 100vh;
  box-shadow: 0 6px 25px -3px rgba(0, 0, 0, 0.09);
  z-index: 2;
  background: #fff;
}

.map-wrap .sidebar {
  padding-bottom: 66px;
}

.sidebar-container {
  display: flex;
  flex-direction: column;
}

.sidebar-container__header {
  padding: 16px;
  margin-bottom: -16px;
}

.sidebar-container__header .sidebar__headline {
  font: 400 22px/120% 'Exo2', Helvetica, Arial, sans-serif;
  margin-bottom: 16px;
}

.sidebar-container__header .sidebar__tabs {
  display: flex;
}

.sidebar-container__header .sidebar__tabs > *:not(:last-child) {
  margin-right: 8px;
}

.sidebar-container__header .sidebar__tabs > * {
  display: inline-flex;
  padding: 6px 13px 8px;
  border: 1px solid #DBDFE2;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s linear;
  align-content: center;
  align-items: center;
  justify-content: center;
  user-select: none !important;
  margin-bottom: 16px;
  font-size: 13px;
}

.sidebar-container__header .sidebar__tabs > *.active {
  border-color: #3aa6f4;
  box-shadow: 0 0 0 2px #3aa6f4;
}

.sidebar-container__header .sidebar__tabs .back-btn::before {
  content: '\e903';
  font-size: 11px;
  position: relative;
  top: 2px;
  font-family: 'Icons';
  margin-right: 6px;
  transition: all 0.1s linear;
}

.sidebar-container__header .sidebar__tabs .filters-btn {
  margin-left: auto;
  position: relative;
}

.sidebar-container__header .sidebar__tabs .filters-btn > span {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 18px;
  min-width: 18px;
  background: #3aa6f4;
  position: absolute;
  top: -9px;
  right: -9px;
  color: #fff;
  font-size: 9px;
  padding: 1px 4px;
  border-radius: 8px;
}

.sidebar-container__header .sidebar__tabs .filters-btn::before {
  content: "\40";
  font-family: 'dripicons-v2';
}

.sidebar {
  width: 100%;
  height: 100%;
  padding: 16px;
  overflow: hidden;
  overflow-y: auto;
}

.sidebar__box {
  border-radius: 16px;
  background: #fff;
  box-shadow: 0 6px 25px -3px rgba(0, 0, 0, 0.09);
  margin-bottom: 5.33333333px;
  padding: 16px;
  transition: all 0.3s linear;
}

.sidebar__box__header {
  cursor: pointer;
  padding-right: 100px;
}

.sidebar__box__header .acc-btn {
  position: absolute;
  text-align: right;
  top: 28px;
  right: 16px;
  transform: translateY(-50%);
  width: 50px;
  height: 30px;
  color: #616B74;
  border: 1px solid #DBDFE2;
  font-family: 'Exo2', Helvetica, Arial, sans-serif;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  border-radius: 8px;
  background: #f9fafa;
  box-shadow: inset 0 1px 0 0 #fff;
}

.sidebar__box__list {
  padding-top: 0;
  transition: all 0.1s linear;
  transform-origin: 0 0;
  transform: scaleY(0);
  max-height: 0;
  opacity: 0;
}

.sidebar__box.active .sidebar__box__header .acc-btn::after {
  content: '\e900';
  font-family: 'Icons';
  font-size: 9px;
  color: #AFB8BF;
}

.sidebar__box.active .sidebar__box__list {
  padding-top: 10px;
  transform: scaleY(1);
  max-height: 8000px;
  opacity: 1;
}

.sidebar__box.focus {
  box-shadow: 0 7px 35px -2px rgba(0, 0, 0, 0.09), inset 0 0 0 2px #37414a !important;
}

.transit-box {
  position: relative;
}

.transit-box__header h3 {
  display: flex;
  font: 400 16px/110% 'Exo2', Helvetica, Arial, sans-serif;
  position: relative;
  padding-left: 16px;
  margin-bottom: 4px;
}

.transit-box__header h3::before {
  content: '\e902';
  position: absolute;
  top: 2px;
  left: -3px;
  font-family: 'Icons';
  color: #616B74;
}

.transit-box__header h4 {
  font: 400 11px/110% 'Exo2', Helvetica, Arial, sans-serif;
}

.transit-box__header p {
  font: 400 11px/110% 'Exo2', Helvetica, Arial, sans-serif;
}

.transit-box__header .schedule {
  position: absolute;
  top: 13px;
  right: 70px;
  width: 30px;
  height: 30px;
  text-decoration: none;
  border: 1px solid #DBDFE2;
  font-family: 'Exo2', Helvetica, Arial, sans-serif;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  border-radius: 8px;
  background: #f9fafa;
  box-shadow: inset 0 1px 0 0 #fff;
}

.transit-box__header .schedule::after {
  content: '\e906';
  font-size: 15px;
  color: #b9c2c9;
  font-family: 'Icons';
}

.transit-box__header .acc-btn::after {
  content: 'stops';
}

.transit-box__list__item {
  padding: 8px 8px 8px 13px;
  font: 400 13px/110% 'Exo2', Helvetica, Arial, sans-serif;
  position: relative;
  display: flex;
}

.transit-box__list__item::after {
  content: '';
  width: 2px;
  height: 100%;
  background: #AFB8BF;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  display: block;
}

.transit-box__list__item:last-of-type::after {
  height: 15px;
}

.transit-box__list__item::before {
  content: '';
  display: block;
  background: #fff;
  border: 2px solid #AFB8BF;
  position: absolute;
  z-index: 2;
  left: 1px;
  top: 15px;
  transform: translate(-50%, -50%);
  border-radius: 100%;
  width: 9px;
  height: 9px;
}

.transit-box__list__item.active::before {
  width: 11px;
  height: 11px;
  border-color: #37414A;
}

.transit-box__list__item p {
  padding-right: 6px;
  flex: 1 1 auto;
}

.transit-box__list__item .schedule {
  position: relative;
  top: -2px;
  width: 24px;
  height: 24px;
  text-decoration: none;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.transit-box__list__item .schedule::after {
  content: '\e906';
  font-size: 15px;
  color: #b9c2c9;
  font-family: 'Icons';
}

.transit-box.active .transit-box__header h3::before {
  color: #3aa6f4;
}

.transit-box.active .transit-box__list .transit-box__list__item::after {
  background: #3aa6f4;
}

.system-box {
  position: relative;
}

.system-box__header h3 {
  display: flex;
  font: 400 16px/110% 'Exo2', Helvetica, Arial, sans-serif;
  position: relative;
  padding-left: 16px;
  margin-bottom: 4px;
}

.system-box__header h3::before {
  content: '\e907';
  position: absolute;
  top: 1px;
  left: -3px;
  font-family: 'Icons';
  color: #616B74;
  font-size: 13px;
}

.system-box__header h4 {
  font: 400 11px/110% 'Exo2', Helvetica, Arial, sans-serif;
}

.system-box__header p {
  font: 400 11px/110% 'Exo2', Helvetica, Arial, sans-serif;
}

.system-box__header .info-button {
  position: absolute;
  top: 13px;
  right: 70px;
  width: 30px;
  height: 30px;
  text-decoration: none;
  border: 1px solid #DBDFE2;
  font-family: 'Exo2', Helvetica, Arial, sans-serif;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  border-radius: 8px;
  background: #f9fafa;
  box-shadow: inset 0 1px 0 0 #fff;
}

.system-box__header .info-button::after {
  content: '\e010';
  font-size: 15px;
  color: #b9c2c9;
  font-family: 'dripicons-v2';
}

.system-box__header .acc-btn::after {
  content: 'routs';
}

.system-box__list__item {
  padding: 8px 20px 8px 19px;
  font: 400 11px/110% 'Exo2', Helvetica, Arial, sans-serif;
  cursor: pointer;
  position: relative;
  border: 1px solid #F4F5F5;
  margin-bottom: 3px;
  border-radius: 8px;
  transition: all 0.2s linear;
  box-shadow: 0 2px 6px 0 rgba(97, 107, 116, 0.08);
  background: #fff;
}

.system-box__list__item::before {
  content: '\e902';
  display: block;
  font-family: 'Icons';
  color: #AFB8BF;
  font-size: 13px;
  position: absolute;
  z-index: 2;
  left: 9px;
  top: 13px;
  transform: translate(-50%, -50%);
  border-radius: 100%;
  width: 9px;
  height: 9px;
}

.system-box__list__item::after {
  content: '\e901';
  display: block;
  font-family: 'Icons';
  color: #AFB8BF;
  font-size: 10px;
  position: absolute;
  z-index: 2;
  right: 6px;
  top: 13px;
  transform: translate(-50%, -50%);
  border-radius: 100%;
  width: 9px;
  height: 9px;
}

.system-box.active .system-box__header h3::before {
  color: #3aa6f4;
}

.transit-stop-box {
  position: relative;
}

.transit-stop-box__header {
  margin-bottom: 13px !important;
  padding-right: 90px;
}

.transit-stop-box__header h3 {
  display: flex;
  font: 400 16px/110% 'Exo2', Helvetica, Arial, sans-serif;
  position: relative;
  padding-left: 16px;
  margin-bottom: 4px;
}

.transit-stop-box__header h3::before {
  content: '\e905';
  position: absolute;
  font-size: 13px;
  top: 2px;
  left: -3px;
  font-family: 'Icons';
  color: #616B74;
}

.transit-stop-box__header h4 {
  font: 400 11px/110% 'Exo2', Helvetica, Arial, sans-serif;
}

.transit-stop-box__header p {
  font: 400 11px/110% 'Exo2', Helvetica, Arial, sans-serif;
}

.transit-stop-box__header .schedule {
  position: absolute;
  top: 13px;
  right: 70px;
  width: 30px;
  height: 30px;
  text-decoration: none;
  border: 1px solid #DBDFE2;
  font-family: 'Exo2', Helvetica, Arial, sans-serif;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  border-radius: 8px;
  background: #f9fafa;
  box-shadow: inset 0 1px 0 0 #fff;
}

.transit-stop-box__header .schedule::after {
  content: '\e906';
  font-size: 15px;
  color: #b9c2c9;
  font-family: 'Icons';
}

.transit-stop-box__header .acc-btn::after {
  content: 'routes';
}

.transit-stop-box__list__item {
  padding: 8px 20px 8px 19px;
  font: 400 11px/110% 'Exo2', Helvetica, Arial, sans-serif;
  cursor: pointer;
  position: relative;
  border: 1px solid #F4F5F5;
  margin-bottom: 3px;
  border-radius: 8px;
  transition: all 0.2s linear;
  box-shadow: 0 2px 6px 0 rgba(97, 107, 116, 0.08);
  background: #fff;
}

.transit-stop-box__list__item::before {
  content: '\e902';
  display: block;
  font-family: 'Icons';
  color: #AFB8BF;
  font-size: 13px;
  position: absolute;
  z-index: 2;
  left: 9px;
  top: 13px;
  transform: translate(-50%, -50%);
  border-radius: 100%;
  width: 9px;
  height: 9px;
}

.transit-stop-box__list__item::after {
  content: '\e901';
  display: block;
  font-family: 'Icons';
  color: #AFB8BF;
  font-size: 10px;
  position: absolute;
  z-index: 2;
  right: 6px;
  top: 13px;
  transform: translate(-50%, -50%);
  border-radius: 100%;
  width: 9px;
  height: 9px;
}

.back-btn {
  display: inline-flex;
  padding: 6px 10px 8px;
  border: 1px solid #DBDFE2;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s linear;
  align-content: center;
  align-items: center;
  justify-content: center;
  user-select: none !important;
}

.operators-list {
  border-radius: 16px;
  background: #fff;
  z-index: 2;
  box-shadow: 0 6px 25px -3px rgba(0, 0, 0, 0.09);
  margin-bottom: 5.33333333px;
  padding: 16px;
  position: relative;
  transition: all 0.3s linear;
}

.operators-list__header {
  margin-bottom: 16px;
}

.operators-list__header h3 {
  display: flex;
  font: 400 16px/110% 'Exo2', Helvetica, Arial, sans-serif;
  position: relative;
  padding-left: 16px;
  margin-bottom: 4px;
}

.operators-list__header h3::before {
  content: '\e907';
  position: absolute;
  top: 1px;
  left: -3px;
  font-family: 'Icons';
  color: #616B74;
  font-size: 13px;
}

.operators-list__list__item {
  padding: 8px 20px 10px 12px;
  font: 400 15px/110% 'Exo2', Helvetica, Arial, sans-serif;
  cursor: pointer;
  position: relative;
  border: 1px solid #F4F5F5;
  margin-bottom: 3px;
  border-radius: 8px;
  transition: all 0.2s linear;
  box-shadow: 0 2px 6px 0 rgba(97, 107, 116, 0.08);
  background: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  text-decoration: none;
  color: #37414A;
}

.operators-list__list__item a {
  font: 400 15px/108% 'Exo2', Helvetica, Arial, sans-serif;
  text-decoration: none;
  color: #37414A;
}

.operators-list__list__item::before {
  content: '\e905';
  display: none;
  font-family: 'Icons';
  color: #AFB8BF;
  font-size: 8px;
  position: absolute;
  z-index: 2;
  left: 10px;
  top: 13px;
  transform: translate(-50%, -50%);
  border-radius: 100%;
  width: 9px;
  height: 9px;
}

.operators-list__list__item::after {
  content: '\e901';
  display: block;
  font-family: 'Icons';
  color: #AFB8BF;
  font-size: 10px;
  position: absolute;
  z-index: 2;
  right: 6px;
  top: 13px;
  transform: translate(-50%, -50%);
  border-radius: 100%;
  width: 9px;
  height: 9px;
}

body:not(.mobile) .transit-stop-box__header .schedule:hover::after {
  color: #3aa6f4;
}

body:not(.mobile) .transit-stop-box__list__item {
  padding: 8px 20px 8px 19px;
  font: 400 11px/110% 'Exo2', Helvetica, Arial, sans-serif;
  cursor: pointer;
  position: relative;
  border: 1px solid #F4F5F5;
  margin-bottom: 3px;
  border-radius: 8px;
  transition: all 0.2s linear;
  box-shadow: 0 2px 6px 0 rgba(97, 107, 116, 0.08);
  background: #fff;
}

body:not(.mobile) .transit-stop-box__list__item::before {
  content: '\e902';
  display: block;
  font-family: 'Icons';
  color: #AFB8BF;
  font-size: 13px;
  position: absolute;
  z-index: 2;
  left: 9px;
  top: 13px;
  transform: translate(-50%, -50%);
  border-radius: 100%;
  width: 9px;
  height: 9px;
}

body:not(.mobile) .transit-stop-box__list__item::after {
  content: '\e901';
  display: block;
  font-family: 'Icons';
  color: #AFB8BF;
  font-size: 10px;
  position: absolute;
  z-index: 2;
  right: 6px;
  top: 13px;
  transform: translate(-50%, -50%);
  border-radius: 100%;
  width: 9px;
  height: 9px;
}

body:not(.mobile) .system-box__header .info-button:hover::after {
  color: #3aa6f4;
}

body:not(.mobile) .transit-box__header .schedule:hover::after {
  color: #3aa6f4;
}

body:not(.mobile) .transit-box__list__item p:hover {
  color: #3aa6f4;
  cursor: pointer;
}

body:not(.mobile) .transit-box__list__item .schedule:hover::after {
  color: #3aa6f4;
}

body:not(.mobile) .back-btn:hover,
body:not(.mobile) .sidebar-container .sidebar__tabs > *:not(.active):hover {
  background: #3aa6f4;
  color: #fff;
  border-color: #3aa6f4;
}

body:not(.mobile) .back-btn:hover::before,
body:not(.mobile) .sidebar-container .sidebar__tabs > *:not(.active):hover::before {
  color: #fff;
}

body:not(.mobile) .transit-stop-box__list__item:hover,
body:not(.mobile) .system-box__list__item:hover,
body:not(.mobile) .operators-list__list__item:hover {
  border-color: #AFB8BF;
  color: #37414A;
}

body:not(.mobile) .transit-stop-box__list__item:hover::after,
body:not(.mobile) .system-box__list__item:hover::after,
body:not(.mobile) .operators-list__list__item:hover::after {
  color: #616B74;
}

.filters {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
  width: 100%;
  max-width: 420px;
  box-shadow: 0 6px 25px -3px rgba(0, 0, 0, 0.09);
  background: #fff;
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  transition: all 0.3s ease-in;
  transform: translate(0, 0);
}

.filters:not(.active) {
  transform: translateX(100%);
  opacity: 0;
  visibility: hidden;
}

.filters__header {
  padding: 16px 16px 6px 16px;
  margin-bottom: -16px;
}

.filters__header .filters__headline {
  font: 400 22px/120% 'Exo2', Helvetica, Arial, sans-serif;
  margin-bottom: 16px;
}

.filters__header .filters__tabs {
  display: flex;
}

.filters__header .filters__tabs > *:not(:last-child) {
  margin-right: 8px;
}

.filters__header .filters__tabs > * {
  display: inline-flex;
  padding: 6px 13px 8px;
  border: 1px solid #DBDFE2;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s linear;
  align-content: center;
  align-items: center;
  justify-content: center;
  user-select: none !important;
  margin-bottom: 16px;
  font-size: 13px;
}

.filters__header .filters__tabs > *.active {
  border-color: #3aa6f4;
  box-shadow: 0 0 0 2px #3aa6f4;
}

.filters__header .filters__tabs .close-btn::before {
  content: "\39";
  font-size: 15px;
  position: relative;
  color: #616B74;
  font-family: "dripicons-v2";
  margin-right: 6px;
  transition: all 0.1s linear;
}

.filters__header .filters__tabs .reset-btn::before {
  content: "\5a";
  font-family: 'dripicons-v2';
  font-size: 15px;
  margin-right: 6px;
  color: #616B74;
}

.filters__header .filters__tabs .apply-btn {
  background: #3aa6f4;
  color: #fff;
  border: none;
  margin-left: auto;
  transition: all 0.2s linear;
  position: relative;
}

.filters__header .filters__tabs .apply-btn::after {
  content: "\53";
  font-family: 'dripicons-v2';
  font-size: 15px;
  margin-left: 6px;
}

.filters__header .filters__tabs .apply-btn::before {
  content: '';
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  background: -moz-linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  background: -webkit-linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#ffffff", GradientType=1);
  opacity: 0.3;
  z-index: 0;
}

.filters__header .filters__tabs .apply-btn.loader-btn {
  pointer-events: none;
  background: #AFB8BF;
}

.filters__header .filters__tabs .apply-btn.loader-btn::before {
  animation: btn-loader 4s ease-in infinite;
}

.filters__wrap {
  width: 100%;
  height: 100%;
  padding: 16px;
  overflow: hidden;
  overflow-y: auto;
}

.filters__wrap-box {
  width: 100%;
}

.filters__wrap-box__headline {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background: #F4F5F5;
  padding: 10px 10px;
  margin-bottom: 16px;
}

.filters__wrap-box__headline .box-name {
  display: flex;
  align-content: center;
  align-items: center;
  font: 600 20px/120% 'Exo2', Helvetica, Arial, sans-serif;
}

.filters__wrap-box__headline .box-name > span {
  font-size: 11px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 20px;
  padding: 3px 12px;
  border-radius: 8px;
  background: #3aa6f4;
  color: #fff;
  margin-left: 6px;
}

.filters__wrap-box__headline .checkbox {
  margin-right: 0;
  display: flex;
  justify-content: space-between;
}

.filters__wrap-box__headline .checkbox > span {
  display: flex;
  order: 3;
  position: relative;
  top: 3px;
  margin: 0 0 0 8px !important;
}

.filters__wrap-box .check-list {
  display: flex;
  flex-direction: column;
  padding-left: 8px;
  padding-right: 8px;
  margin-bottom: 16px;
}

.filters__wrap-box .check-list > * {
  justify-content: space-between;
  display: flex;
  text-align: left;
  width: 100%;
  margin-bottom: 16px;
  font: 400 15px/115% 'Exo2', Helvetica, Arial, sans-serif;
  margin-right: 0;
  position: relative;
  padding-left: 26px;
}

.filters__wrap-box .check-list > *::before {
  font-family: 'Icons';
  color: #AFB8BF;
  position: absolute;
  font-size: 11px;
  top: -2px;
  left: 0;
  width: 22px;
  height: 22px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.filters__wrap-box .check-list > * > span {
  position: relative;
  order: 3;
  top: 0;
  flex: 0 0 18px;
  margin: 0 0 0 8px !important;
}

@keyframes btn-loader {
  0%, 100% {
    left: 0;
    width: 0.1%;
  }

  47% {
    width: 100%;
    left: 0;
  }

  70% {
    width: 100%;
    left: 100%;
  }

  99.9% {
    width: 0.1%;
    left: 100%;
  }
}

.filters__tabs > *:not(.active):active,
.sidebar__tabs > *:not(.active):active {
  transform: translateY(2px);
}

.filters .filter-box__operators .check-list .checkbox::before {
  content: '\e907';
}

.filters .filter-box__routes .check-list .checkbox::before {
  content: '\e902';
  font-size: 15px;
}

body:not(.mobile) .filters__tabs > *:not(.active):hover,
body:not(.mobile) .sidebar__tabs > *:not(.active):hover {
  background: #4ebaff;
  color: #fff;
  border-color: #4ebaff;
}

body:not(.mobile) .filters__tabs > *:not(.active):hover::before,
body:not(.mobile) .sidebar__tabs > *:not(.active):hover::before {
  color: #fff !important;
}

body:not(.mobile) .filters .apply-btn:hover {
  background: #4ebaff;
}

.user-geolocation {
  position: absolute;
  left: 10px;
  top: 10px;
  display: inline-flex;
  background: #fff;
  box-shadow: 0 6px 25px -3px rgba(0, 0, 0, 0.09);
  z-index: 10;
  border: 1px solid #F4F5F5;
}

.user-geolocation > * {
  display: flex;
  width: auto;
  height: 34px;
  padding: 0 13px;
  align-content: center;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  user-select: none !important;
  cursor: pointer;
}

.user-geolocation > *:not(:last-child) {
  border-right: 1px solid #DBDFE2;
}

.map-logo {
  position: absolute;
  width: 260px;
  z-index: 10;
  left: 16px;
  top: 16px;
}

.map-logo img {
  width: 100%;
}

.header-map {
  position: relative;
  height: 80px;
  background: #F4F5F5;
}

.header-map .map-logo {
  top: 50%;
  transform: translateY(-50%);
}

.leaflet-popup-content {
  max-width: 600px;
  width: 400px;
  font-size: 12px;
}

.routes-loader {
  display: none;
  z-index: 15;
  width: 100%;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  height: 22px;
  background: #F4F5F5;
}

.routes-loader > div {
  width: 22%;
  height: inherit;
  background: #3aa6f4;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  color: #fff;
  font: 600 13px/120% 'Exo2', Helvetica, Arial, sans-serif;
}

.operator-info-wrap {
  display: flex;
  margin-bottom: 32px;
}

.operator-main-description {
  flex: 0 0 450px;
  padding-right: 16px;
}

.operator-main-description__image {
  width: 100%;
  height: auto;
  margin-bottom: 16px;
}

.operator-main-description__image .img-wrap {
  position: relative;
  padding-bottom: 66%;
  width: 100%;
  height: auto;
}

.operator-main-description__image .img-wrap img {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.operator-main-description__info p {
  padding: 0;
  margin-bottom: 0;
}

.operator-main-description__info a {
  color: #37414A;
  text-decoration: none;
}

.operator-main-description__info > * {
  padding: 4px 0;
  margin-bottom: 2px !important;
  position: relative;
  display: flex;
}

.operator-main-description__info > *::before {
  content: '';
  top: 2px;
  display: flex;
  font-family: 'dripicons-v2' !important;
  color: #2692E0;
  margin-right: 6px;
}

.operator-main-description__info__address::before {
  content: "\e012";
}

.operator-main-description__info__tel::before {
  content: "\24";
}

.operator-main-description__info__site::before {
  content: "\e064";
}

.operator-map {
  flex: 1 1 auto;
}

.operator-map > * {
  width: 100%;
  height: 60vh;
  min-height: 320px;
  max-height: 780px;
}

body:not(.mobile) .operator-main-description__info a:hover {
  color: #2692E0;
  text-decoration: underline;
}

body:not(.mobile) .map-crumbs > *:hover span {
  color: #78C943;
}

body:not(.mobile) .crumbs > a:hover {
  color: #309cea;
}

.crumbs {
  display: flex;
  min-width: 100%;
  overflow: hidden;
  overflow-x: auto;
}

.crumbs > * {
  padding-right: 26px;
  height: 40px;
  display: inline-flex;
  white-space: nowrap;
  align-content: center;
  align-items: center;
  position: relative;
  color: #37414A;
  font: 400 15px/105% 'Exo2', Helvetica, Arial, sans-serif;
}

.crumbs > *:not(:last-child)::after {
  content: "\e903";
  position: absolute;
  top: 50%;
  right: 6px;
  font-family: 'Icons';
  transform: translateY(-50%) rotate(-180deg);
  font-size: 9px;
  color: #AFB8BF !important;
}

.map-crumbs {
  display: flex;
  min-width: 100%;
  overflow: hidden;
  margin-bottom: 12px;
  flex-direction: column;
}

.map-crumbs__links {
  display: flex;
  white-space: nowrap;
  margin-bottom: 8px;
}

.map-crumbs__links > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  padding-right: 8px;
  position: relative;
  transition: all 0.2s linear;
}

.map-crumbs__links > div:not(:last-child)::after {
  content: "\56";
  position: absolute;
  top: 50%;
  right: 4px;
  font-family: 'dripicons-v2';
  transform: translateY(-50%);
  font-size: 9px;
  color: #AFB8BF !important;
}

.map-crumbs__links > div:last-child {
  overflow: hidden;
  max-width: calc(100% - 100px);
  min-width: calc(100% - 100px);
}

.map-crumbs__links > div:last-child a {
  width: 100%;
}

.map-crumbs__links > div:last-child:hover::after {
  display: none;
}

.map-crumbs__links > div:last-child:hover a {
  display: block;
  position: absolute;
  width: auto !important;
  max-width: none;
  animation: moveText 12s linear infinite;
}

.map-crumbs__links a {
  display: block;
  position: relative;
  color: #37414A;
  font: 400 13px/120% 'Exo2', Helvetica, Arial, sans-serif;
  margin-right: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.map-crumbs h1 {
  line-height: 120%;
}

@keyframes moveText {
  0%, 95%, 100% {
    transform: translateX(0%);
    left: 0%;
  }

  5% {
    transform: translateX(0%);
    left: 0%;
  }

  45%, 55% {
    transform: translateX(-100%);
    left: 100%;
  }
}

.test-markers {
  left: 0;
  top: 120px;
  background: #fff;
  color: #37414A;
  display: inline-flex;
  z-index: 3;
  border: 1px solid red;
  flex-direction: column;
  position: fixed;
}

.test-markers > * {
  padding: 2px 9px;
  cursor: pointer;
  font-size: 13px;
}

.test-markers > *.focus {
  color: #2692E0;
}

.main-container .map-container input.leaflet-control {
  border: none !important;
  box-shadow: inset 0 0 0 1px rgba(55, 65, 74, 0.5) !important;
  background-position: 50% 50% !important;
  border-radius: 4px !important;
}

.map-container .leaflet-bottom.leaflet-left {
  bottom: 54px;
}

.map-container .leaflet-bottom.leaflet-right {
  bottom: 54px;
}

.map-container .leaflet-bottom.leaflet-right input.leaflet-control,
.map-container .leaflet-control-zoom.leaflet-bar.leaflet-control a.leaflet-control-zoom-in,
.map-container .leaflet-control-zoom.leaflet-bar.leaflet-control a.leaflet-control-zoom-out {
  width: 36px !important;
  height: 36px !important;
  display: flex !important;
  position: relative !important;
  box-shadow: 0 0 0 1px rgba(55, 65, 74, 0.5) !important;
  color: #fff !important;
}

.map-container .leaflet-bottom.leaflet-right input.leaflet-control {
  background-repeat: no-repeat !important;
  background-size: 26px 26px !important;
  background-position: 50% 50% !important;
}

.map-container .leaflet-control-zoom.leaflet-bar.leaflet-control a.leaflet-control-zoom-in,
.map-container .leaflet-control-zoom.leaflet-bar.leaflet-control a.leaflet-control-zoom-out {
  text-indent: 50000px !important;
}

.map-container .leaflet-control-zoom.leaflet-bar.leaflet-control a.leaflet-control-zoom-in::before,
.map-container .leaflet-control-zoom.leaflet-bar.leaflet-control a.leaflet-control-zoom-out::before {
  width: 26px !important;
  height: 26px !important;
  position: absolute;
  font-family: 'dripicons-v2' !important;
  font-size: 16px;
  display: flex !important;
  justify-content: center !important;
  align-items: center  !important;
  align-content: center  !important;
  padding: 0 !important;
  text-indent: 0 !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #37414A !important;
}

.map-container .leaflet-control-zoom.leaflet-bar.leaflet-control a.leaflet-control-zoom-in {
  border-radius: 4px 4px 0 0 !important;
}

.map-container .leaflet-control-zoom.leaflet-bar.leaflet-control a.leaflet-control-zoom-in::before {
  content: '\e035';
}

.map-container .leaflet-control-zoom.leaflet-bar.leaflet-control a.leaflet-control-zoom-out {
  border-radius: 0 0 4px 4px !important;
  top: -1px !important;
}

.map-container .leaflet-control-zoom.leaflet-bar.leaflet-control a.leaflet-control-zoom-out::before {
  content: '\e024';
}

.main-container .map-container .leaflet-control-zoom.leaflet-bar.leaflet-control {
  border: none !important;
}

.contact-us-map {
  display: block;
  width: 100%;
  height: 50vh;
  min-height: 200px;
  max-height: 600px;
  background: #F4F5F5;
  z-index: 2;
}

.help-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  background: #2692E0;
  color: #fff;
  padding: 6px 12px;
  min-width: 46px;
  min-height: 46px;
  border-radius: 0 8px 8px 0;
  position: fixed;
  bottom: 10%;
  left: 0;
  z-index: 5;
  text-decoration: none;
  box-shadow: 0 6px 25px -3px rgba(0, 0, 0, 0.09);
  animation: slowJump 10s ease-in-out infinite;
}

.help-btn > div {
  margin-left: 0;
  max-width: 1px;
  opacity: 0;
  transition: all 0.18s linear;
  font: 600 13px/120% 'Exo2', Helvetica, Arial, sans-serif;
}

.help-btn::before {
  content: "\e009";
  font-family: "dripicons-v2";
  color: #fff;
  animation: bell 9s linear infinite;
  border-radius: 100%;
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
  z-index: 3;
}

.help-btn::after {
  content: '';
  display: flex;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 13px;
  background: #2692E0;
  border-radius: 100%;
  top: 45%;
  transform: scale(1);
  transform-origin: center center !important;
  z-index: 2;
  animation: miganie 12s linear infinite;
  pointer-events: none !important;
}

.help-btn:hover > div {
  margin-left: 6px;
  max-width: 180px;
  opacity: 1;
  color: #fff !important;
}

@keyframes miganie {
  0%, 26%, 100% {
    transform: scale(1);
    opacity: 0;
  }

  27% {
    transform: scale(15);
    opacity: 0.25;
  }

  28% {
    opacity: 0.13;
  }

  29% {
    transform: scale(30);
    opacity: 0;
  }
}

@keyframes bell {
  0%, 5%, 100% {
    transform: scale(1) rotate(0);
  }

  6% {
    transform: scale(1.2) rotate(-16deg);
  }

  7% {
    transform: scale(1.4) rotate(16deg);
  }

  8% {
    transform: scale(1.8) rotate(-13deg);
  }

  9% {
    transform: scale(1.6) rotate(10deg);
  }

  10% {
    transform: scale(1.4) rotate(-9deg);
  }

  11% {
    transform: scale(1) rotate(0);
  }

  13% {
    transform: scale(1) rotate(0);
  }

  14% {
    transform: scale(1.2) rotate(-16deg);
  }

  15% {
    transform: scale(1.4) rotate(16deg);
  }

  15.5% {
    transform: scale(1.8) rotate(-13deg);
  }

  16% {
    transform: scale(1.6) rotate(10deg);
  }

  16.5% {
    transform: scale(1.4) rotate(-9deg);
  }

  17% {
    transform: scale(1) rotate(0);
  }

  18% {
    transform: scale(1) rotate(0);
  }
}

@keyframes slowJump {
  0%, 20%, 40%, 100% {
    transform: translateY(0);
    box-shadow: -2px 8px 10px -2px rgba(0, 0, 0, 0.4);
  }

  30% {
    transform: translateY(-50%);
    box-shadow: -2px 20px 14px -2px rgba(0, 0, 0, 0.25);
  }
}

.section-welcome {
  display: flex;
  min-height: 520px;
  max-height: 860px;
  height: 75vh;
  background: url("/img/welcome-bg.jpg") 50% 90% no-repeat;
  background-size: cover !important;
  position: relative;
}

.section-welcome .form-search__wrap {
  width: 100%;
  max-width: 960px;
}

.section-welcome .btn_search {
  display: none !important;
  pointer-events: none !important;
}

.section-welcome__title {
  margin-bottom: 32px;
  position: relative;
  z-index: 1;
}

.section-welcome__title h1 {
  font-size: 60px;
  color: #fff;
  text-align: center;
  line-height: 110%;
  text-transform: uppercase;
}

.section-welcome::after {
  content: '';
  display: block;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.55);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.section-welcome__form {
  position: relative;
  z-index: 1;
  margin-bottom: 16px;
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
}

.section-welcome__form input:focus:placeholder-shown ~ label.label_bottom,
.section-welcome__form textarea:focus:placeholder-shown ~ label.label_bottom,
.section-welcome__form select:focus:placeholder-shown ~ label.label_bottom,
.section-welcome__form input:not(:placeholder-shown) ~ label.label_bottom,
.section-welcome__form textarea:not(:placeholder-shown) ~ label.label_bottom,
.section-welcome__form select:not(:placeholder-shown) ~ label.label_bottom {
  color: #fff !important;
}

.section-welcome__anchor {
  position: relative;
  flex-direction: column;
  z-index: 1;
  color: #fff;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.section-welcome__anchor > div {
  margin-bottom: 16px;
}

.c-center {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.section-state-list {
  padding-top: 48px;
  padding-bottom: 48px;
}

.section-state-list .state-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
  margin-right: -8px;
}

.section-state-list .state-list__item {
  width: 20%;
  display: flex;
  padding: 0 8px;
  margin-bottom: 16px;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.section-state-list .state-list__item a {
  background: #fff;
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  padding: 8px 16px 8px 76px;
  min-height: 58px;
  text-decoration: none;
  box-shadow: 0 6px 25px -3px rgba(0, 0, 0, 0.09);
  border: 1px solid #F4F5F5;
  border-radius: 8px;
  width: 100%;
  color: #37414A;
  line-height: 110%;
}

.section-state-list .state-list__item a .state-flag {
  display: flex;
  position: absolute;
  left: 12.30769231px;
  top: 12.30769231px;
  border: 1px solid #DBDFE2;
  overflow: hidden;
  border-radius: 2.66666667px;
  order: 0;
  width: 54px;
  min-width: 54px;
  max-width: 54px;
  height: 32px;
  filter: grayscale(20%);
  opacity: 0.9;
  transition: all 0.2s linear;
}

.section-state-list .state-list__item a .state-flag img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
  left: 0;
  top: 0;
}

body:not(.mobile) .section-state-list .state-list__item a:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 36px 0 rgba(0, 0, 0, 0.1);
  z-index: 5;
}

body:not(.mobile) .section-state-list .state-list__item a:hover .state-flag {
  filter: grayscale(0);
  opacity: 1;
}

.section-fact-list {
  padding-top: 80px;
  padding-bottom: 80px;
}

.section-fact-list .fact-list {
  display: flex;
  margin-left: -16px;
  margin-right: -16px;
  flex-wrap: wrap;
}

.section-fact-list .fact-list__item {
  width: 33.33%;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  padding-left: 16px;
  padding-right: 16px;
}

.section-fact-list .fact-list__item__text {
  max-width: 360px;
  text-align: center;
  line-height: 113%;
}

.section-fact-list .fact-list__item__icon {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
}

.section-fact-list .fact-list__item__icon img {
  width: 200px;
}

.padding-top__x2 {
  padding-top: 32px;
}

.text-indent {
  padding-left: 16px;
}

.mini-preloader {
  pointer-events: none;
  display: flex;
  width: 100%;
  height: 0.1%;
  min-height: 0;
  opacity: 0;
  visibility: hidden;
  justify-content: center;
  align-items: center;
  align-content: center;
  transition: all 0.12s linear;
  padding: 0 !important;
  margin: 0 !important;
  max-height: 0 !important;
}

.mini-preloader.active {
  height: 100%;
  min-height: 100px;
  opacity: 1;
  visibility: visible;
  max-height: 8000px !important;
}

.mini-preloader > div {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.mini-preloader > div::after {
  content: "\e011";
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 26px;
  color: #DBDFE2;
  animation: loader-spin 1s steps(8) infinite;
}

.mini-preloader.active ~ .sidebar__box__list {
  display: none;
}

@keyframes loader-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.section-country-list .big-banner {
  display: flex;
  width: 100%;
  overflow: hidden;
  position: relative;
  padding-bottom: 30%;
  border-radius: 8px;
  margin-bottom: 32px;
}

.section-country-list .big-banner.display-none {
  display: none !important;
}

.section-country-list .big-banner.display-none ~ * {
  margin-top: 16px;
}

.section-country-list .big-banner img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.country-list {
  margin-bottom: 32px;
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
  margin-right: -8px;
}

.country-list__item {
  width: 25%;
  display: flex;
  padding: 0 8px;
  margin-bottom: 16px;
}

.country-list__item__box {
  background: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
  text-decoration: none;
  box-shadow: 0 6px 25px -3px rgba(0, 0, 0, 0.09);
  border: 1px solid #F4F5F5;
  border-radius: 8px;
  width: 100%;
  color: #37414A;
  line-height: 113%;
  transition: all 0.12s linear;
  /*
      &::after {
        content: "\e903";
        position: absolute;
        top: 50%;
        right: 16px;
        font-family: @icons;
        transform: translateY(-50%) rotate(-180deg);
        font-size: 9px;
        color: @color_b3 !important;
      }

 */
}

.country-list__item__box .box-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  font: 400 20px/110% 'Exo2', Helvetica, Arial, sans-serif;
  padding: 8px 8px 8px 36px;
  min-height: 42px;
}

.country-list__item__box .box-title::before {
  content: '\e902';
  width: 26px;
  height: 26px;
  display: flex;
  position: absolute;
  align-content: center;
  align-items: center;
  justify-content: center;
  font-family: Icons;
  font-size: 20px;
  color: #2692E0;
  margin-right: 3px;
  left: 7px;
  top: 8px;
}

.country-list__item__box .box-links {
  display: flex;
  box-shadow: inset 0 1px 0 0 #F4F5F5;
  background: #fff;
  border-radius: 0 0 8px 8px;
  overflow: hidden;
  margin-top: auto;
}

.country-list__item__box .box-links a {
  display: flex;
  padding: 3px 6px;
  text-align: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  min-height: 36px;
  flex: 1 1 200px;
  font-size: 14px;
  text-decoration: none;
  color: #616B74;
}

.country-list__item__box .box-links a:not(:last-child) {
  box-shadow: inset -1px 0 0 0 #F4F5F5;
}

.state-county-list {
  margin-bottom: 32px;
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
  margin-right: -8px;
}

.state-county-list__item {
  width: 25%;
  display: flex;
  padding: 0 8px;
  margin-bottom: 16px;
}

.state-county-list__item__box {
  background: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
  text-decoration: none;
  box-shadow: 0 6px 25px -3px rgba(0, 0, 0, 0.09);
  border: 1px solid #F4F5F5;
  border-radius: 8px;
  width: 100%;
  color: #37414A;
  line-height: 113%;
  transition: all 0.12s linear;
}

.state-county-list__item__box a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  font: 400 20px/110% 'Exo2', Helvetica, Arial, sans-serif;
  padding: 8px 8px 8px 36px;
  min-height: 42px;
  color: #37414A;
  text-decoration: none;
}

.state-county-list__item__box a::before {
  content: '\e902';
  width: 26px;
  height: 26px;
  display: flex;
  position: absolute;
  align-content: center;
  align-items: center;
  justify-content: center;
  font-family: Icons;
  font-size: 20px;
  color: #2692E0;
  margin-right: 3px;
  left: 7px;
  top: 8px;
}

body:not(.mobile) .country-list__item__box:hover {
  transform: scale(1.02);
  box-shadow: 0 6px 36px 0 rgba(0, 0, 0, 0.1);
  z-index: 5;
}

body:not(.mobile) .country-list__item__box:hover::before {
  color: #2692E0;
}

body:not(.mobile) .country-list__item__box:hover::after {
  right: 8px;
}

body:not(.mobile) .country-list__item__box a:hover {
  background: #2692E0;
  color: #fff;
}

body:not(.mobile) .state-county-list__item__box:hover {
  transform: scale(1.02);
  box-shadow: 0 6px 36px 0 rgba(0, 0, 0, 0.1);
  z-index: 5;
}

.schedule-title {
  display: flex;
  margin-bottom: 16px;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}

.schedule-title h2 {
  font: 600 26px/120% 'Exo2', Helvetica, Arial, sans-serif;
}

.schedule-title .schedule-quantity {
  display: inline-flex;
  margin-right: auto;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-self: auto;
  border-radius: 8px;
  margin-left: 16px;
  background: #2692E0;
  color: #fff;
  font: 600 18px/120% 'Exo2', Helvetica, Arial, sans-serif;
}

.schedule-table {
  display: table;
  margin-bottom: 32px;
  width: 100%;
  border: 1px solid #F4F5F5;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 7px 27px -3px rgba(0, 0, 0, 0.07);
}

.schedule-table__item {
  display: table-row;
}

.schedule-table__item:not(:last-child) .schedule-table__item__td {
  box-shadow: inset 0 -1px 0 0 rgba(219, 223, 226, 0.6);
}

.schedule-table__item__td,
.schedule-table__item__th {
  display: table-cell;
  padding: 8px 10px;
  border: 0;
  vertical-align: middle;
}

.schedule-table__item__td.route-name,
.schedule-table__item__th.route-name {
  width: 50%;
}

.schedule-table__item__td.route-name .long-name,
.schedule-table__item__th.route-name .long-name {
  margin-bottom: 8px;
}

.schedule-table__item__td.route-name .long-name > div,
.schedule-table__item__th.route-name .long-name > div,
.schedule-table__item__td.route-name .short-name > div,
.schedule-table__item__th.route-name .short-name > div {
  font: 400 13px/120% 'Exo2', Helvetica, Arial, sans-serif;
  color: #616B74;
}

.schedule-table__item__td.route-name .long-name > h3,
.schedule-table__item__th.route-name .long-name > h3,
.schedule-table__item__td.route-name .short-name > h3,
.schedule-table__item__th.route-name .short-name > h3 {
  font: 600 16px/120% 'Exo2', Helvetica, Arial, sans-serif;
}

.schedule-table__item__td.stop-name,
.schedule-table__item__th.stop-name {
  width: 230px;
  font: 600 16px/120% 'Exo2', Helvetica, Arial, sans-serif !important;
}

.schedule-table__item__td.stop-name > *,
.schedule-table__item__th.stop-name > * {
  font: 600 16px/120% 'Exo2', Helvetica, Arial, sans-serif !important;
}

.schedule-table__item__td.stop-time,
.schedule-table__item__th.stop-time {
  font: 400 16px/120% 'Exo2', Helvetica, Arial, sans-serif;
}

.schedule-table__item__td.stop-time:nth-child(2n+2),
.schedule-table__item__th.stop-time:nth-child(2n+2) {
  background: #f7f8f8;
}

.schedule-table__item__td.stop-time > *,
.schedule-table__item__th.stop-time > * {
  font: 400 16px/120% 'Exo2', Helvetica, Arial, sans-serif;
}

.schedule-table__item__td.arrival-time,
.schedule-table__item__th.arrival-time,
.schedule-table__item__td.departure-time,
.schedule-table__item__th.departure-time {
  width: 25%;
}

.schedule-table__item__td.arrival-time p,
.schedule-table__item__th.arrival-time p,
.schedule-table__item__td.departure-time p,
.schedule-table__item__th.departure-time p {
  display: inline-flex;
  text-align: center !important;
}

.schedule-table__item__td:first-child,
.schedule-table__item__th:first-child {
  border-radius: 8px 0 0 0;
}

.schedule-table__item__td:last-child,
.schedule-table__item__th:last-child {
  border-radius: 0 8px 0 0;
}

.schedule-table__item__th {
  background: #2692E0;
  color: #fff;
}

.ui-menu.ui-widget.ui-widget-content {
  border-radius: 8px;
  overflow: hidden;
  border: none !important;
  box-shadow: 0 8px 20px -3px rgba(0, 0, 0, 0.23);
  background: white;
  width: calc(100% - 16px*2) !important;
  max-width: 640px !important;
  z-index: 20;
}

.ui-menu.ui-widget.ui-widget-content .ui-widget-content {
  display: none;
}

.ui-menu.ui-widget.ui-widget-content .ui-menu-item {
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.05);
  border: none !important;
}

.ui-menu.ui-widget.ui-widget-content .ui-menu-item > div {
  display: flex;
  padding: 10px 12px;
  font: 300 14px/113% 'Exo2';
  border: none !important;
  min-height: 36px;
  margin: 0 !important;
}

.ui-menu.ui-widget.ui-widget-content .ui-menu-item > div.ui-state-active {
  background: #78C943;
  border: none;
}

.ui-helper-hidden-accessible {
  position: absolute;
  opacity: 0;
  visibility: hidden;
}

.ui-menu-item-wrapper.ui-state-active {
  background: #DBDFE2 !important;
  cursor: pointer;
}

body div.ui-helper-hidden-accessible {
  position: absolute !important;
  z-index: -50 !important;
  opacity: 0 !important;
  visibility: hidden !important;
}

.search-loading {
  background: url('/img/loader.gif') no-repeat 50% 50%;
  background-size: 80%;
  opacity: 0;
  display: flex;
  position: absolute !important;
  top: 50%;
  transition: all 0.2s linear;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  z-index: 5;
  pointer-events: none;
  right: 4px;
}

.ui-autocomplete-input.ui-autocomplete-loading ~ .search-loading {
  opacity: 1;
}

.search_box.ui-widget {
  flex-wrap: wrap;
}

.display-none {
  display: none !important;
}

@media screen and (min-width: 768px) {
  .checkbox:hover input:not[disabled] + span,
  .radio:hover input:not[disabled] + span {
    box-shadow: inset 0 0 0 2px rgba(15, 185, 177, 0.2);
  }
}

@media only screen and (max-width: 1240px), only screen and (max-device-width: 1240px) {
  .header.desktop-header .menu__item a {
    font: 600 14px/120% 'Exo2', Helvetica, Arial, sans-serif;
  }
}

@media only screen and (max-width: 1239px), only screen and (max-device-width: 1239px) {
  .operator-main-description {
    flex: 0 0 280px;
  }
}

@media only screen and (max-width: 1190px), only screen and (max-device-width: 1190px) {
  .section-state-list .state-list__item {
    width: 25%;
  }
}

@media only screen and (max-width: 960px), only screen and (max-device-width: 960px) {
  .text-box p,
  .text-wrap p {
    font: 400 15px/120% 'Exo2', Helvetica, Arial, sans-serif;
  }

  .form_footer2 {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: stretch;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
  }

  .form_footer2 > * {
    flex-basis: 100%;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 18px;
  }

  .form_footer2 > * > * {
    width: 100%;
  }

  .popup__title {
    font: 600 24px/120% 'Exo2', Helvetica, Arial, sans-serif;
  }

  .operator-info-wrap {
    flex-direction: column;
  }

  .operator-main-description {
    padding-right: 0;
    flex: 1 1 auto;
    display: flex;
    margin-left: -16px;
    margin-right: -16px;
  }

  .operator-main-description > * {
    width: 50%;
    padding-left: 16px;
    padding-right: 16px;
  }

  .section-state-list .state-list__item {
    width: 33.33%;
  }

  .country-list__item {
    width: 33.33%;
  }

  .state-county-list__item {
    width: 33.33%;
  }
}

@media only screen and (max-width: 920px), only screen and (max-device-width: 920px) {
  .footer__logo a {
    height: 26px;
  }

  .footer__logo a img {
    height: 22px;
  }

  .footer__copyright {
    top: 10px;
    order: 99;
    margin-bottom: 0 !important;
  }

  .footer__nav {
    width: 100%;
    padding-top: 0;
    flex-direction: column;
    display: flex;
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s linear;
    position: relative;
  }

  .footer__nav::after {
    content: '';
    position: absolute;
    top: 8px;
    width: 100%;
    height: 1px;
    background: #616B74;
  }

  .footer__nav a {
    font: 400 13px/120% 'Exo2', Helvetica, Arial, sans-serif;
    padding-left: 0;
    margin: 0 10px;
  }

  .footer .burger-btn {
    display: flex;
  }

  .footer .burger-btn.active::before {
    content: "\39";
  }

  .footer .burger-btn.active ~ .footer__nav {
    max-height: 600px;
    opacity: 1;
    visibility: visible;
    padding-top: 24px;
  }
}

@media only screen and (max-width: 860px), only screen and (max-device-width: 860px) {
  .resolution-wrap {
    padding: 0 16px;
  }

  .headernames_box > h1 {
    font: 400 32px/120% 'Exo2', Helvetica, Arial, sans-serif;
  }

  .text-box h1,
  .text-wrap h1 {
    font: 600 32px/120% 'Exo2', Helvetica, Arial, sans-serif;
  }

  .text-box h2,
  .text-wrap h2 {
    font: 600 25px/120% 'Exo2', Helvetica, Arial, sans-serif;
  }

  .text-box h3,
  .text-wrap h3 {
    font: 600 20px/120% 'Exo2', Helvetica, Arial, sans-serif;
  }

  .text-box h4,
  .text-wrap h4 {
    font: 600 18px/120% 'Exo2', Helvetica, Arial, sans-serif;
  }

  .map-wrap-scroll {
    height: calc(var(--app-height) - 208px);
  }

  .section-fact-list .fact-list__item {
    width: 100% !important;
  }

  .section-fact-list .fact-list__item:not(:last-child) {
    margin-bottom: 32px;
  }
}

@media only screen and (max-width: 780px), only screen and (max-device-width: 780px) {
  .text-box .row[data-columns='4'] > .column,
  .text-wrap .row[data-columns='4'] > .column,
  .text-box .logos[data-columns='4'] > .column,
  .text-wrap .logos[data-columns='4'] > .column {
    width: 50% !important;
  }
}

@media only screen and (max-width: 740px), only screen and (max-device-width: 740px) {
  .logos-table > div {
    width: 50%;
  }

  .logos-table > div > a {
    max-width: 250px;
  }

  .text-box table.adaptive-table tr,
  .text-wrap table.adaptive-table tr {
    grid-template-columns: 1fr auto auto;
  }

  .text-box table.adaptive-table tr > td:nth-child(1),
  .text-wrap table.adaptive-table tr > td:nth-child(1) {
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: 2;
    grid-column-end: 2;
  }

  .text-box table.adaptive-table tr > td:nth-child(2),
  .text-wrap table.adaptive-table tr > td:nth-child(2) {
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: 2;
    grid-column-end: 3;
  }

  .text-box table.adaptive-table tr > td:nth-child(3),
  .text-wrap table.adaptive-table tr > td:nth-child(3) {
    grid-row-start: 1;
    grid-column-start: 3;
    grid-row-end: 2;
    grid-column-end: 4;
  }

  .text-box table.adaptive-table tr > td:nth-child(4),
  .text-wrap table.adaptive-table tr > td:nth-child(4) {
    grid-row-start: 2;
    grid-column-start: 1;
    grid-row-end: 3;
    grid-column-end: 2;
  }

  .text-box table.adaptive-table tr > td:nth-child(5),
  .text-wrap table.adaptive-table tr > td:nth-child(5) {
    grid-row-start: 3;
    grid-column-start: 1;
    grid-row-end: 4;
    grid-column-end: 2;
  }

  .text-box table.adaptive-table tr > td:nth-child(6),
  .text-wrap table.adaptive-table tr > td:nth-child(6) {
    grid-row-start: 2;
    grid-column-start: 2;
    grid-row-end: 4;
    grid-column-end: 4;
  }

  .text-box table.adaptive-table tr > td:nth-child(7),
  .text-wrap table.adaptive-table tr > td:nth-child(7) {
    grid-row-start: 4;
    grid-column-start: 1;
    grid-row-end: 5;
    grid-column-end: 4;
  }

  .popup__container .popup__window {
    border-radius: 4px;
  }
}

@media only screen and (max-width: 720px), only screen and (max-device-width: 720px) {
  .section-state-list .state-list__item {
    width: 50%;
  }

  .country-list__item {
    width: 50%;
  }

  .state-county-list__item {
    width: 50%;
  }
}

@media only screen and (max-width: 640px), only screen and (max-device-width: 640px) {
  .resolution-text-wrap {
    padding: 0 23px;
  }

  .text-box .row[data-columns='3'] > .column,
  .text-wrap .row[data-columns='3'] > .column,
  .text-box .logos[data-columns='3'] > .column,
  .text-wrap .logos[data-columns='3'] > .column {
    width: 100% !important;
  }

  .map-wrap {
    flex-direction: column;
  }

  .map-wrap .map-container {
    height: 100vh;
    max-height: var(--app-height) !important;
    min-height: var(--app-height);
  }

  .map-wrap .sidebar-container {
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(20px);
    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.8), 0 0 30px -1px rgba(55, 65, 74, 0.13);
    max-width: none;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0 !important;
    height: 380px !important;
    min-height: 380px !important;
    max-height: 380px !important;
  }

  .map-wrap .sidebar-container__header .sidebar__headline {
    display: none;
  }

  .map-wrap .sidebar {
    padding-top: 0;
    padding-bottom: 50px;
  }

  .filters {
    max-width: none;
  }

  .filters:not(.active) {
    transform: translateY(100%);
    opacity: 0;
    visibility: hidden;
    box-shadow: 0 0 3000px 3000px rgba(0, 0, 0, 0.8);
  }

  .map-logo {
    width: 180px;
  }

  .main-container .map-container .leaflet-bottom.leaflet-right {
    top: 10px;
    right: 0;
    bottom: auto !important;
    left: auto !important;
    height: 100vh !important;
    height: -webkit-fill-available !important;
  }

  .main-container .map-container .leaflet-bottom.leaflet-right .leaflet-control-attribution.leaflet-control {
    position: absolute;
    top: auto;
    bottom: 390px !important;
    width: 100vw;
    left: auto;
    right: 0;
  }
}

@media only screen and (max-width: 620px), only screen and (max-device-width: 620px) {
  .login-footer {
    flex-direction: column;
  }

  .login-footer > * {
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 580px), only screen and (max-device-width: 580px) {
  .text-box .row[data-columns='2'] > .column,
  .text-wrap .row[data-columns='2'] > .column,
  .text-box .logos[data-columns='2'] > .column,
  .text-wrap .logos[data-columns='2'] > .column {
    width: 100% !important;
  }

  .text-box .row[data-columns='4'] > .column,
  .text-wrap .row[data-columns='4'] > .column,
  .text-box .logos[data-columns='4'] > .column,
  .text-wrap .logos[data-columns='4'] > .column {
    width: 100% !important;
  }
}

@media only screen and (max-width: 560px), only screen and (max-device-width: 560px) {
  .logos-table > div {
    width: 100%;
  }

  .logos-table > div > a {
    max-width: 220px;
  }

  .text-box h1,
  .text-wrap h1 {
    font: 600 28px/120% 'Exo2', Helvetica, Arial, sans-serif;
  }

  .text-box h2,
  .text-wrap h2 {
    font: 600 20px/120% 'Exo2', Helvetica, Arial, sans-serif;
  }

  .text-box h3,
  .text-wrap h3 {
    font: 600 18px/120% 'Exo2', Helvetica, Arial, sans-serif;
  }

  .text-box table.adaptive-table tr,
  .text-wrap table.adaptive-table tr {
    grid-template-columns: 1fr;
  }

  .text-box table.adaptive-table tr > td:nth-child(1),
  .text-wrap table.adaptive-table tr > td:nth-child(1) {
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: 2;
    grid-column-end: 2;
  }

  .text-box table.adaptive-table tr > td:nth-child(2),
  .text-wrap table.adaptive-table tr > td:nth-child(2) {
    grid-row-start: 2;
    grid-column-start: 1;
    grid-row-end: 3;
    grid-column-end: 2;
  }

  .text-box table.adaptive-table tr > td:nth-child(3),
  .text-wrap table.adaptive-table tr > td:nth-child(3) {
    grid-row-start: 3;
    grid-column-start: 1;
    grid-row-end: 4;
    grid-column-end: 2;
  }

  .text-box table.adaptive-table tr > td:nth-child(4),
  .text-wrap table.adaptive-table tr > td:nth-child(4) {
    grid-row-start: 4;
    grid-column-start: 1;
    grid-row-end: 5;
    grid-column-end: 2;
  }

  .text-box table.adaptive-table tr > td:nth-child(5),
  .text-wrap table.adaptive-table tr > td:nth-child(5) {
    grid-row-start: 5;
    grid-column-start: 1;
    grid-row-end: 6;
    grid-column-end: 2;
  }

  .text-box table.adaptive-table tr > td:nth-child(6),
  .text-wrap table.adaptive-table tr > td:nth-child(6) {
    grid-row-start: 6;
    grid-column-start: 1;
    grid-row-end: 7;
    grid-column-end: 2;
  }

  .text-box table.adaptive-table tr > td:nth-child(7),
  .text-wrap table.adaptive-table tr > td:nth-child(7) {
    grid-row-start: 7;
    grid-column-start: 1;
    grid-row-end: 8;
    grid-column-end: 2;
  }

  .map-wrap-scroll {
    height: calc(var(--app-height) - 204px);
  }

  .section-state-list .state-list__item a {
    font-size: 15px;
    min-height: 54px;
    padding: 8px 8px 8px 72px;
  }

  .section-state-list .state-list__item a .state-flag {
    left: 10.66666667px;
  }

  .section-state-list .state-list__item a .state-flag {
    width: 48px;
    min-width: 48px;
    max-width: 48px;
    height: 27px;
  }
}

@media only screen and (max-width: 540px), only screen and (max-device-width: 540px) {
  .text-box .numeric-hn > span,
  .text-wrap .numeric-hn > span {
    font: 600 20px/120% 'Exo2', Helvetica, Arial, sans-serif !important;
  }

  .footer__logo a {
    max-width: 20px;
    overflow: hidden;
  }
}

@media only screen and (max-width: 480px), only screen and (max-device-width: 480px) {
  #go-top {
    bottom: 85px;
  }

  .operator-main-description {
    flex-direction: column;
  }

  .operator-main-description > * {
    width: 100%;
  }

  .section-state-list .state-list__item {
    width: 100%;
  }

  .section-state-list .state-list__item a {
    font-size: 17px;
  }

  .country-list__item {
    width: 100%;
  }

  .state-county-list__item {
    width: 100%;
  }
}

@media not all and (min-resolution: 0.001dpcm) {
@supports (-webkit-appearance:none) and (stroke-color: transparent) {
    body {
      min-height: -webkit-fill-available;
    }
}
}