//============================================================================
//+++++++++++++++++++++++++++++++++ HEADER +++++++++++++++++++++++++++++++++++
//============================================================================

// BIG HEADER
// ====================================
.header.desktop-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  z-index: 90;
  width: 100%;
  max-width: @maxresolution;
  height: auto;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: @color_w;
  box-shadow: 0 0 13px -1px rgba(@color_b1, .13);

  .menu__desktop-hide {
    display: none;
  }

  /*
  .languages{
    display: flex;

    a{
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      width: 40px;
      height: 40px;
       font: @f_sbold 14px/120% @font;
      border-radius: 100%;
      text-align: center;
      color: @color_b3 - 60%;
      margin-right: @gap/2;

      &:not(.active):hover{
        background: @color_1;
        color: @color_w !important;

      }

      &.active{
        pointer-events: none;
        border: 1px solid @color_2;
        //background: @color_1;
        //background: @color_2;
        color: @color_w;
        position: relative;
        box-shadow: inset 0 0 0 1px rgba(@color_1, 1);

        &::before{
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          pointer-events: none;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          border-radius: @br;
          box-shadow: inset 1px 1px 0 0 rgba(@color_3, 1)

        }
      }
    }
  }
  */

  .logo {
    display: flex;
    width: 210px;
    height: 53px;
    transition: all .3s linear;
    position: absolute;
    top: 50%;
    left: @gap;
    transform: translateY(-50%);

    a {
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: flex-start;

      img {
        height: 53px;
      }
    }
  }

  .languages {
    display: flex;
    position: relative;

    .lang_selected {
      display: flex;
      padding-right: 10px;
      width: 62px;
      height: 32px;
      color: @color_w;
      align-content: center;
      font-size: 16px;
      align-items: center;
      justify-content: center;
      transition: all 0.07s linear;
      cursor: pointer;

      &::after {
        position: absolute;
        right: 6px;
        top: 50%;
        transform: translateY(-50%);
        content: 'T';
        .font-icon;
        color: @color_b3 !important;
      }
    }

    .lang_drop {
      display: flex;
      width: 100%;
      padding: 0 0 4px 0;
      flex-direction: column;
      background: @color_b1;
      position: absolute;
      transition: all .1s linear;
      top: 0;
      opacity: 0;
      visibility: hidden;

      a {
        width: 62px;
        padding-right: 10px;
        height: 32px;
        align-content: center;
        font-size: 16px;
        align-items: center;
        justify-content: center;
        display: flex;
        color: @color_w;
        text-decoration: none;

        &.active {
          display: none;
        }

      }
    }

    &.active {
      .lang_selected {
        background: @color_b1;

        &::after {
          transform: translateY(-50%) rotate(-180deg);
        }
      }

      .lang_drop {
        top: 32px;
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .menu {
    display: none !important;
    margin-left: auto;
    margin-right: 0;

    &__wrap {
      display: flex;
    }

    &__item {
      position: relative;

      a {
        display: -webkit-box;
        min-height: 46px;
        padding: 0 @gap;
        font: @f_sbold 18px/120% @font;
        text-decoration: none;
        color: @color_b1;
        align-content: center;
        align-items: center;
        justify-content: center;
        text-align: center;

        @media only screen and (max-width: 1240px), only screen and (max-device-width: 1240px) {
          font: @f_sbold 14px/120% @font;
        }

        &.active {


          &::after{
            content: '';
            display: block;
            position: absolute;
            bottom: -17px;
            left: 0;
            z-index: 0;
            width: 100%;
            height: 4px;
            background: @color_1;
          }
        }


      }

    }
  }

  .resolution-wrap {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    position: relative;
    height: 60px;
    transition: all .2s linear;
  }
}

body:not(.mobile) .header.desktop-header{
  .menu {
    &__item {
      a {
        &:hover {
          color: @color_2;

          &::after{
            content: '';
          }
        }
      }

    }
  }
}

// SMALL HEADRER
// ====================================
.header.desktop-header.header__small {
  background: rgba(@color_w, .9);
  backdrop-filter: blur(14px);

  .menu {
    &__item {
      a {
        font: @f_reg 14px/120% @font;
        padding: 0 @gap/2;

        &.active {

          &::after{
            bottom: -7px;
          }
        }
      }
    }
  }

  .languages {

    .lang_selected {
      font-size: 13px;
    }

    .lang_drop {
      a {
        font-size: 13px;
      }
    }

    &.active {
      .lang_selected {
        background: @color_b1;

        &::after {
          transform: translateY(-50%) rotate(-180deg);
        }
      }

      .lang_drop {
        top: 32px;
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .logo {
    display: flex;
    width: 160px;
    height: 45px;

    a{
      img{
        height: 45px;
      }
    }

  }

  .resolution-wrap {
    height: 60px;
  }
}

// MOBILE HEADER
// ====================================
.header.mobile-header {
  display: flex;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(@color_w, .95);
  backdrop-filter: blur(10px);

  .menu__desktop-hide {
    display: flex;
    flex-direction: column;
    justify-content: center;

    [class*='style-btn'] {
      margin-bottom: @gap;
    }

    .menu__contacts {
      > * {
        margin-bottom: @gap;

        &::before {

        }
      }

      .contacts__tel,
      .contacts__mail {
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;

        &::before {
          width: 36px;
          height: 36px;
          background-size: 100% auto !important;
        }

        a {
          font-size: 16px;
          font-weight: @f_reg;

          &:hover {
            text-decoration: none !important;
          }
        }
      }

      .contacts__social-media {
        a {
          width: 36px;
          height: 36px;
        }
      }
    }
  }

  .resolution-wrap {
    display: flex;
    flex-direction: row;
    margin: auto;
    height: 60px;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
  }

  .logo {
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    width: 174px;
    height: 40px;

    img{
      height: 40px;
    }
  }

  .menu {
    display: flex;
    top: 0;
    left: 0;
    position: fixed;
    background: rgba(@color_b1, .98);
    backdrop-filter: blur(10px);
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    width: 100%;
    max-width: 460px;
    height: 100vh;
    box-shadow: 0 0 5000px 5000px rgba(0, 0, 0, .6);
    overflow: hidden;
    overflow-y: auto;

    transform: translateX(-100%);
    opacity: 0;
    visibility: hidden;


    &__item {
      display: flex;
      width: 100%;
      padding-left: @gap;
      padding-right: @gap;

      a {
        display: -webkit-box;
        width: 100%;
        height: 60px;
        text-decoration: none;
        align-content: center;
        align-items: center;
        justify-content: center;
        //text-transform: uppercase;
        color: @color_w;
        font-size: 18px;
        transition: all 0.3s linear;
        position: relative;
        border-bottom: 2px solid @color_b1;
        box-shadow: 0 1px 0 0 rgba(@color_b2, .03);
        //background: rgba(@color_b2, .03);

        &.active {
          pointer-events: none;
          background: @color_2;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }

    &__wrap {
      padding-top: @gap*3;
      margin-bottom: @gap*2;
      width: 100%;
    }
  }

  .languages {
    margin-right: @gap;
    display: flex;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;

    .lang_selected {
      display: flex;
      padding-right: 10px;
      width: 62px;
      height: 32px;
      color: @color_w;
      align-content: center;
      font-size: 16px;
      align-items: center;
      justify-content: center;
      transition: all 0.07s linear;
      cursor: pointer;
      border-radius: @br @br 0 0;

      &::after {
        position: absolute;
        right: 6px;
        top: 50%;
        transform: translateY(-50%);
        content: 'T';
        .font-icon;
        color: @color_b3 !important;
      }
    }

    .lang_drop {
      display: flex;
      width: 100%;
      padding: 0 0 4px 0;
      flex-direction: column;
      background: @color_b1;
      position: absolute;
      transition: all .1s linear;
      top: 0;
      opacity: 0;
      visibility: hidden;
      border-radius: 0 0 @br @br;

      a {
        width: 62px;
        padding-right: 10px;
        height: 32px;
        align-content: center;
        font-size: 16px;
        align-items: center;
        justify-content: center;
        display: flex;
        color: @color_w;
        text-decoration: none;

        &.active {
          display: none;
        }

      }
    }

    &.active {
      .lang_selected {
        background: @color_b1;

        &::after {
          transform: translateY(-50%) rotate(-180deg);
        }
      }

      .lang_drop {
        top: 32px;
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .menu-burger {
    display: none !important;
    position: absolute;
    width: 36px;
    height: 36px;
    z-index: 5;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);

    &:hover {
      cursor: pointer;

      > * {
        background: @color_w;
      }
    }

    &::before,
    i,
    &::after {
      display: block;
      width: 75%;
      height: 2px;
      background: @color_b3 !important;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      pointer-events: none;
      transform-origin: 0% 50%;
      transition: all 0.2s linear;
    }

    &::before {
      content: '';
      top: 25%;
    }

    i {
      top: 50%;
    }

    &::after {
      content: '';
      top: 75%;
    }
  }
}

.header.mobile-header.menu-active {
  .menu-burger {
    &::before, &::after {
      top: 50%;
      width: 50%;
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    i {
      width: 30%;
      transform: translate(120%, -50%);
      opacity: 0;
      visibility: hidden;
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  .menu {
    transition: all .3s linear;
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
  }

}


.main_page .logo a {
  pointer-events: none !important;
}


