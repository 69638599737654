.font(@font_name, @file_name, @weight, @style) {
  @font-face {
    font-family: @font_name;
    font-display: swap;
    src: url("/fonts/@{file_name}.woff") format("woff"), url("/fonts/@{file_name}.woff2") format("woff2");
    font-weight: @weight;
    font-style: @style;
  }
}

.font("Exo2", "Exo2-Regular", 400, normal);
.font("Exo2", "Exo2-SemiBold", 600, normal);
.font("Exo2", "Exo2-ExtraBold", 800, normal);
.font("Icons", "icomoon", normal, normal);
.font("dripicons-v2", "dripicons-v2", normal, normal);