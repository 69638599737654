// ---------------------------------- MAIN PAGE

.main-container {
  position: relative;
  //border: 2px solid red;
  //min-height: var(--app-height);
}

.map-wrap-scroll{
  display: flex;
  height: calc(var(--app-height) - 240px);
  //max-height: 100%;
  //max-height: var(--app-height) !important;
  //min-height: var(--app-height);
  overflow: hidden !important;
  position: relative;
  background: @color_b3;

  @media only screen and (max-width: 860px),
  only screen and (max-device-width: 860px) {
    height: calc(var(--app-height) - 208px);
  }
  @media only screen and (max-width: 560px),
  only screen and (max-device-width: 560px) {
    height: calc(var(--app-height) - 204px);
  }

  .map-container-scroll{
    width: 100%;
  }
}

.map-wrap {
  display: flex;
  max-height: 100vh;
  //max-height: 100%;
  max-height: var(--app-height) !important;
  min-height: var(--app-height);
  overflow: hidden !important;
  position: relative;

  .map-container {
    flex: 1 1 auto;
    border: none;
    position: relative;
    width: 100%;
    height: 100vh;
    max-height: var(--app-height) !important;
    min-height: var(--app-height);
    z-index: 1;
  }



  .sidebar-container {
    position: relative;
    width: 100%;
    max-width: 420px;
    height: auto;
    min-height: 100vh;
    box-shadow: @shadow;
    z-index: 2;
    background: @color_w;
  }

  .sidebar {
    padding-bottom: 66px;
  }

  @media only screen and (max-width: 640px), only screen and (max-device-width: 640px) {
    flex-direction: column;

    .map-container {
      height: 100vh;
      max-height: var(--app-height) !important;
      min-height: var(--app-height);
    }

    .sidebar-container {
      background: rgba(@color_w, .8);
      backdrop-filter: blur(20px);
      box-shadow: inset 0 1px 0 0 rgba(@color_w, .8), 0 0 30px -1px rgba(@color_b1, .13);
      max-width: none;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0 !important;
      height: 380px !important;
      min-height: 380px !important;
      max-height: 380px !important;


      &__header {
        .sidebar__headline {
          display: none;
        }
      }
    }

    .sidebar {
      padding-top: 0;
      padding-bottom: 50px;
    }

  }
}

.sidebar-container {
  display: flex;
  flex-direction: column;

  &__header {
    padding: @gap;
    margin-bottom: -@gap;

    .sidebar__headline {
      font: @f_reg 22px/120% @font;
      margin-bottom: @gap;
    }

    .sidebar__tabs {
      display: flex;

      > *:not(:last-child) {
        margin-right: 8px;
      }

      > * {
        display: inline-flex;
        padding: 6px 13px 8px;
        border: 1px solid @color_b4;
        border-radius: @br;
        cursor: pointer;
        transition: all .2s linear;
        align-content: center;
        align-items: center;
        justify-content: center;
        user-select: none !important;
        margin-bottom: @gap;
        font-size: 13px;

        &.active {
          border-color: @color_2;
          box-shadow: 0 0 0 2px @color_2;
        }

      }

      .back-btn {

        &::before {
          content: '\e903';
          font-size: 11px;
          position: relative;
          top: 2px;
          font-family: @icons;
          margin-right: 6px;
          transition: all .1s linear;
        }
      }

      .filters-btn {
        margin-left: auto;
        position: relative;

        > span {
          display: flex;
          align-content: center;
          align-items: center;
          justify-content: center;
          height: 18px;
          min-width: 18px;
          background: @color_2;
          position: absolute;
          top: -9px;
          right: -9px;
          color: @color_w;
          font-size: 9px;
          padding: 1px 4px;
          border-radius: @br;
        }

        &::before {
          content: "\40";
          font-family: 'dripicons-v2';
        }
      }

    }
  }
}

.sidebar {
  width: 100%;
  height: 100%;
  padding: @gap;
  overflow: hidden;
  overflow-y: auto;


  &__box {
    border-radius: @br*2;
    background: @color_w;
    box-shadow: @shadow;
    margin-bottom: @gap /3;
    padding: @gap;
    transition: all .3s linear;

    &__header {
      cursor: pointer;
      padding-right: 100px;

      .acc-btn { //&::after
        position: absolute;
        text-align: right;
        top: 28px;
        right: 16px;
        transform: translateY(-50%);
        width: 50px;
        height: 30px;
        color: @color_b2;
        border: 1px solid @color_b4;
        font-family: @font;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        font-size: 11px;
        border-radius: @br;
        background: @color_b5 + 5%;
        box-shadow: inset 0 1px 0 0 @color_w;
      }
    }

    &__list {
      padding-top: 0;
      transition: all .1s linear;
      transform-origin: 0 0;
      transform: scaleY(0);
      max-height: 0;
      opacity: 0;
    }

    &.active {
      .sidebar__box__header {
        .acc-btn::after {
          content: '\e900';
          font-family: @icons;
          font-size: 9px;
          color: @color_b3;
        }
      }

      .sidebar__box__list {
        padding-top: 10px;
        transform: scaleY(1);
        max-height: 8000px;
        opacity: 1;
      }
    }

    &.focus {
      box-shadow: 0 7px 35px -2px rgba(0, 0, 0, .09), inset 0 0 0 2px rgba(@color_b1, 1) !important;
    }
  }
}

.transit-box {
  position: relative;

  &__header {

    h3 {
      display: flex;
      font: @f_reg 16px/110% @font;
      position: relative;
      padding-left: 16px;
      margin-bottom: 4px;

      &::before {
        content: '\e902';
        position: absolute;
        top: 2px;
        left: -3px;
        font-family: @icons;
        color: @color_b2;
      }
    }

    h4 {
      font: @f_reg 11px/110% @font;
    }

    p {
      font: @f_reg 11px/110% @font;
    }

    .schedule {
      position: absolute;
      top: 13px;
      right: 70px;
      width: 30px;
      height: 30px;
      text-decoration: none;

      border: 1px solid @color_b4;
      font-family: @font;
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
      font-size: 11px;
      border-radius: @br;
      background: @color_b5 + 5%;
      box-shadow: inset 0 1px 0 0 @color_w;

      &::after {
        content: '\e906';
        font-size: 15px;
        color: @color_b3 + 10%;
        font-family: @icons;
      }
    }

    .acc-btn::after {
      content: 'stops'; // \e904
    }
  }

  &__list {
    &__item {
      padding: 8px 8px 8px 13px;
      font: @f_reg 13px/110% @font;
      position: relative;
      display: flex;

      &::after {
        content: '';
        width: 2px;
        height: 100%;
        background: @color_b3;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        display: block;
      }

      &:last-of-type::after {
        height: 15px;
      }

      &::before {
        content: '';
        display: block;
        background: @color_w;
        border: 2px solid @color_b3;
        position: absolute;
        z-index: 2;
        left: 1px;
        top: 15px;
        transform: translate(-50%, -50%);
        border-radius: 100%;
        width: 9px;
        height: 9px;
      }

      &.active::before {
        width: 11px;
        height: 11px;
        border-color: @color_b1;
      }

      p {
        padding-right: 6px;
        flex: 1 1 auto;
      }

      .schedule {
        position: relative;
        top: -2px;
        width: 24px;
        height: 24px;
        text-decoration: none;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;

        &::after {
          content: '\e906';
          font-size: 15px;
          color: @color_b3 + 10%;
          font-family: @icons;
        }
      }
    }
  }

  &.active {
    .transit-box__header {
      h3::before {
        color: @color_2;
      }
    }

    .transit-box__list {
      .transit-box__list__item::after {
        background: @color_2;
      }
    }
  }
}

.system-box {
  position: relative;

  &__header {

    h3 {
      display: flex;
      font: @f_reg 16px/110% @font;
      position: relative;
      padding-left: 16px;
      margin-bottom: 4px;

      &::before {
        content: '\e907';
        position: absolute;
        top: 1px;
        left: -3px;
        font-family: @icons;
        color: @color_b2;
        font-size: 13px;
      }
    }

    h4 {
      font: @f_reg 11px/110% @font;
    }

    p {
      font: @f_reg 11px/110% @font;
    }

    .info-button {
      position: absolute;
      top: 13px;
      right: 70px;
      width: 30px;
      height: 30px;
      text-decoration: none;

      border: 1px solid @color_b4;
      font-family: @font;
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
      font-size: 11px;
      border-radius: @br;
      background: @color_b5 + 5%;
      box-shadow: inset 0 1px 0 0 @color_w;

      &::after {
        content: '\e010';
        font-size: 15px;
        color: @color_b3 + 10%;
        font-family: @icons-2;
      }
    }

    .acc-btn::after {
      content: 'routs'; // \e904
    }
  }

  &__list {
    &__item {
      padding: 8px 20px 8px 19px;
      font: @f_reg 11px/110% @font;
      cursor: pointer;
      position: relative;
      border: 1px solid @color_b5;
      margin-bottom: 3px;
      border-radius: @br;
      transition: all .2s linear;
      box-shadow: 0 2px 6px 0 rgba(@color_b2, .08);
      background: @color_w;

      &::before {
        content: '\e902';
        display: block;
        font-family: @icons;
        color: @color_b3;
        font-size: 13px;
        position: absolute;
        z-index: 2;
        left: 9px;
        top: 13px;
        transform: translate(-50%, -50%);
        border-radius: 100%;
        width: 9px;
        height: 9px;

      }

      &::after {
        content: '\e901';
        display: block;
        font-family: @icons;
        color: @color_b3;
        font-size: 10px;
        position: absolute;
        z-index: 2;
        right: 6px;
        top: 13px;
        transform: translate(-50%, -50%);
        border-radius: 100%;
        width: 9px;
        height: 9px;
      }
    }
  }

  &.active {
    .system-box__header {
      h3::before {
        color: @color_2;
      }
    }
  }
}

.transit-stop-box {
  position: relative;

  &__header {
    margin-bottom: 13px !important;
    padding-right: 90px;

    h3 {
      display: flex;
      font: @f_reg 16px/110% @font;
      position: relative;
      padding-left: 16px;
      margin-bottom: 4px;

      &::before {
        content: '\e905';
        position: absolute;
        font-size: 13px;
        top: 2px;
        left: -3px;
        font-family: @icons;
        color: @color_b2;
      }
    }

    h4 {
      font: @f_reg 11px/110% @font;
    }

    p {
      font: @f_reg 11px/110% @font;
    }

    .schedule {
      position: absolute;
      top: 13px;
      right: 70px;
      width: 30px;
      height: 30px;
      text-decoration: none;

      border: 1px solid @color_b4;
      font-family: @font;
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
      font-size: 11px;
      border-radius: @br;
      background: @color_b5 + 5%;
      box-shadow: inset 0 1px 0 0 @color_w;

      &::after {
        content: '\e906';
        font-size: 15px;
        color: @color_b3 + 10%;
        font-family: @icons;
      }
    }

    .acc-btn::after {
      content: 'routes'; // \e904
    }
  }

  &__list {
    &__item {
      padding: 8px 20px 8px 19px;
      font: @f_reg 11px/110% @font;
      cursor: pointer;
      position: relative;
      border: 1px solid @color_b5;
      margin-bottom: 3px;
      border-radius: @br;
      transition: all .2s linear;
      box-shadow: 0 2px 6px 0 rgba(@color_b2, .08);
      background: @color_w;

      &::before {
        content: '\e902';
        display: block;
        font-family: @icons;
        color: @color_b3;
        font-size: 13px;
        position: absolute;
        z-index: 2;
        left: 9px;
        top: 13px;
        transform: translate(-50%, -50%);
        border-radius: 100%;
        width: 9px;
        height: 9px;

      }

      &::after {
        content: '\e901';
        display: block;
        font-family: @icons;
        color: @color_b3;
        font-size: 10px;
        position: absolute;
        z-index: 2;
        right: 6px;
        top: 13px;
        transform: translate(-50%, -50%);
        border-radius: 100%;
        width: 9px;
        height: 9px;
      }
    }
  }
}

.back-btn {
  display: inline-flex;
  padding: 6px 10px 8px;
  border: 1px solid @color_b4;
  border-radius: @br;
  cursor: pointer;
  transition: all .2s linear;
  align-content: center;
  align-items: center;
  justify-content: center;
  user-select: none !important;
}


.operators-list{
  border-radius: @br*2;
  background: @color_w;
  z-index: 2;
  box-shadow: @shadow;
  margin-bottom: @gap /3;
  padding: @gap;
  position: relative;
  transition: all .3s linear;

  &__header{
    margin-bottom: @gap;

    h3 {
      display: flex;
      font: @f_reg 16px/110% @font;
      position: relative;
      padding-left: 16px;
      margin-bottom: 4px;

      &::before {
        content: '\e907';
        position: absolute;
        top: 1px;
        left: -3px;
        font-family: @icons;
        color: @color_b2;
        font-size: 13px;
      }
    }
  }

  &__list{
    &__item{
      padding: 8px 20px 10px 12px;
      font: @f_reg 15px/110% @font;
      cursor: pointer;
      position: relative;
      border: 1px solid @color_b5;
      margin-bottom: 3px;
      border-radius: @br;
      transition: all .2s linear;
      box-shadow: 0 2px 6px 0 rgba(@color_b2, .08);
      background: @color_w;
      display: flex;
      justify-content: flex-start; // align X
      align-items: center; // align Y
      align-content: center ; // align Y ios
      text-decoration: none;
      color: @color_b1;

      a{
        font: @f_reg 15px/108% @font;
        text-decoration: none;
        color: @color_b1;
      }


      &::before {
        content: '\e905';
        display: none;
        font-family: @icons;
        color: @color_b3;
        font-size: 8px;
        position: absolute;
        z-index: 2;
        left: 10px;
        top: 13px;
        transform: translate(-50%, -50%);
        border-radius: 100%;
        width: 9px;
        height: 9px;

      }

      &::after {
        content: '\e901';
        display: block;
        font-family: @icons;
        color: @color_b3;
        font-size: 10px;
        position: absolute;
        z-index: 2;
        right: 6px;
        top: 13px;
        transform: translate(-50%, -50%);
        border-radius: 100%;
        width: 9px;
        height: 9px;
      }
    }
  }

}


body:not(.mobile) {
  .transit-stop-box {
    &__header {
      .schedule {
        &:hover {
          &::after {
            color: @color_2;
          }
        }
      }
    }

    &__list {
      &__item {
        padding: 8px 20px 8px 19px;
        font: @f_reg 11px/110% @font;
        cursor: pointer;
        position: relative;
        border: 1px solid @color_b5;
        margin-bottom: 3px;
        border-radius: @br;
        transition: all .2s linear;
        box-shadow: 0 2px 6px 0 rgba(@color_b2, .08);
        background: @color_w;

        &::before {
          content: '\e902';
          display: block;
          font-family: @icons;
          color: @color_b3;
          font-size: 13px;
          position: absolute;
          z-index: 2;
          left: 9px;
          top: 13px;
          transform: translate(-50%, -50%);
          border-radius: 100%;
          width: 9px;
          height: 9px;

        }

        &::after {
          content: '\e901';
          display: block;
          font-family: @icons;
          color: @color_b3;
          font-size: 10px;
          position: absolute;
          z-index: 2;
          right: 6px;
          top: 13px;
          transform: translate(-50%, -50%);
          border-radius: 100%;
          width: 9px;
          height: 9px;
        }
      }
    }
  }

  .system-box{
    &__header {
      .info-button {
        &:hover {
          &::after {
            color: @color_2;
          }
        }
      }
    }
  }
  .transit-box {
    &__header {
      .schedule {
        &:hover {
          &::after {
            color: @color_2;
          }
        }
      }
    }
    &__list{
      &__item{
        p {
          &:hover {
            color: @color_2;
            cursor: pointer;
          }
        }
        .schedule {
          &:hover {
            &::after {
              color: @color_2;
            }
          }
        }
      }
    }
  }


  .back-btn:hover,
  .sidebar-container .sidebar__tabs > *:not(.active):hover {
    background: @color_2;
    color: @color_w;
    border-color: @color_2;

    &::before {
      color: @color_w;
    }
  }

  .transit-stop-box__list__item:hover,
  .system-box__list__item:hover,
  .operators-list__list__item:hover {
    border-color: @color_b3;
    color: @color_b1;

    &::after {
      color: @color_b2;
    }
  }
}

// ---------------------------------- FILTERS
.filters {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
  width: 100%;
  max-width: 420px;
  box-shadow: @shadow;
  background: @color_w;
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  transition: all .3s ease-in;
  transform: translate(0, 0);

  @media only screen and (max-width: 640px), only screen and (max-device-width: 640px) {
    max-width: none;
  }

  &:not(.active) {
    transform: translateX(100%);
    opacity: 0;
    visibility: hidden;

    @media only screen and (max-width: 640px), only screen and (max-device-width: 640px) {
      transform: translateY(100%);
      opacity: 0;
      visibility: hidden;
      box-shadow: 0 0 3000px 3000px rgba(0, 0, 0, 0.8);
    }
  }

  &__header {
    padding: @gap @gap 6px @gap;
    margin-bottom: -@gap;

    .filters__headline {
      font: @f_reg 22px/120% @font;
      margin-bottom: @gap;
    }

    .filters__tabs {
      display: flex;

      > *:not(:last-child) {
        margin-right: 8px;
      }

      > * {
        display: inline-flex;
        padding: 6px 13px 8px;
        border: 1px solid @color_b4;
        border-radius: @br;
        cursor: pointer;
        transition: all .2s linear;
        align-content: center;
        align-items: center;
        justify-content: center;
        user-select: none !important;
        margin-bottom: @gap;
        font-size: 13px;

        &.active {
          border-color: @color_2;
          box-shadow: 0 0 0 2px @color_2;
        }

      }

      .close-btn {

        &::before {
          content: "\39";
          font-size: 15px;
          position: relative;
          color: @color_b2;
          font-family: "dripicons-v2";
          margin-right: 6px;
          transition: all .1s linear;
        }
      }

      .reset-btn {
        &::before {
          content: "\5a";
          font-family: 'dripicons-v2';
          font-size: 15px;
          margin-right: 6px;
          color: @color_b2;
        }
      }

      .apply-btn {
        background: @color_2;
        color: @color_w;
        border: none;
        margin-left: auto;
        transition: all .2s linear;
        position: relative;

        &::after {
          content: "\53";
          font-family: 'dripicons-v2';
          font-size: 15px;
          margin-left: 6px;
        }

        &::before {
          content: '';
          width: 0;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: @color_w;
          background: -moz-linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
          background: -webkit-linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
          background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
          opacity: 0.3;
          z-index: 0;

        }

        &.loader-btn {
          pointer-events: none;
          background: @color_b3;

          &::before {
            animation: btn-loader 4s ease-in infinite;
          }
        }
      }
    }
  }

  &__wrap {
    width: 100%;
    height: 100%;
    padding: @gap;
    overflow: hidden;
    overflow-y: auto;

    // form
    &-box {
      width: 100%;

      &__headline {
        display: flex;
        width: 100%;
        justify-content: space-between;
        background: @color_b5;
        padding: 10px 10px;
        margin-bottom: @gap;

        .box-name {
          display: flex;
          align-content: center;
          align-items: center;
          font: @f_sbold 20px/120% @font;

          > span {
            font-size: 11px;
            display: flex;
            align-content: center;
            align-items: center;
            justify-content: center;
            height: 20px;
            padding: 3px 12px;
            border-radius: @br;
            background: @color_2;
            color: @color_w;
            margin-left: 6px;
          }
        }

        .checkbox {
          margin-right: 0;
          display: flex;
          justify-content: space-between;

          > span {
            display: flex;
            order: 3;
            position: relative;
            top: 3px;
            margin: 0 0 0 8px !important;
          }
        }
      }


      .check-list {
        display: flex;
        flex-direction: column;
        padding-left: @gap/2;
        padding-right: @gap/2;
        margin-bottom: @gap;

        > * {
          justify-content: space-between;
          display: flex;
          text-align: left;
          width: 100%;
          //border: 1px solid red;
          margin-bottom: @gap;
          font: @f_reg 15px/115% @font;
          margin-right: 0;
          position: relative;

          padding-left: 26px;

          &::before {

            font-family: @icons;
            color: @color_b3;
            position: absolute;
            font-size: 11px;
            top: -2px;
            left: 0;

            width: 22px;
            height: 22px;
            display: flex;
            align-content: center;
            align-items: center;
            justify-content: center;
          }

          > span {
            position: relative;
            order: 3;
            top: 0;
            flex: 0 0 @ch_wh;
            margin: 0 0 0 8px !important;
          }
        }
      }
    }
  }
}

@keyframes btn-loader {
  0%, 100% {
    left: 0;
    width: 0.1%;
  }
  47% {
    width: 100%;
    left: 0;
  }
  70% {
    width: 100%;
    left: 100%;
  }
  99.9% {
    width: 0.1%;
    left: 100%;
  }
}

.filters__tabs, .sidebar__tabs {
  > *:not(.active):active {
    transform: translateY(2px);
  }
}

.filters .filter-box__operators .check-list .checkbox::before {
  content: '\e907';
}

.filters .filter-box__routes .check-list .checkbox::before {
  content: '\e902';
  font-size: 15px;
}

body:not(.mobile) {

  .filters__tabs, .sidebar__tabs {
    > *:not(.active):hover {
      background: @color_2 + 20%;
      color: @color_w;
      border-color: @color_2 + 20%;

      &::before {
        color: @color_w !important;
      }
    }
  }

  .filters .apply-btn:hover {
    background: @color_2 + 20%;

  }
}

// ---------------------------------- MAP
.user-geolocation {
  position: absolute;
  left: 10px;
  top: 10px;
  display: inline-flex;
  background: @color_w;
  box-shadow: @shadow;
  z-index: 10;
  border: 1px solid @color_b5;

  > * {
    display: flex;
    width: auto;
    height: 34px;
    padding: 0 13px;
    align-content: center;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    user-select: none !important;
    cursor: pointer;


    &:not(:last-child) {
      border-right: 1px solid @color_b4;
    }
  }
}

.map-logo {
  position: absolute;
  width: 260px;
  z-index: 10;
  left: 16px;
  top: 16px;

  img {
    width: 100%;
  }

  @media only screen and (max-width: 640px), only screen and (max-device-width: 640px) {
    width: 180px;
  }
}

.header-map {
  position: relative;
  height: 80px;
  background: @color_b5;

  .map-logo {
    top: 50%;
    transform: translateY(-50%);
  }
}

.leaflet-popup-content {
  max-width: 600px;
  width: 400px;
  font-size: 12px;
}

.routes-loader {
  display: none;
  z-index: 15;
  width: 100%;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  height: 22px;
  background: @color_b5;

  > div {
    width: 22%;
    height: inherit;
    background: @color_2;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    color: @color_w;
    font: @f_sbold 13px/120% @font;
  }

}


// ---------------------------------- OPERATOR INFO
.operator-info-wrap {
  display: flex;
  margin-bottom: @gap*2;

  @media only screen and (max-width: 960px), only screen and (max-device-width: 960px) {
    flex-direction: column;

  }
}

.operator-main-description {
  flex: 0 0 450px;
  padding-right: @gap;

  @media only screen and (max-width: 1239px), only screen and (max-device-width: 1239px) {
    flex: 0 0 280px;
  }
  @media only screen and (max-width: 960px), only screen and (max-device-width: 960px) {
    padding-right: 0;
    flex: 1 1 auto;
    display: flex;
    margin-left: -@gap;
    margin-right: -@gap;

    > * {
      width: 50%;
      padding-left: @gap;
      padding-right: @gap;

    }
  }

  @media only screen and (max-width: 480px), only screen and (max-device-width: 480px) {
    flex-direction: column;

    > * {
      width: 100%;
    }
  }


  &__image {
    width: 100%;
    height: auto;
    margin-bottom: @gap;

    .img-wrap {
      position: relative;
      padding-bottom: 66%;
      width: 100%;
      height: auto;

      img {
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        font-family: 'object-fit: cover;';
      }
    }


  }

  &__info {
    p {
      padding: 0;
      margin-bottom: 0;
    }

    a {
      color: @color_b1;
      text-decoration: none;
    }

    > * {
      padding: 4px 0;
      margin-bottom: 2px !important;
      position: relative;
      display: flex;

      &::before {
        content: '';
        top: 2px;
        display: flex;
        font-family: @icons-2 !important;
        color: @color_1;
        margin-right: 6px;
      }
    }

    &__address {
      &::before {
        content: "\e012";
      }
    }

    &__tel {
      &::before {
        content: "\24";
      }
    }

    &__site {
      &::before {
        content: "\e064";
      }
    }
  }
}

.operator-map {
  flex: 1 1 auto;

  > * {
    width: 100%;
    height: 60vh;
    min-height: 320px;
    max-height: 780px;
  }
}

body:not(.mobile) {
  .operator-main-description {
    &__info {
      a:hover {
        color: @color_1;
        text-decoration: underline;
      }
    }
  }

  .map-crumbs {
    > * {
      &:hover {
        span {
          color: @color_4;
        }
      }
    }
  }
  .crumbs {
    > a {
      &:hover {
        color: @color_1 + 10%;
      }
    }
  }
}


// ---------------------------------- CRUMBS
.crumbs {
  display: flex;
  min-width: 100%;
  overflow: hidden;
  overflow-x: auto;

  > * {
    padding-right: 26px;
    height: 40px;
    display: inline-flex;
    white-space: nowrap;
    align-content: center;
    align-items: center;
    position: relative;
    color: @color_b1;
    font: @f_reg 15px/105% @font;


    &:not(:last-child) {
      &::after {
        content: "\e903";
        position: absolute;
        top: 50%;
        right: 6px;
        font-family: @icons;
        transform: translateY(-50%) rotate(-180deg);
        font-size: 9px;
        color: @color_b3 !important;
      }
    }
  }
}

.map-crumbs {
  display: flex;
  min-width: 100%;
  overflow: hidden;
  //overflow-x: auto;
  margin-bottom: @gap - 4px;
  flex-direction: column;

  &__links{
    display: flex;
    white-space: nowrap;
    margin-bottom: 8px;

    > div{
      display: flex;
      justify-content: flex-start; // align X
      align-items: center ; // align Y
      align-content: center; // align Y ios
      padding-right: 8px;
      position: relative;
      transition: all .2s linear;

      &:not(:last-child){
        &::after {
          content: "\56";
          position: absolute;
          top: 50%;
          right: 4px;
          font-family: @icons-2;
          transform: translateY(-50%);
          font-size: 9px;
          color: @color_b3 !important;
        }
      }

      &:last-child{
        overflow: hidden;
        max-width: calc(100% - 100px);
        min-width: calc(100% - 100px);

        a{
          width: 100%;
        }

        &:hover{
          &::after{
            display: none;
          }

          a{
            display: block;
            position: absolute;
            width: auto !important;
            max-width: none;
            animation: moveText 12s linear infinite;
          }
        }
      }
    }

    a {
      display: block;
      position: relative;
      color: @color_b1;
      //text-decoration: none;
      font: @f_reg 13px/120% @font;
      margin-right: 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  h1{
    line-height: 120%;
  }
}
@keyframes moveText {
  0%, 95%, 100%{
    transform: translateX(0%);
    left: 0%;
  }
  5%{
    transform: translateX(0%);
    left: 0%;
  }
  45%, 55% {
    transform: translateX(-100%);
    left: 100%;
  }
}


.test-markers {
  left: 0;
  top: 120px;
  background: @color_w;
  color: @color_b1;
  display: inline-flex;
  z-index: 3;
  border: 1px solid red;
  flex-direction: column;
  position: fixed;

  > * {
    padding: 2px 9px;
    cursor: pointer;
    font-size: 13px;

    &.focus {
      color: @color_3;
    }
  }
}

.main-container .map-container input.leaflet-control {
  border: none !important;
  box-shadow: inset 0 0 0 1px rgba(@color_b1, .5) !important;
  background-position: 50% 50% !important;
  border-radius: @br/2 !important;
}

.map-container{

  .leaflet-bottom.leaflet-left{
    bottom: 54px;

  }
  .leaflet-bottom.leaflet-right{
    bottom: 54px;
  }

  .leaflet-bottom.leaflet-right input.leaflet-control,
  .leaflet-control-zoom.leaflet-bar.leaflet-control a.leaflet-control-zoom-in,
  .leaflet-control-zoom.leaflet-bar.leaflet-control a.leaflet-control-zoom-out{
    width: 36px !important;
    height: 36px !important;
    display: flex !important;
    position: relative !important;
    box-shadow: 0 0 0 1px rgba(@color_b1, .5) !important;
    color: @color_w !important;
  }
  .leaflet-bottom.leaflet-right input.leaflet-control{
    background-repeat: no-repeat !important;
    background-size: 26px 26px !important;
    background-position: 50% 50% !important;
  }
  .leaflet-control-zoom.leaflet-bar.leaflet-control a.leaflet-control-zoom-in,
  .leaflet-control-zoom.leaflet-bar.leaflet-control a.leaflet-control-zoom-out{
    text-indent: 50000px !important;

    &::before{
      width: 26px !important;
      height: 26px !important;
      position: absolute;
      font-family: 'dripicons-v2' !important;
      font-size: 16px;
      display: flex !important;
      justify-content: center !important; // align X
      align-items: center  !important; // align Y
      align-content: center  !important; // align Y ios
      padding: 0 !important;
      text-indent: 0 !important;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: @color_b1 !important;
    }
  }
  .leaflet-control-zoom.leaflet-bar.leaflet-control a.leaflet-control-zoom-in{
    border-radius: 4px 4px 0 0 !important;
    &::before{
      content:'\e035';
    }
  }
  .leaflet-control-zoom.leaflet-bar.leaflet-control a.leaflet-control-zoom-out{
    border-radius: 0 0 4px 4px !important;
    top: -1px !important;
    &::before{
      content:'\e024';
    }
  }

}

.main-container .map-container .leaflet-control-zoom.leaflet-bar.leaflet-control {
  border: none !important;
}

@media only screen and (max-width: 640px), only screen and (max-device-width: 640px) {
  .main-container .map-container .leaflet-bottom.leaflet-right {
    top: 10px;
    right: 0;
    bottom: auto !important;
    left: auto !important;
    height: 100vh !important;
    height: -webkit-fill-available !important;
  }

  .main-container .map-container .leaflet-bottom.leaflet-right .leaflet-control-attribution.leaflet-control {
    position: absolute;
    top: auto;
    bottom: 390px !important;
    width: 100vw;
    left: auto;
    right: 0;
  }
}

.contact-us-map {
  display: block;
  width: 100%;
  height: 50vh;
  min-height: 200px;
  max-height: 600px;
  background: @color_b5;
  z-index: 2;
}

.help-btn{
  display: flex;
  justify-content: center; // align X
  align-items: center; // align Y
  align-content: center; // align Y ios
  background: @color_1;
  color: @color_w;
  padding: 6px 12px;
  min-width: 46px;
  min-height: 46px;
  border-radius: 0 @br @br 0;
  position: fixed;
  bottom: 10%;
  left: 0;
  z-index: 5;
  text-decoration: none;
  box-shadow: @shadow;
  animation:  slowJump 10s ease-in-out infinite;
  
  > div{
    margin-left: 0;
    max-width: 1px;
    opacity: 0;
    transition: all .18s linear;
    font: @f_sbold 13px/120% @font;
  }

  &::before{
    content: "\e009";
    font-family: "dripicons-v2";
    color: @color_w;
    animation: bell 9s linear infinite;
    border-radius: 100%;
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios
    position: relative;
    z-index: 3;
  }

  &::after{
    content: '';
    display: flex;
    width: 20px;
    height: 20px;
    position: absolute;
    left: 13px;
    background: @color_1;
    border-radius: 100%;
    top: 45%;
    transform: scale(1);
    transform-origin: center center !important;
    z-index: 2;
    animation: miganie 12s linear infinite;
    pointer-events: none !important;
  }

  &:hover{

    > div{
      margin-left: 6px;
      max-width: 180px;
      opacity: 1;
      color: @color_w !important;
    }
  }
}

@keyframes miganie {
  0%, 26%, 100% {
    transform: scale(1);
    opacity: 0;
  }
  27% {
    transform: scale(15);
    opacity: .25;
  }
  28% {
    opacity: .13;
  }
  29% {
    transform: scale(30);
    opacity: 0;
  }

}
@keyframes bell {
  0%, 5%, 100%{
    transform: scale(1) rotate(0);
  }
  6%{
    transform: scale(1.2) rotate(-16deg);
  }
  7%{
    transform: scale(1.4) rotate(16deg);
  }
  8%{
    transform: scale(1.8) rotate(-13deg);
  }
  9%{
    transform: scale(1.6) rotate(10deg);
  }
  10%{
    transform: scale(1.4) rotate(-9deg);
  }
  11%{
    transform: scale(1.0) rotate(0);
  }
  13%{
    transform: scale(1.0) rotate(0);
  }
  14%{
    transform: scale(1.2) rotate(-16deg);
  }
  15%{
    transform: scale(1.4) rotate(16deg);
  }
  15.5%{
    transform: scale(1.8) rotate(-13deg);
  }
  16%{
    transform: scale(1.6) rotate(10deg);
  }
  16.5%{
    transform: scale(1.4) rotate(-9deg);
  }
  17%{
    transform: scale(1.0) rotate(0);
  }
  18%{
    transform: scale(1.0) rotate(0);
  }
}
@keyframes slowJump {
  0%, 20%, 40%, 100%{
    transform: translateY(0);
    box-shadow: -2px 8px 10px -2px rgba(0,0,0,.4);
  }
  30%{
    transform: translateY(-50%);
    box-shadow: -2px 20px 14px -2px rgba(0,0,0,.25);
  }
}


