// ---------------------------------- 404
.section__error {
  display: flex;
  height: 33.33vh;
  min-height: 400px;
  max-height: 640px;
  justify-content: center; // align X
  align-items: center; // align Y
  align-content: center; // align Y ios
  flex-direction: column;
  padding: @gap*2 @gap !important;
  background: url("@{img}video-bg.jpg") 50% 50% no-repeat;
  background-size: cover !important;

  > * {
    position: relative;
    z-index: 3;
  }

  .section__error__number {
    font-size: 120px;
    color: @color_1;
    font-weight: @f_bold !important;
    line-height: 120%;
    height: 150px;
    display: flex;
    text-align: center;
  }

  .error__details {
    display: inline-block;
    text-align: center;
    color: @color_b1;
  }

}



