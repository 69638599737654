.footer{
  width: 100%;
  position: relative;
  z-index: 3;
  background: @color_b1;

  .logo-copy{
    display: flex;
    justify-content: flex-start; // align X
    align-items: center ; // align Y
    align-content: center; // align Y ios
  }

  &__logo{
    margin-right: @gap;
    display: flex;
    justify-content: flex-start; // align X
    align-items: flex-start; // align Y
    align-content: flex-start; // align Y ios

    a{
      display: flex;
      justify-content: flex-start; // align X
      align-items: flex-start; // align Y
      align-content: flex-start; // align Y ios
      height: 34px;

      img{
        height: 30px;
        //filter: brightness(200%) saturate(0%) opacity(40%);
      }

      @media only screen and (max-width: 920px),
      only screen and (max-device-width: 920px) {
        height: 26px;

        img{
          height: 22px;
        }
      }
      @media only screen and (max-width: 540px),
      only screen and (max-device-width: 540px) {
        max-width: 20px;
        overflow: hidden;
      }
    }
  }

  &__copyright{
    color: @color_b2 + 20%;
    font-size: 12px;
    position: absolute;
    top: 13px;
    left: 50%;
    transform: translateX(-50%);

    @media only screen and (max-width: 920px),
    only screen and (max-device-width: 920px) {
      top: 10px;
      order: 99;
      margin-bottom: 0 !important;
    }

  }

  &__nav{
    display: inline-flex;
    align-content: center;
    align-items: center;
    justify-content: flex-start;

    @media only screen and (max-width: 920px),
    only screen and (max-device-width: 920px) {
      width: 100%;
      padding-top: 0;
      flex-direction: column;
      display: flex;
      max-height: 0;
      opacity: 0;
      visibility: hidden;
      transition: all .2s linear;
      position: relative;

      &::after{
        content: '';
        position: absolute;
        top: 8px;
        width: 100%;
        height: 1px;
        background: @color_b2;
      }
    }

    a{
      text-decoration: none;
      color: @color_b2 + 20% ;
      font: @f_reg 11px/110% @font;
      margin-left: @gap ;
      padding: 10px 0;

      @media only screen and (max-width: 920px),
      only screen and (max-device-width: 920px) {
        font: @f_reg 13px/120% @font;
        padding-left: 0;
        margin: 0 10px;
      }

      &:hover{
        text-decoration: underline;
        color: @color_b3;
      }
    }
  }

  .resolution-wrap{
    align-content: center;
    align-items: center;
    display: flex;
    padding-top: @gap/2;
    padding-bottom: @gap/2;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .burger-btn{
    position: absolute;
    right: 16px;
    top: 10px;
    color: @color_b3;
    display: none;
    justify-content: center; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios
    text-transform: uppercase;
    font-size: 11px;


    &::before{
      content: "\e020";
      color: @color_b3;
      margin-right: 6px;
      font-size: 13px;
      font-family: @icons-2;
      font-style: normal !important;
      font-weight: normal !important;
      font-variant: normal !important;
      text-transform: none !important;
      speak: none;
      line-height: 1;
    }
  }

  @media only screen and (max-width: 920px),
  only screen and (max-device-width: 920px) {
    .burger-btn{
      display: flex;

      &.active::before{
        content: "\39";
      }
    }
    .burger-btn.active ~ .footer__nav{
      max-height: 600px;
      opacity: 1;
      visibility: visible;
      padding-top: 24px;

    }
  }

}

.map-wrap .footer{
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 14;
}

body:not(.mobile){
  .burger-btn:hover{
    color: @color_w;
    cursor: pointer;

    &::before{
      color: @color_w;
    }
  }
}