// WELCOME

.section-welcome{
  display: flex;
  min-height: 520px;
  max-height: 860px;
  height: 75vh;
  background: url("@{img}welcome-bg.jpg") 50% 90% no-repeat;
  background-size: cover !important;
  position: relative;

  .form-search__wrap{
    width: 100%;
    max-width: 960px;
  }

  .btn_search{
    display: none !important;
    pointer-events: none !important;
  }

  &__title{
    margin-bottom: @gap * 2 ;
    position: relative;
    z-index: 1;

    h1{
      font-size: 60px;
      color: @color_w;
      text-align: center;
      line-height: 110%;
      text-transform: uppercase;
    }
  }

  &::after{
    content: '';
    display: block;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0, .55);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  &__form{
    position: relative;
    z-index: 1;
    margin-bottom: @gap;
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;

    input, textarea, select {
      &:focus:placeholder-shown,
      &:not(:placeholder-shown) {
        & ~ label.label_bottom {
          color: @color_w !important;
        }
      }
    }
  }

  &__anchor{
    position: relative;
    flex-direction: column;
    z-index: 1;
    color: @color_w;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;

    > div{
      margin-bottom: @gap;
    }
  }

}

.c-center{
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.section-state-list {
  padding-top: @gap*3;
  padding-bottom: @gap*3;

  .state-list{
    display: flex;
    flex-wrap: wrap;
    margin-left: -@gap/2;
    margin-right: -@gap/2;

    &__item{
      width: 20%;
      display: flex;
      padding: 0 @gap/2;
      margin-bottom: @gap;
      justify-content: center; // align X
      align-items: center; // align Y
      align-content: center; // align Y ios
      
      

      @media only screen and (max-width: 1190px),
      only screen and (max-device-width: 1190px) {
        width: 25%;
      }

      @media only screen and (max-width: 960px),
      only screen and (max-device-width: 960px) {
        width: 33.33%;
      }

      @media only screen and (max-width: 720px),
      only screen and (max-device-width: 720px) {
        width: 50%;
      }

      @media only screen and (max-width: 480px),
      only screen and (max-device-width: 480px) {
        width: 100%;
      }

      a{
        background: @color_w;
        display: flex;
        position: relative;
        justify-content: flex-start; // align X
        align-items: center; // align Y
        align-content: center; // align Y ios
        padding: @gap /2 @gap @gap /2 76px;
        min-height: 58px;
        text-decoration: none;
        box-shadow: @shadow;
        border: 1px solid @color_b5;
        border-radius: @br;
        width: 100%;
        color: @color_b1;
        line-height: 110%;

        @media only screen and (max-width: 480px),
        only screen and (max-device-width: 480px) {
          font-size: 17px;
        }

        @media only screen and (max-width: 560px),
        only screen and (max-device-width: 560px) {
          font-size: 15px;
          min-height: 54px;
          padding: @gap /2 @gap /2 @gap /2 72px;
        }

        .state-flag{
          display: flex;
          position: absolute;
          left: @gap/1.3;
          top: @gap/1.3;
          border: 1px solid @color_b4;
          overflow: hidden;
          border-radius: @br/3;
          order: 0;
          width: 54px;
          min-width: 54px;
          max-width: 54px;
          height: 32px;
          filter: grayscale(20%) ;
          opacity: .9;
          transition: all .2s linear;

          @media only screen and (max-width: 560px),
          only screen and (max-device-width: 560px) {
            left: @gap /1.5;
          }

          img{
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            font-family: 'object-fit: cover;';
            left: 0;
            top: 0;

          }

          @media only screen and (max-width: 560px),
          only screen and (max-device-width: 560px) {
            width: 48px;
            min-width: 48px;
            max-width: 48px;
            height: 27px;
          }
        }
      }
    }
  }
}

body:not(.mobile){
  .section-state-list{
    .state-list{
      &__item{
        a{
          &:hover{
            transform: scale(1.05);
            box-shadow: 0 6px 36px 0 rgba(0,0,0, .1);
            z-index: 5;

            .state-flag{
              filter: grayscale(0) ;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

.section-fact-list {
  padding-top: @gap*5;
  padding-bottom: @gap*5;

  .fact-list {
    display: flex;
    margin-left: -@gap;
    margin-right: -@gap;
    flex-wrap: wrap;

    &__item {
      width: 33.33%;
      display: flex;
      flex-direction: column;
      align-content: center;
      align-items: center;
      justify-content: flex-start;
      padding-left: @gap;
      padding-right: @gap;

      
      @media only screen and (max-width: 860px),
      only screen and (max-device-width: 860px) {
        width: 100% !important;

        &:not(:last-child){
          margin-bottom: @gap*2;
        }
      }

      &__text{
        max-width: 360px;
        text-align: center;
        line-height: 113%;
      }

      &__icon {
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        margin-bottom: @gap/2;

        img{
         width: 200px;
        }
      }
    }
  }
}

.padding-top__x2 {
  padding-top: @gap*2;
}

.text-indent{
  padding-left: @gap;
}

.mini-preloader{
  pointer-events: none;
  display: flex;
  width: 100%;
  height: 0.1%;
  min-height: 0;
  opacity: 0;
  visibility: hidden;
  justify-content: center; // align X
  align-items: center; // align Y
  align-content: center; // align Y ios
  transition: all .12s linear;
  padding: 0 !important;
  margin: 0 !important;
  max-height: 0 !important;

  &.active{
    height: 100%;
    min-height: 100px;
    opacity: 1;
    visibility: visible;
    max-height: 8000px !important;
  }

  > div{
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios

    &::after{
      content: "\e011";
      .font-icon;
      font-size: 26px;
      color: @color_b4;
      animation: loader-spin 1s steps(8) infinite;
    }
  }
}
.mini-preloader.active ~ .sidebar__box__list{
  display: none;
}

@keyframes loader-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


.section-country-list{
  .big-banner{
    display: flex;
    width: 100%;
    overflow: hidden;
    position: relative;
    padding-bottom: 30%;
    border-radius: @br;
    margin-bottom: @gap * 2;

    &.display-none{
      display: none !important;

      & ~ *{
        margin-top: 16px;
      }
    }

    img{
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover;';
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
}
.country-list{
  margin-bottom: @gap * 2;
  display: flex;
  flex-wrap: wrap;
  margin-left: -@gap/2;
  margin-right: -@gap/2;

  &__item{
    width: 25%;
    display: flex;
    padding: 0 @gap/2;
    margin-bottom: @gap;

    @media only screen and (max-width: 960px),
    only screen and (max-device-width: 960px) {
      width: 33.33%;
    }

    @media only screen and (max-width: 720px),
    only screen and (max-device-width: 720px) {
      width: 50%;
    }

    @media only screen and (max-width: 480px),
    only screen and (max-device-width: 480px) {
      width: 100%;
    }

    &__box{
      background: @color_w;
      display: flex;
      flex-direction: column;
      position: relative;
      text-decoration: none;
      box-shadow: @shadow;
      border: 1px solid @color_b5;
      border-radius: @br;
      width: 100%;
      color: @color_b1;
      line-height: 113%;
      transition: all .12s linear;

      .box-title{
        display: flex;
        justify-content: flex-start; // align X
        align-items: center;  // align Y
        align-content: center; // align Y ios
        font: @f_reg 20px/110% @font;
        padding: 8px 8px 8px 36px;
        min-height: 42px;

        &::before{
          content: '\e902';
          width: 26px;
          height: 26px;
          display: flex;
          position: absolute;
          align-content: center;
          align-items: center;
          justify-content: center;
          font-family: Icons;
          font-size: 20px;
          color: @color_1;
          margin-right: 3px;
          left: 7px;
          top: 8px;
        }
      }

      .box-links{
        display: flex;
        box-shadow: inset 0 1px 0 0 @color_b5;
        background: @color_w;
        border-radius: 0 0 @br @br;
        overflow: hidden;
        margin-top: auto;

        a{
          display: flex;
          padding: 3px 6px;
          text-align: center;
          justify-content: center; // align X
          align-items: center; // align Y
          align-content: center; // align Y ios
          min-height: 36px;
          flex: 1 1 200px;
          font-size: 14px;
          text-decoration: none;
          color: @color_b2;


          &:not(:last-child){
            box-shadow: inset -1px 0 0 0 @color_b5;
          }
        }
      }


/*
      &::after {
        content: "\e903";
        position: absolute;
        top: 50%;
        right: 16px;
        font-family: @icons;
        transform: translateY(-50%) rotate(-180deg);
        font-size: 9px;
        color: @color_b3 !important;
      }

 */
    }
  }
}

.state-county-list{
  margin-bottom: @gap * 2;
  display: flex;
  flex-wrap: wrap;
  margin-left: -@gap/2;
  margin-right: -@gap/2;

  &__item{
    width: 25%;
    display: flex;
    padding: 0 @gap/2;
    margin-bottom: @gap;

    @media only screen and (max-width: 960px),
    only screen and (max-device-width: 960px) {
      width: 33.33%;
    }

    @media only screen and (max-width: 720px),
    only screen and (max-device-width: 720px) {
      width: 50%;
    }

    @media only screen and (max-width: 480px),
    only screen and (max-device-width: 480px) {
      width: 100%;
    }

    &__box{
      background: @color_w;
      display: flex;
      flex-direction: column;
      position: relative;
      text-decoration: none;
      box-shadow: @shadow;
      border: 1px solid @color_b5;
      border-radius: @br;
      width: 100%;
      color: @color_b1;
      line-height: 113%;
      transition: all .12s linear;

      a{
        display: flex;
        justify-content: flex-start; // align X
        align-items: center;  // align Y
        align-content: center; // align Y ios
        font: @f_reg 20px/110% @font;
        padding: 8px 8px 8px 36px;
        min-height: 42px;
        color: @color_b1;
        text-decoration: none;

        &::before{
          content: '\e902';
          width: 26px;
          height: 26px;
          display: flex;
          position: absolute;
          align-content: center;
          align-items: center;
          justify-content: center;
          font-family: Icons;
          font-size: 20px;
          color: @color_1;
          margin-right: 3px;
          left: 7px;
          top: 8px;
        }
      }

    }
  }
}

body:not(.mobile){

  .country-list{
      &__item{
        &__box{
          &:hover{
            transform: scale(1.02);
            box-shadow: 0 6px 36px 0 rgba(0,0,0, .1);
            z-index: 5;

            &::before{
              color: @color_3;
            }
            &::after{
              right: 8px;
            }
          }

          a:hover{
            background: @color_1;
            color: @color_w;
          }
        }
      }
    }
  .state-county-list{
    &__item{
      &__box{
        &:hover{
          transform: scale(1.02);
          box-shadow: 0 6px 36px 0 rgba(0,0,0, .1);
          z-index: 5;
        }
      }
    }
  }
}

// schedule new
.schedule-title {
  display: flex;
  margin-bottom: @gap;
  justify-content: flex-start; // align X
  align-items: center; // align Y
  align-content: center; // align Y ios

  h2{
     font: @f_sbold 26px/120% @font;
  }

  .schedule-quantity {
    display: inline-flex;
    margin-right: auto;
    padding: 6px 12px;
    justify-content: center; // align X
    align-items: center; // align Y
    align-content: center; // align Y ios
    justify-self: auto;
    border-radius: @br;
    margin-left: @gap;
    background: @color_1;
    color: @color_w;
     font: @f_sbold 18px/120% @font;
  }
}
.schedule-table {
  display: table;
  margin-bottom: @gap*2;
  width: 100%;
  border: 1px solid @color_b5;
  border-radius: @br;
  background: @color_w;
  box-shadow: @shadow_2;

  &__item{
    display: table-row;

    &:not(:last-child){
      .schedule-table__item__td {
        box-shadow: inset 0 -1px 0 0 rgba(@color_b4, .6);
      }
    }

    &__td,
    &__th{
      display: table-cell;
      padding: 8px 10px;
      border: 0;
      vertical-align: middle;

      &.route-name{
        width: 50%;

        .long-name{
          margin-bottom: 8px;
        }
        .long-name,
        .short-name{
          >div{
            font: @f_reg 13px/120% @font;
            color: @color_b2;
          }
          >h3{
             font: @f_sbold 16px/120% @font;
          }
        }
      }

      &.stop-name{
        width: 230px;
        font: @f_sbold 16px/120% @font !important;

        > *{
          font: @f_sbold 16px/120% @font !important;
        }
      }

      &.stop-time{
        font: @f_reg 16px/120% @font;

        &:nth-child(2n+2){
          background: @color_b5 + 3%;
        }

        > *{
          font: @f_reg 16px/120% @font;
        }
      }

      &.arrival-time,
      &.departure-time{
        width:25%;

        p{
          display: inline-flex;
          text-align: center !important;
        }
      }

      &:first-child{
        border-radius: @br 0 0 0;
      }

      &:last-child{
        border-radius: 0 @br 0 0;
      }
    }

    &__th{
      background: @color_1;
      color: @color_w;
    }
  }



}


// SEARCH
.ui-menu.ui-widget.ui-widget-content {
  border-radius: @br;
  overflow: hidden;
  border: none !important;
  box-shadow: 0 8px 20px -3px rgba(0,0,0, .23);
  background: white;
  width: calc(100% - @gap*2) !important;
  max-width: 640px !important;
  z-index: 20;

  .ui-widget-content{
    display: none;
  }

  .ui-menu-item{

    box-shadow: inset 0 -1px 0 0 rgba(0,0,0, .05);
    border: none !important;

    > div{
      display: flex;
      padding: 10px 12px;
      font: 300 14px/113% 'Exo2';
      border: none !important;
      min-height: 36px;
      margin: 0 !important;

      &.ui-state-active{
        background: @color_4;
        border: none;
      }
    }
  }
}
.ui-helper-hidden-accessible{
  position: absolute;
  opacity: 0;
  visibility: hidden;
}
.ui-menu-item-wrapper.ui-state-active{
  background: @color_b4 !important;
  cursor: pointer;
}
body div.ui-helper-hidden-accessible{
  position: absolute !important;
  z-index: -50 !important;
  opacity: 0 !important;
  visibility: hidden !important;
}
.search-loading{
  background: url('@{img}loader.gif') no-repeat 50% 50%;
  background-size: 80%;
  opacity: 0;
  display: flex;
  position: absolute !important;
  top:50%;
  transition: all .2s linear;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  z-index: 5;
  pointer-events: none;
  right: 4px;
}

.ui-autocomplete-input.ui-autocomplete-loading ~ .search-loading{
  opacity: 1;
}
.search_box.ui-widget{
  flex-wrap: wrap;
}

.display-none{
  display: none !important;
}



