//============================================================================
//+++++++++++++++++++++++++++++ FORMS RESET +++++++++++++++++++++++++++++++++
//============================================================================
@form_placeholder_color: @color_b3;
@form_bg_color: @color_w;
@form_border: 1px solid @color_b3;
@form_text_color: @color_b1;
@form_a_border: 2px solid @color_2;
@form_font: @font_7;
@form_error_text: @color_5;

@form_icns_n: @color_b3;
@form_icns_a: @color_2;

@form_r: 8px;

@formLeftPadding:  @gap*1.2;

@vgap: @gap*3;


//removes the outline of active buttons and forms /
input:not[type=checkbox] { //NO IOS SHADOW AND b_radius
  -webkitw: none;
  -webkit-box-appearance: none;
  box-shado-shadow: none;
  border-radius: 0 !important;
  -webkit-appearance: none;
}

input, textarea {
  outline: none;
}

input:active, textarea:active {
  outline: none;
}

*:focus {
  outline: none;
}

textarea {
  resize: none;
}

textarea {
  resize: vertical;
}

textarea {
  resize: horizontal;
}

button::-moz-focus-inner {
  border: 0;
}

a.active.focus,
a.active:focus,
a.focus,
a:active.focus,
a:active:focus,
a:focus,
button:active,
button.active.focus,
button.active:focus,
button.focus,
button:active.focus,
button:active:focus,
button:focus,
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
  outline: 0 !important;
  outline-color: transparent !important;
  outline-width: 0 !important;
  outline-style: none !important;
  box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);

  button:active, button:focus {
    outline: none !important;
  }

}
*:focus, *:active,
*::-moz-focus-inner {
  outline: none !important;
  border: 0;
  outline: 0 !important;
  outline-offset:0 !important;
}


// ИЗМЕНЕНИЕ ПАРАМЕТРОФ ИНПУТОВ.
// =============================================================
.forms_param() {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: center;

  text-decoration: none;
  width: 100%;
  height: @elements_h;
  padding: 0;
  //line-height: @elements_h !important;
  color: @form_placeholder_color;
  border-radius: @form_r;
}

// СТИЛИ БЛОКА ФОРМЫ
// =============================================================
form.form_style_1 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;

  > * {
    position: relative;
    > * {
      position: relative;
    }
  }
}


// BUTTONS STYLE (если не кнопки поиска)
// =============================================================
.form_style_1 {
  button:not(.btn_search):not(.icn_btn):not(.btn_search2),
  input[type="button"]:not(.btn_search):not(.btn_search2),
  input[type="submit"]:not(.btn_search):not(.btn_search2),
  input[type="reset"]:not(.btn_search):not(.btn_search2) {
    .btn__theme-1;
    transition: all .2s ease-in-out;
  }
}

// HOVER (NOT MOBILE)
body:not(.mobile) {
  .form_style_1 button:not(.btn_search):not(.icn_btn):not(.btn_search2),
  .form_style_1 input[type="button"]:not(.btn_search):not(.btn_search2),
  .form_style_1 input[type="submit"]:not(.btn_search):not(.btn_search2),
  .form_style_1 input[type="reset"]:not(.btn_search):not(.btn_search2) {
    &:hover:not([disabled]) {
      //cursor: pointer;
      //background: @color_1 + 16%;
      //color: @color_w;
    }
  }
}


// All input fields
// =============================================================
.form_main_parameters{
  .forms_param;
  border-width:0 0 1px 0 !important;
  border-bottom: @form_border;
  background: @form_bg_color;
  font: @form_font !important;
  line-height: @lh !important;
  padding: 0 20px 0 @formLeftPadding;
}

.form_style_1 input{
  &:not([type="radio"]):not([type="checkbox"]):not([type="button"]):not([type="submit"]):not([type="reset"]):not([type="color"]):not([type="range"]):not(.input_avatar){
    .form_main_parameters;
    &:focus{
      border: @form_a_border !important;
      font: @form_font;
      color: @form_text_color;
    }
  }
}

.form_style_1 textarea,
.form_style_1 select,
.form_style_1 .file_upload > div,
.form_style_1 .type_color_box,
.form_style_1 .inputfile_6 + label{
  .form_main_parameters;
  &:focus{
    border: @form_a_border !important;
  }

}


// SELECT STANDART <select>
// =============================================================
.form_style_1 .custom_select_container {
  background: @form_bg_color;
  border-width:0 0 1px 0 !important;
  border-bottom: @form_border;
  height: @elements_h;
  display: block;
  width: 100%;
  top: 0;
  border-radius: @form_r;
  z-index: 1;

  select {
    border: none !important;
    background: none;
    display: block;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    padding: 0 40px 2px @formLeftPadding;
    color: @form_placeholder_color;
    font: @form_font;

    &::-ms-expand {
      display: none !important;
    }

    &:focus{
      color: @form_text_color !important;
    }
    &:not(:focus){
      color: @form_placeholder_color !important;
    }
  }

  &::before {
    .centerxy !important;
    content: '\54'; //.dripicons-chevron-down
    width: @elements_h;
    height: @elements_h;
    position: absolute;
    z-index: -1;
    font-size: 20px;
    right: 3px;
    top: 50%;
    transform: translateY(-50%) scale(1);
    color: @form_icns_n;
    .font-icon;
  }

  &.active {
    &::before {
      color: @form_icns_a;
      transform: translateY(-50%) scale(-1);
    }
  }
}
.custom_select_container option {
  height: @elements_h;
  padding-top: 10px;
  padding-bottom: 10px;
  background: @color_b1;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}
body:not(.mobile){
  .form_style_1 .custom-select_container:hover {
    cursor: pointer;

    &::before {
      color: @form_icns_a;
    }
  }
}




// SELECT CUSTOM
// =============================================================
.select_box {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: stretch;
  width: 100%;
  height: @elements_h;
  max-height: @elements_h;
  cursor: pointer;

  &.active {
    > div {
      align-self: start;
      top: 0;
      background: @color_w;
      box-shadow: inset 0 0 1px 0 @color_b5, 0 5px 40px -6px rgba(0, 0, 0, 0.2);

      > *:not(:last-child) {
        .box-shadow(inset 0 -1px 0 0 @color_b5);
      }

      > * {
        &:first-child:after {
          transform: translateY(-50%) scaleY(-1);
        }
      }
    }
  }

  &:not(.active) {
    > div {
      > *:not(:first-child) {
        display: none;
        cursor: pointer;
      }
    }
  }

  * {
    .anim_on(@anim);
  }

  > div {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;

    width: 100%;
    position: relative;
    height: auto;
    z-index: 10;

    > * {
      display: block;
      text-align: left;
      padding: 0 20px 0 8px;
      text-decoration: underline;
      height: 30px;
      font: @font_5;
      color: @color_b1;
      .centery;
      white-space: nowrap;

      &:hover {
        cursor: pointer !important;
      }

      &:first-child:after {
        content: "\54"; //.dripicons-chevron-down
        display: block;
        position: absolute;
        font-size: 11px;
        top: 50%;
        transform: translateY(-50%);
        right: 3px;
        .font-icon;
        color: @color_b4;
        z-index: 0;
      }
    }
  }

}

// SELECT BOX DARK THEME
.select_box.select_box_theme1{

  border-radius: @form_r;
  background: @color_b1;

  &:not(.active) {
    > div {
      > *:not(:first-child) {
        display: none;
      }
    }
  }

  &.active {
    > div {
      align-self: start;
      top: 0;
      background: @color_b1;


      -webkit-box-shadow: 0 5px 40px -6px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0 5px 40px -6px rgba(0, 0, 0, 0.2);
      box-shadow: 0 5px 40px -6px rgba(0, 0, 0, 0.2);

      > * {
        color: @color_w;
      }

      > *:not(:last-child) {
        .box-shadow(inset 0 -1px 0 0 @color_b2);
      }

      > *:not(:first-child):hover {
        background: none;
        color: @color_1;
      }

      > * {
        &:first-child:after {
          transform: translateY(-50%) scaleY(-1);
        }
      }
    }
  }

  * {
    .no_select;
    .anim_on(@anim);
  }

  > div {
    z-index: 10;
    border-radius: @form_r;

    > * {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-content: center;
      align-items: center;

      text-align: left;
      padding: 0 50px 0 20px;
      min-height: @elements_h;
      font: @form_font;
      text-decoration: none;
      color: @color_b5;

      &:first-child:after {
        content: '\54'; //.dripicons-chevron-down
        display: block;
        position: absolute;
        font-size: 20px;
        top: @elements_h / 2;
        right: 25px;
        .font-icon;
        color: @color_b4;
      }
    }
  }
}
body:not(.mobile){
  .select_box.select_box_theme1 {
    &:hover {
      cursor: pointer !important;
    }

    &.active {
      > div {
        > *:not(:first-child):hover {
          background: none;
          color: @color_1;
          cursor: pointer !important;
        }
      }
    }
  }

}

// SELECT BOX LIGHT THEME
.select_box.select_box_theme2{
  border-radius: @form_r;
  background: none;
  border: none;

  &:not(.active) {
    > div {
      > *:not(:first-child) {
        display: none;
      }
    }
  }

  &.active {
    > div {
      align-self: start;
      top: 0;
      background: @form_bg_color;
      .box-shadow(@shadow);

      > * {
        color: @color_b1;
        &:first-child:after{
          color: @form_icns_a;
        }
      }

      > *:not(:last-child) {
        .box-shadow(inset 0 -1px 0 0 @color_b5);
      }

      > *:not(:first-child):hover {
        background: none;
        color: @color_1;
      }

      > * {
        &:first-child:after {
          transform: translateY(-50%) scaleY(-1);
        }
      }
    }
  }

  * {
    .no_select;
    .anim_on(@anim);
  }

  > div {
    z-index: 10;
    border-radius: @form_r;
    background: @form_bg_color;
    border-bottom: @form_border;

    > * {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-content: center;
      align-items: center;

      text-align: left;
      padding: 0 50px 0 20px;
      min-height: @elements_h;
      font: @form_font;
      text-decoration: none;
      color: @form_placeholder_color;

      &:first-child:after {
        content: '\54'; //.dripicons-chevron-down
        display: block;
        position: absolute;
        font-size: 20px;
        top: @elements_h / 2;
        right: 25px;
        .font-icon;
        color: @form_icns_n;
      }
    }
  }
}
body:not(.mobile){
  .select_box.select_box_theme1 {
    &:hover {
      cursor: pointer !important;
    }

    &.active {
      > div {
        > *:not(:first-child):hover {
          background: none;
          color: @color_1;
          cursor: pointer !important;
        }
      }
    }
  }

}



.form_style_1 .type-week,
.form_style_1 .type-date {
  background: none;
}

.form_style_1 .type-week:before,
.form_style_1 .type-date:before {
  content: '\4d'; //.dripicons-calendar
  width: 40px;
  height: 40px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  color: @color_b4;
  .centerxy;
  .font-icon;
}

.form_style_1 .type-week:after,
.form_style_1 .type-date:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  width: 100%;
  height: 40px;
  background: @color_w;
}

.form_style_1 .type-week input,
.form_style_1 .type-date input {
  z-index: 3;
  background: none !important;
}





// FORM PLACEHOLDER and FOCUS animate
// =============================================================
@lh: normal;

.placeholder_style_inputs {
  color: @form_placeholder_color !important;
  opacity: 0;
  transition: opacity 0.3s ease;
  line-height: @lh;
}

.placeholder_style_search {
  //line-height: normal !important;
}

.placeholder_style_textarea {
  color: @form_placeholder_color;
  opacity: 0;
  transition: opacity 0.3s ease;
  line-height: @elements_h - 3px !important;
  // .fs_16;
}

.placeholder_focus {
  opacity: 0;
  transition: opacity 0.3s ease;
  font: @form_font;
}

input::-webkit-input-placeholder {
  .placeholder_style_inputs;
}

textarea::-webkit-input-placeholder {
  .placeholder_style_textarea;
}

input::-moz-placeholder {
  .placeholder_style_inputs;
  line-height: @elements_h - 2;
}

textarea::-moz-placeholder {
  .placeholder_style_textarea;
  line-height: @elements_h - 2;

}

input:-moz-placeholder {
  .placeholder_style_inputs;
}

textarea:-moz-placeholder {
  .placeholder_style_textarea;
}

input:-ms-input-placeholder {
  .placeholder_style_inputs;
}

textarea:-ms-input-placeholder {
  .placeholder_style_textarea;
}

.search_box input::-webkit-input-placeholder {
  .placeholder_style_search;
}

.search_box input::-moz-placeholder {
  .placeholder_style_search;
}

.search_box input:-moz-placeholder {
  .placeholder_style_search;
}

.search_box input:-ms-input-placeholder {
  .placeholder_style_search;
}

input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
  .placeholder_focus;
}

input:focus::-moz-placeholder,
textarea:focus::-moz-placeholder {
  .placeholder_focus;
}

input:focus:-moz-placeholder,
textarea:focus:-moz-placeholder {
  .placeholder_focus;
}

input:focus:-ms-input-placeholder,
textarea:focus:-ms-input-placeholder {
  .placeholder_focus;
}


// CHECKBOX & RADIO CUSTOM
// =============================================================
.checkbox, .radio {
  position: relative;
  cursor: pointer;
  margin-right: 10px;

  * {
    .no_select;
    cursor: pointer !important;
    width: 28px;
    height: 28px;
  }
}
@ch_wh: 18px;
.checkbox::selection, .radio::selection {
  background: transparent;
}

.checkbox input + span, .radio input + span {
  background: @color_w;
  border: 1px solid @color_b4 - 30% !important;
  content: "";
  display: inline-block;
  margin: 0 10px 0 0;
  padding: 0;
  width: @ch_wh;
  height: @ch_wh;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
}

.checkbox input:not(:checked) + span::after, .radio input:not(:checked) + span::after {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform .2s;
}

@media screen and (min-width: 768px) {
  .checkbox:hover input:not[disabled] + span, .radio:hover input:not[disabled] + span {
    box-shadow: inset 0 0 0 2px rgba(15, 185, 177, 0.2);
  }
}

.checkbox input:not[disabled]:active + span, .radio:not[disabled] input:active + span {
  box-shadow: inset 0 0 0 2px rgba(15, 185, 177, 0.2);
}

.checkbox input:focus + span, .radio input:focus + span {
  box-shadow: inset 0 0 0 2px rgba(15, 185, 177, 0.2);
}

.checkbox input:checked + span::after, .radio input:checked + span::after {
  transform: translate(-50%, -50%) scale(1);
}

.checkbox input, .radio input {
  position: absolute;

  opacity: 0;
}

.checkbox input + span {
  border-radius: 3px;
}

.checkbox input + span::after {
  content: '\53'; //.dripicons-checkmark
  .font-icon;
  position: absolute;
  top: 50%;
  left: 50%;
  color: @color_1;
  font-size: 16px;
  width: @ch_wh - 4px;
  height: @ch_wh - 4px;
}

.radio input + span {
  border-radius: 100%;
}

.radio input + span::after {
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  width: @ch_wh / 2;
  height: @ch_wh / 2;
  transform: translate(-50%, -50%) scale(0);
}

.radio input:checked + span::after {
  background: @form_icns_a;
}


.form_style_1 input[type="checkbox"] + .form_err_def {
  display: block;
  background: @color_b1;
  border: 1px solid @color_b2;
  color: @color_b5;
  padding: 10px 13px;
  border-radius: @br !important;
  position: absolute;
  height: auto !important;
  bottom: -35px !important;
  top: auto !important;
  left: -4px !important;

  &:before {
    content: "";
    display: block;
    transform: rotate(-45deg);
    width: 10px;
    height: 10px;
    position: absolute !important;
    top: -6px;
    left: 7px;
    background: @color_w;
    border-top: 1px solid @color_3;
    border-right: 1px solid @color_3;
    z-index: -1;
  }
}


// LABEL TEXT-AREA
// =============================================================
.form_style_1 label {
  z-index: 1;
}

.form_style_1 .textarea_box {
  width: 100%;
  height: auto;
  position: relative;
}

.form_style_1 textarea {
  padding-top: 5px;
  padding-bottom: 14px;
  font: @form_font;
  min-width: 100%;
  max-width: 100%;
  max-height: 100%;
  min-height: 100px;
  resize: none;
  border-radius: @form_r/2;
}


// TYPE FILE LOAD
// =============================================================
// add file (style 6)
.form_style_1 .inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;

  & + label {
    .forms_param;
    background: @color_b5;
    border: 1px solid @color_b5 !important;
    padding: 0 @elements_h+10 0 20px;

    cursor: pointer !important;

    outline: none !important;

    > span {
      overflow: hidden;
      z-index: 2;

      &::after {
        content: 'load file';
      }
    }

    > strong {
      position: absolute;
      top: 0;
      right: 0;
      color: @color_w;
      z-index: 3;
      width: @elements_h;
      height: @elements_h;
      .centerxy;

      &::before {
        content: "\58"; //.dripicons-clipboard
        font-size: 18px;
        color: @color_b5;
        z-index: 5;
        .font-icon;
      }
    }

    &:hover > strong::before {
      color: @color_2;
    }
  }
}

.form_style_1 .no-js .inputfile + label {
  display: none;
}

.form_style_1 .inputfile:focus + label,
.form_style_1 .inputfile.has_focus + label {
  outline: none;
}

.form_style_1 .inputfile:focus + label,
.form_style_1 .inputfile.has-focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}


// Load avatar
// add file (style 7)
.form_style_1 .input_avatar {
  width: 108px !important;
  height: 108px !important;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  .box-shadow(@shadow);

  cursor: pointer;

  & + label {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: stretch;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    width: 108px;
    height: 108px;
    outline: none !important;
    border-radius: 100%;


    background: url("@{img}avatar_bg.png") 50% 50% no-repeat;
    background-size: cover;

    > span {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;
      align-items: center;

      width: 100%;
      height: 100% !important;

      border-radius: 100%;
      overflow: hidden;
      z-index: 2;
      .imgfit;
      background: url("@{img}avatar_bg.png");

    }

    > strong {
      position: absolute;
      bottom: -6px;
      left: 80px;
      z-index: 3;
      width: @elements_h - 10%;
      height: @elements_h - 10%;
      .centerxy;
      background: @color_1;
      border-radius: @form_r;
      .box-shadow(@shadow);

      &::before {
        content: "\58"; //.dripicons-clipboard
        color: @color_w;
        z-index: 5;
        .font-icon;
        font-size: 14px;
      }

    }

    &:hover {

      > strong::before {
        color: @color_w;
      }
    }
  }
}

.form_style_1 .no-js .input_avatar + label {
  display: none;
}

.form_style_1 .input_avatar:focus + label,
.form_style_1 .input_avatar.has_focus + label {
  outline: none;

}

.form_style_1 .input_avatar:focus + label,
.form_style_1 .input_avatar.has-focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.user_profile_avatar, .user_profile_avatar *, .user_profile_avatar input:focus {
  outline: 0 !important;
  outline-color: transparent !important;
  outline-width: 0 !important;
  outline-style: none !important;
  box-shadow: 0 0 0 0 rgba(0, 123, 255, 0) !important;
}


// TYPE SEARCH
// =============================================================
@search_btn_w: @elements_h;

.form_style_1 .search_box {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  position: relative;
  height: @elements_h + 2px;

  > input[type="search"],
  > input[type="text"] {
    flex: 1 1 100%;

    position: relative;
    padding-right:@search_btn_w !important;
  }

  > button,
  > input[type='button'],
  > input[type='submit'] {
    width: @search_btn_w !important;
    height: @elements_h + 2px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    z-index: 2;
    background: none !important;
    padding: 0 !important;
    border: none !important;

    &::before {
      content: '\e041'; //.dripicons-search
      font-size: 18px;
      color: @color_1;
      .font-icon;
    }

    &:hover::before {
      color: @color_2;
    }
  }
}

.form_style_1 input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  width: 30px;
  height: 30px;
  .centerxy;
  margin-right: 0;
  cursor: pointer;
  background: url('@{img}icn_close.svg') 50% 50% no-repeat;
  background-size: 10px auto !important;
  opacity: 0.6;
}

.form_style_1 input[type="search"]::-webkit-search-cancel-button:hover {
  opacity: 1.0;
}


// TYPE NUMBER
// =============================================================
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.quantity {
  position: relative;
  height: @elements_h;

  input {
    width: 45px;
    height: 42px;
    line-height: 1.65;
    float: left;
    display: block;
    padding: 0;
    margin: 0;
    padding-left: 20px;
    border: 1px solid #eee;

    &:focus {
      outline: 0;
    }
  }

  .quantity-nav {
    position: absolute;
    right: 2px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-content: flex-start;
    align-items: stretch;
    height: @elements_h - 4;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;

    .quantity-button {
      position: relative;
      cursor: pointer;
      background: @form_icns_n;
      width: @elements_h - 4;
      .centerxy;
      color: @color_w;
      margin-left: 5px;
      border-radius: @form_r;
      .anim_on(@anim);
      transform: scale(0.8);
      .no_select;

      &:active {
        transform: scale(0.7);
      }

      &.quantity-up {
        &::before{
          content: '\e035'; //.dripicons-plus
          .font-icon;
        }
      }

      &.quantity-down {
        &::before{
          content: '\e024'; //.dripicons-minus
          .font-icon;
        }
      }
    }
  }
}

body:not(.mobile) {
  .quantity-nav {
    .quantity-button {
      &:hover {
        cursor: pointer;
        background-color: @color_1;
        color: @color_w !important;
      }
    }
  }
}


// TYPE COLOR
// =============================================================
.form_style_1 .type_color_box {
  position: relative;
  overflow: hidden;
  text-align: center;
}
.form_style_1 .type_color_box:before {
  content: 'Choice color:';
  height: 100%;
  text-align: left;
  width: auto;
  position: absolute;
  opacity: 0.9;
  border-right: 1px solid @color_b2;
  top: 0;
  left: 0;
  padding: 10px;
  background-color: @color_w;
  display: block;
  z-index: 1;

}
.form_style_1 input[type=color] {
  left: 0;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  display: block;
  position: absolute;
  border: none;
  padding: 0;
  overflow: hidden;
  -webkit-transform: scale(1.5);
  -moz-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -o-transform: scale(1.5);
  transform: scale(1.5);
  cursor: pointer;
}



// ERROR FORM ELEMENT
// =============================================================
.form_style_1 .form_err input:not([type="radio"]):not([type="checkbox"]):not([type="button"]):not([type="submit"]):not([type="reset"]):not([type="color"]),
.form_style_1 .form_err textarea,
.form_style_1 .form_err select,
.form_style_1 .form_err .file_upload > div,
.form_style_1 .form_err .type_color_box,
.form_style_1 .form_err .inputfile_6 + label{
  border-color: @form_error_text;
  background: rgba(231, 76, 60, 0.05) !important;
  //-webkit-box-shadow:   0 0 5px 0 rgba(231, 76, 60, 0.2);
  //-moz-box-shadow:      0 0 5px 0 rgba(231, 76, 60, 0.2);
  //box-shadow:           0 0 5px 0 rgba(231, 76, 60, 0.2);
}

//select[aria-describedby*='-error'],
//input[aria-describedby*='-error']

.custom_select_container > div.err_info {
  position: relative;
  left: 0;
  z-index: 8;
  display: block;
  float: none;
  clear: both;
  width: 100% !important;
}

.vgap > * {
  margin-bottom: @vgap;
  position: relative;
  width: 100%;
}

.form_footer1 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: flex-start;
  align-items: center;

  > * {
    flex-basis: auto;
    margin-bottom: 10px;
  }

  > *:first-child {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;

    > * {
      flex-basis: auto;
      margin-right: 18px;
      margin-bottom: 10px;
      white-space: nowrap;
    }
  }

}

.form_footer2 {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;;
  justify-content: space-between;
  align-content: flex-start;
  align-items: center;
  margin-left: -@gap;
  margin-right: -@gap;
  margin-bottom: 20px;


  > * {
    flex-basis: 50%;
    padding-left: @gap;
    padding-right: @gap;

    > * {
      width: 100%;
    }
  }

}

@media only screen and (max-width: 960px), only screen and (max-device-width: 960px) {
  .form_footer2 {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: stretch;

    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;


    > * {
      flex-basis: 100%;
      padding-left: 0;
      padding-right: 0;
      margin-bottom: 18px;

      > * {
        width: 100%;
      }
    }

  }
}

// PASSWORD VISIBLE \ HIDDEN
.eye {
  .centerxy;
  width: @elements_h;
  height: @elements_h;
  position: absolute;
  z-index: 4;
  right: 0;
  top: 0;
  .anim_on(@anim);

  &::after {
    content: '\e037'; //.dripicons-preview
    .font-icon;
    color: @color_b2;
  }

  &:hover {
    cursor: pointer;
  }

  &:active {
    transform: translateY(1px) scale(0.95);
  }

  &.visible {
    &::after {
      color: @color_1;
    }
  }
}

.login-footer{
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: space-between;

  > *{
    display: flex;
    font-size: 15px !important;

    >*:not(:first-child){
      margin-left: @gap;
    }
  }

  @media only screen and (max-width: 620px),
  only screen and (max-device-width: 620px) {
    flex-direction: column;
    > *{
      margin-bottom: @gap;
    }
  }
}

@dbh: 26px;
.drop_links_box {
  margin: 0 5px !important;
  .no_select;
  font: @font_6;
  z-index: 10;

  &, * {
    //border: 1px solid red;
    .anim_on(0.3s);
  }

  min-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: stretch;
  align-self: flex-start !important;

  > div:nth-child(1) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    position: relative;
    padding-right: 15px;
    height: @dbh;

    > span {
      border-bottom: 1px dashed @color_b2;
    }

    &::after {
      content: '\e04d'; //.dripicons-thumbs-down
      position: absolute;
      top: 40%;
      right: 2px;
      transform: translateY(-50%) scaleY(1);
      .font-icon;
      font-size: 14px;
      color: @color_b4;
    }

    &:hover {
      cursor: pointer !important;
    }
  }

  > div:nth-child(2) {
    opacity: 0;
    visibility: hidden;
    transform: scaleY(0) translateY(0);
    position: absolute;
    top: @dbh;
    transform-origin: 50% 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: stretch;

    background: @color_w;
    .box-shadow(@shadow);
    border-radius: @br;

    padding: 10px;

    &::after {
      content: '';
      display: block;
      position: absolute;
      z-index: 0;
      background: @color_w;
      transform: rotate(-45deg) translateX(-50%);
      top: -5px;
      left: 50%;
      width: 10px;
      height: 10px;
    }

    > * {
      padding: 5px;
      text-decoration: none;
      color: @color_b1;

      &:not(:last-child) {
        border-bottom: 1px solid @color_b5;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  &.active {
    > div:nth-child(1) {
      &::after {
        top: 52%;
        transform: translateY(-50%) scaleY(-1);
        color: @color_1;
      }
    }

    > div:nth-child(2) {
      opacity: 1;
      visibility: visible;
      transform: scaleY(1) translateY(0);

    }

  }


}

.field-wrap{
  width: 100%;
  position: relative;
}

// LABEL STYLE
//=============================================================================
label.label_bottom{
  position: absolute;
  display: flex;
  align-content: center;
  align-items: center;
  z-index: 4;
  // top:@s40 - 7;
  top: 0;
  left: 0;
  height: @elements_h;
  color: @form_placeholder_color;
  padding-left: @formLeftPadding !important;
  opacity: 1;
  font: @font_6;
  .anim_on(0.1s);
  pointer-events: none;
}

input, textarea, select{
  &:focus:placeholder-shown,
  &:not(:placeholder-shown){
    & ~ label.label_bottom{
      height: @vgap;
      top: -@vgap /1.3 + 2px;
      left: -10px;
      font: @font_8;
      color: @form_text_color;
    }
  }
}
span.error{
  color: @form_error_text;
   font: @f_reg 13px/110% @font;
  position: absolute;
  bottom: -20px;
  width: 100%;
  padding: 0 13px 5px;
  //border: @form_border;
  border-top: none;
  //border-radius: 0 0 @br/3 @br/3 ;
  z-index: 7;
  background: none;
  //box-shadow: 0 5px 20px -4px rgba(@color_b1, 0.1);
}





// jQuery Validation Plugin
// List of rules
// required — поле обязательное для заполнения (true или false);
// maxlength — максимальное кол-во символов (число);
// minlength — минимальное кол-во символов (число);
// email — проверяет корректность e-mail адреса (true или false);
// url — проверяет корректность url адреса (true или false);
// remote — указывается файл для проверки поля (например: check.php);
// date — проверка корректности даты (true или false);
// dateISO — проверка корректности даты ISO (true или false);
// number — проверка на число (true или false);
// digits — только цифры (true или false);
// creditcard — корректность номера кредитной карты (true или false);
// equalTo — равное чему-то (например, другому полю equalTo:»#pswd»);
// accept — проверка на правильное расширение (accept: «xls|csv»);
// rangelength — диапазон кол-ва символов, минимальное и максимальное (rangelength: [2, 6]);
// range — число должно быть в диапазоне от и до (range: [13, 23]);
// max — максимальное значение числа (22);
// min — минимальное значение числа (11).


// RANGE STYLE (slider-line)
// =============================================================

@track_h: 4px;


input[type=range]{
  -webkit-appearance: none;
  width:100%;
  height: @track_h;
}


input[type=range]:focus{
  outline: none;

  &::-webkit-slider-runnable-track{
    background: @color_b5;
  }

  &::-ms-fill-lower{
    background: @color_1;
  }

  &::-ms-fill-upper{
    background: @color_2;
  }
}

.track(){
  width: 100%;
  height: @track_h;
  cursor: pointer;
  animate: 0.2s;
}

.styl_track(){
  background: @color_b5;
  border-radius: @br;
  box-shadow: none;
  border: 0;
}

.thumb(){
  .anim_on(@anim);
  z-index: 2;
  position: relative;
  -webkit-box-shadow:     0 3px 10px -2px rgba(40, 80, 120, 0.2);
  -moz-box-shadow:        0 3px 10px -2px rgba(40, 80, 120, 0.2);
  box-shadow:             0 3px 10px -2px rgba(40, 80, 120, 0.2);

  border: none;
  height: @track_h * 5;
  width: @track_h * 5;
  border-radius: @track_h / 2;
  background: @color_1;
  cursor: pointer;

  &:hover, &:active{
    background: @color_2;
  }

}

input[type=range]::-webkit-slider-runnable-track{
  .track();
  .styl_track();
}

input[type=range]::-webkit-slider-thumb{
  .thumb();
  -webkit-appearance: none;
  margin-top: -7px;
}

input[type=range]::-moz-range-track{
  .track();
  .styl_track();
}

input[type=range]::-moz-range-thumb{
  .thumb();
}

input[type=range]::-ms-track{
  .track();
  background:transparent;
  border-color: transparent;
  color:transparent;
}

input[type=range]::-ms-fill-lower,
input[type=range]::-ms-fill-upper{
  .styl_track();
}

input[type=range]::-ms-thumb{
  .thumb();
}


// =============================================================
[disabled] {
  opacity: 0.5;
}

.hide {
  display: none !important;
}


